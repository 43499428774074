<template>
  <div v-bind:class="{ 'small': $vuetify.breakpoint.smAndDown }">
    <!-- Floating Chat Button -->
    <v-btn v-show="!isChatOpen" fab class="floating-chat-btn" @click="toggleChat" color="primary darken-2"
      id="floating-chat-btn" elevation="2">
      <v-badge v-if="totalUnreadConversations > 0" :content="totalUnreadConversations" color="red" overlap>
        <v-icon>mdi-message</v-icon>
      </v-badge>
      <v-icon v-else>mdi-message</v-icon>
    </v-btn>

    <!-- Chat Window -->
    <v-dialog v-model="isChatOpen" max-width="400px" hide-overlay transition="dialog-bottom-transition"
      content-class="chat-dialog-content" id="chat-dialog" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card outlined>
        <!-- Chat Header -->
        <v-toolbar flat class="chat-header">
          <v-btn icon v-if="selectedConversation" @click="goBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ selectedConversation ? getUserName(selectedConversation.id) : 'Mensajes' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeChat">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider />

        <!-- Interactions List or Chat Window -->
        <v-card-text class="chat-content pa-0 pb-0 mb-0" v-bind:class="{ 'ismobile': $vuetify.breakpoint.smAndDown }">
          <!-- Interactions List -->
          <div v-if="!selectedConversation">
            <!-- Existing Conversations -->
            <v-list class="conversation-list-content py-0">
              <v-subheader>Conversaciones</v-subheader>
              <template v-for="(conversation, index) in sortedInteractions">
                <v-list-item @click="selectConversation(conversation)" :key="conversation.id">
                  <v-list-item-avatar style="overflow:visible">
                    <template v-if="conversation.type == 'whatsapp'">
                      <v-icon>mdi-whatsapp</v-icon>
                    </template>
                    <template v-else-if="conversation.type == 'instagram'">
                      <v-icon>mdi-instagram</v-icon>
                    </template>
                    <template v-else-if="conversation.type == 'in-app-chat'">
                      <v-badge :content="' '" :color="getStatusClass(getStatus(conversation.id))" overlap small>
                        <v-avatar size="32">
                          <UserImage :user="{ id: conversation.id }" small />
                        </v-avatar>
                      </v-badge>
                    </template>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ getUserName(conversation.id) }}</v-list-item-title>
                    <v-list-item-subtitle>{{ conversation.lastMessage }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <!-- Show a badge if there's an unread message from the user -->
                    <v-badge v-if="hasUnreadMessages(conversation)" :content="' '" color="red" overlap class="mr-4">
                      <v-icon color="grey darken-1">mdi-message</v-icon>
                    </v-badge>
                    <v-icon v-else color="grey lighten-1">mdi-message-outline</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>

            <!-- Users Without Interactions -->
            <v-list class="py-0">
              <v-subheader>Usuarios sin conversación</v-subheader>
              <template v-for="(user, idx) in unusedUsers">
                <v-list-item @click="startConversation(user)" :key="user.id">
                  <v-list-item-avatar>
                    <v-badge :content="' '" :color="getStatusClass(user.state)" overlap>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-badge>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{ user.name }}</v-list-item-title>
                    <v-list-item-subtitle>{{ user.state === 'online' ? 'En línea' : 'Desconectado'
                      }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </div>

          <!-- Chat Messages -->
          <div v-else>
            <div class="chat-messages">
              <div v-for="(message, index) in selectedConversationMessages" :key="index"
                :class="['message-container', message.fromUser ? 'from-user' : 'from-admin']">
                <!-- Message Types -->
                <div v-if="message.type === 'text'">
                  <p class="mb-1 px-2 py-1 rounded-lg"
                    :style="{ backgroundColor: message.fromUser ? '#128c7e' : '#3f51b5', color: 'white' }">
                    {{ message.text }}
                  </p>
                </div>
                <div v-else-if="message.type === 'image'">
                  <img :src="message.fileUrl" width="200" class="mb-2" />
                </div>
                <div v-else-if="message.type === 'video'">
                  <video :src="message.fileUrl" controls width="200" class="mb-2"></video>
                </div>
                <div v-else-if="message.type === 'file'">
                  <v-chip class="mb-2" :color="message.fromUser ? '#128c7e' : '#3f51b5'"
                    @click="downloadFile(message.fileUrl)">
                    <v-icon left>mdi-file</v-icon>
                    {{ message.fileName }}
                  </v-chip>
                </div>
              </div>
              <!-- Typing Indicator -->
              <div v-if="isUserTyping" class="typing-indicator">
                <v-chip class="mb-2">
                  {{ getUserName(selectedConversation.id) }} está escribiendo...
                </v-chip>
              </div>
            </div>
          </div>
        </v-card-text>

        <!-- Message Input Section -->
        <v-divider v-if="selectedConversation" />
        <v-card-actions class="message-input" v-if="selectedConversation">
          <v-progress-linear v-if="uploading" :value="uploadProgress" height="2" color="primary"></v-progress-linear>
          <v-text-field v-model="newMessage" label="Escribe un mensaje" outlined hide-details class="rounded-lg"
            :disabled="!selectedConversation || uploading" @keyup.enter="sendMessage" @input="handleTyping">
            <template v-slot:append>
              <v-icon @click="triggerFileInput">mdi-paperclip</v-icon>
              <v-icon @click="sendMessage" :color="newMessage ? 'primary' : ''">mdi-send</v-icon>
            </template>
          </v-text-field>
          <!-- Hidden File Input -->
          <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  getDatabase,
  ref as dbRef,
  set,
  update,
  push,
  onValue,
  onDisconnect,
  get,
  off
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import UserImage from '@/components/profile/UserImage.vue';
import { logAuditEvent } from '@/error/audit.js';
import { Device } from '@capacitor/device';
import { getFirestore, setDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { getMessaging, getToken } from "firebase/messaging";

import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  components: {
    UserImage,
  },
  data() {
    return {
      isChatOpen: false,
      interactions: [],
      selectedConversation: null,
      newMessage: '',
      userStatus: {},
      isUserTyping: false,
      typingTimeout: null,
      uploading: false,
      uploadProgress: 0,
      lastSeenAdmin: 0,
      lastSeenUser: 0,
      interactionsRef: null,
      selectedConversationMessages: [],
      lastMessage: null,
      scrollListenerAttached: false,
    };
  },
  computed: {
    sortedInteractions() {
      return this.interactions.sort((a, b) => b.lastMessageDate - a.lastMessageDate);
    },
    totalUnreadConversations() {
      return this.interactions.filter((conversation) => this.hasUnreadMessages(conversation)).length;
    },
    unusedUsers() {
      // Users that have no interaction yet
      // Filter out admin and those present in interactions
      const usedUserIds = this.interactions.map(i => i.id);

      let filtered = Object.keys(this.userStatus)
        .filter(userId => userId !== this.$store.state.Auth.token.claims.user_id && !usedUserIds.includes(userId))
        .map(userId => ({
          id: userId,
          name: this.getUserName(userId),
          state: this.getStatus(userId)
        }));

      //remove the admin from the list
      filtered = filtered.filter(user => user.id !== this.$store.state.Auth.token.claims.user_id);

      return filtered;
    }
  },
  methods: {
    async getDeviceId() {
      const info = await Device.getId();
      return info.identifier;
    },
    async requestNotificationPermissions() {
      try {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
          const messaging = getMessaging();
          const token = await getToken(messaging, {
            vapidKey: 'BGCHz5qHsrrI5SveIUNCs5OS2dsNNS1KIYPDAI8NKe_kcLPUDmuSHwKvUz-Q-AcN2Aw2YId8smvWiRfd6ewvirM'
          });

          const deviceId = await this.getDeviceId();
          const db = getFirestore();
          const messagingRef = collection(db, 'messaging');
          const messagingDoc = doc(messagingRef, this.$store.state.Auth.token.claims.user_id);
          const snapshot = await getDoc(messagingDoc);

          if (snapshot.exists()) {
            let tokenArray = snapshot.data().tokens || [];
            const existingDevice = tokenArray.find(entry => entry.deviceId === deviceId);
            if (existingDevice) {
              if (existingDevice.token !== token) {
                existingDevice.token = token;
                await updateDoc(messagingDoc, { tokens: tokenArray });
              }
            } else {
              tokenArray.push({ deviceId, token });
              await updateDoc(messagingDoc, { tokens: tokenArray });
            }
          } else {
            await setDoc(messagingDoc, {
              tokens: [{ deviceId, token }]
            });
          }

          logAuditEvent('notification', this.$store.state.Auth.token.claims.user_id, "Accepted permission");
        } else {
          logAuditEvent('notification', this.$store.state.Auth.token.claims.user_id, "Not accepted permission");
        }
      } catch (e) {
        this.requestNotificationPermissions();
        console.log('Error requesting notification permissions:', e);
        this.$notify({
          title: 'Error',
          text: 'Error al solicitar permisos de notificación. Por favor, inténtelo de nuevo.',
          type: 'error'
        });
        logAuditEvent('error', this.$store.state.Auth.token.claims.user_id, e);
      }
    },
    toggleChat() {
      this.isChatOpen = !this.isChatOpen;
      if (this.isChatOpen) {
        this.requestNotificationPermissions();
      }
    },
    closeChat() {
      this.isChatOpen = false;
      this.selectedConversation = null;
      this.selectedConversationMessages = [];
      this.setTypingStatus(false);
    },
    goBack() {
      this.selectedConversation = null;
      this.selectedConversationMessages = [];
      this.setTypingStatus(false);
    },
    async loadInteractions() {
      const db = getDatabase();
      this.interactionsRef = dbRef(db, 'interactions');

      onValue(this.interactionsRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const interactionsArray = Object.keys(data).map((key) => ({
            ...data[key],
            id: key,
          }));
          this.interactions = interactionsArray.sort(
            (a, b) => b.lastMessageDate - a.lastMessageDate
          );
        } else {
          this.interactions = [];
        }

        // Notification logic: If chat is closed and new message arrives
        if (!this.isChatOpen && this.interactions.length > 0) {
          if (this.lastMessage === null) {
            this.lastMessage = this.interactions[0].lastMessage;
          } else if (this.lastMessage !== this.interactions[0].lastMessage) {
            this.lastMessage = this.interactions[0].lastMessage;
            this.showNotification(this.interactions[0]);
          }
        } else if (this.isChatOpen && this.interactions.length > 0) {
          this.lastMessage = this.interactions[0].lastMessage;
        }

        // Set admin online and listen for statuses
        this.setUserOnline(this.$store.state.Auth.token.claims.user_id);
        this.listenForStatusUpdates();
      });
    },
    async selectConversation(conversation) {
      this.selectedConversation = conversation;
      const db = getDatabase();
      const conversationRef = dbRef(db, `interactions/${conversation.id}`);

      const conversationSnapshot = await get(conversationRef);
      if (conversationSnapshot.exists()) {
        const cData = conversationSnapshot.val();
        this.lastSeenAdmin = cData.lastSeenAdmin || 0;
        this.lastSeenUser = cData.lastSeenUser || 0;
      } else {
        this.lastSeenAdmin = 0;
        this.lastSeenUser = 0;
      }

      const messagesRef = dbRef(db, `interactions/${conversation.id}/messages`);
      onValue(messagesRef, (snapshot) => {
        let messages = [];
        const data = snapshot.val();
        if (data) {
          messages = Object.keys(data)
            .map((key) => ({ ...data[key], id: key }))
            .sort((a, b) => a.timestamp - b.timestamp);
        }
        this.selectedConversationMessages = messages;

        // After messages load, try marking as read if at bottom
        this.$nextTick(() => {
          const chatContent = document.querySelector('.chat-content');
          if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
            if (!this.scrollListenerAttached) {
              this.scrollListenerAttached = true;
              chatContent.addEventListener('scroll', () => {
                if (chatContent.scrollHeight - chatContent.scrollTop - chatContent.clientHeight < 50) {
                  this.markMessagesAsRead();
                }
              });
            }
            // If already at bottom, mark as read
            if (chatContent.scrollHeight - chatContent.scrollTop - chatContent.clientHeight < 50) {
              this.markMessagesAsRead();
            }
          }
        });
      });

      this.listenForUserTyping(conversation.id);
    },
    markMessagesAsRead() {
      if (this.selectedConversation) {
        const db = getDatabase();
        const conversationRef = dbRef(db, `interactions/${this.selectedConversation.id}`);
        update(conversationRef, { lastSeenAdmin: Date.now() });
      }
    },
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        const message = {
          text: this.newMessage,
          fromUser: false,
          timestamp: Date.now(),
          type: 'text',
        };

        this.sendMessageToDatabase(message);
        this.setTypingStatus(false);
        this.newMessage = '';


        // if user is offline, send call the messageNotification cloud function
        if (this.getStatus(this.selectedConversation.id) === 'offline') {
          const functions = getFunctions();
          const messageNotification = httpsCallable(functions, "gym/sendMessageToDevices");
          messageNotification({ user_id: this.selectedConversation.id, message: message.text, title: 'Nuevo mensaje', click_action: "/mensajes" });
        }

      }
    },
    async sendMessageToDatabase(message) {
      const db = getDatabase();
      const conversationRef = dbRef(db, `interactions/${this.selectedConversation.id}`);
      const messagesRef = dbRef(db, `interactions/${this.selectedConversation.id}/messages`);
      const newMessageRef = push(messagesRef);
      await set(newMessageRef, message);

      const interactionSnapshot = await get(conversationRef);
      if (!interactionSnapshot.exists()) {
        await set(conversationRef, {
          type: 'in-app-chat',
          lastMessage: message.type === 'text' ? message.text : 'Sent a file',
          lastMessageDate: Date.now(),
          lastSeenAdmin: Date.now(),
          lastSeenUser: 0,
          lastMessageFromUser: false
        });
      } else {
        await update(conversationRef, {
          lastMessage: message.type === 'text' ? message.text : 'Sent a file',
          lastMessageDate: Date.now(),
          lastMessageFromUser: false
        });
      }

      // Mark as read since admin is sending (and presumably viewing)
      this.markMessagesAsRead();
    },
    hasUnreadMessages(conversation) {
      return conversation.lastMessageFromUser === true && conversation.lastMessageDate > (conversation.lastSeenAdmin || 0);
    },
    setUserOnline(userId) {
      const db = getDatabase();
      const userStatusRef = dbRef(db, `/status/${userId}`);

      set(userStatusRef, {
        name: this.$store.state.Auth.token.claims.name,
        state: 'online',
        lastChanged: Date.now(),
      });
      onDisconnect(userStatusRef).set({
        name: this.$store.state.Auth.token.claims.name,
        state: 'offline',
        lastChanged: Date.now(),
      });
    },
    listenForStatusUpdates() {
      const db = getDatabase();
      const statusRef = dbRef(db, '/status');
      onValue(statusRef, (snapshot) => {
        this.userStatus = snapshot.val() || {};
      });
    },
    getStatus(userId) {
      return this.userStatus[userId]?.state === 'online' ? 'online' : 'offline';
    },
    // Return a color directly for the badge
    getStatusClass(state) {
      return state === 'online' ? 'green' : 'grey';
    },
    getUserName(userId) {
      return this.userStatus[userId]?.name || 'Unknown';
    },
    handleTyping() {
      this.setTypingStatus(true);
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }
      this.typingTimeout = setTimeout(() => {
        this.setTypingStatus(false);
      }, 300);
    },
    setTypingStatus(isTyping) {
      if (!this.selectedConversation) return;
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${this.selectedConversation.id}/admin`);
      set(typingRef, isTyping);
    },
    listenForUserTyping(conversationId) {
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${conversationId}/user`);
      onValue(typingRef, (snapshot) => {
        this.isUserTyping = snapshot.val() || false;
      });
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.uploading = true;
      const storage = getStorage();
      const storageReference = storageRef(
        storage,
        `interactions/${this.selectedConversation.id}/${Date.now()}_${file.name}`
      );

      const uploadTask = uploadBytesResumable(storageReference, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error('File upload error:', error);
          this.uploading = false;
          this.uploadProgress = 0;
        },
        async () => {
          this.uploading = false;
          this.uploadProgress = 0;
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          let messageType = 'file';
          if (file.type.startsWith('image/')) {
            messageType = 'image';
          } else if (file.type.startsWith('video/')) {
            messageType = 'video';
          }

          const message = {
            fromUser: false,
            timestamp: Date.now(),
            type: messageType,
            fileUrl: downloadURL,
            fileName: file.name,
            fileType: file.type,
          };

          await this.sendMessageToDatabase(message);
        }
      );
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    async showNotification(conversation) {
      try {
        const userId = conversation.id;
        const storage = getStorage();
        const storageReference = storageRef(storage, `/profile/${userId}/profilePic.jpg`);
        const userImage = await getDownloadURL(storageReference);

        const userName = this.getUserName(userId);

        const notification = new Notification(userName, {
          body: conversation.lastMessage,
          icon: userImage
        });

        notification.onclick = () => {
          window.focus();
          this.isChatOpen = true;
          this.selectConversation(conversation);
        };
      } catch (error) {
        console.error('Notification icon error:', error);
        const userName = this.getUserName(conversation.id);
        const notification = new Notification(userName, {
          body: conversation.lastMessage,
        });
        notification.onclick = () => {
          window.focus();
          this.isChatOpen = true;
          this.selectConversation(conversation);
        };
      }
    },
    startConversation(user) {
      const db = getDatabase();
      const conversationRef = dbRef(db, `interactions/${user.id}`);
      const newConversation = {
        type: 'in-app-chat',
        lastMessage: '',
        lastMessageDate: 0,
        lastSeenAdmin: Date.now(),
        lastSeenUser: 0,
        lastMessageFromUser: false
      };
      set(conversationRef, newConversation).then(() => {
        this.selectConversation({ id: user.id, ...newConversation });
      });
    }
  },
  async mounted() {
    await this.loadInteractions();
  },
  beforeDestroy() {
    off(this.interactionsRef);
    if (this.selectedConversation) {
      this.setTypingStatus(false);
    }
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
  },
};
</script>

<style scoped>
.floating-chat-btn {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 5;
}

.small .floating-chat-btn {
  bottom: 86px !important;
  right: 16px !important;
}

.chat-dialog-content {
  position: fixed;
  bottom: 80px;
  right: 24px;
  margin: 0;
  width: 400px;
  max-height: 70vh;
  overflow: hidden;
}

.chat-header {
  flex-shrink: 0;
}

.chat-content {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.chat-content.ismobile {
  height: calc(100vh - 144px) !important;
}

.message-container {
  display: flex;
  margin: 8px;
}

.from-user {
  justify-content: flex-start;
}

.from-admin {
  justify-content: flex-end;
}

.typing-indicator {
  margin-left: 16px;
}

.message-input {
  flex-shrink: 0;
}
</style>

<style>
.chat-dialog-content {
  align-self: auto;
  right: 0px;
  position: absolute;
  bottom: 20px;
  box-shadow: none;
}
</style>
