import firebase from "../../firebase.js";

import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  Timestamp,
} from "firebase/firestore";

import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import store from "../store/index";
import moment from "moment";

import { logAuditEvent } from "@/error/audit.js";

export default class User {
  constructor(obj) {
    this.type = obj.type || "usuario";

    if (obj.id) {
      this.id = obj.id || "";
    }
    this.createdAt = obj.createdAt || new Date();
    this.civilId = obj.civilId || "";
    this.displayName = obj.displayName || "";
    this.email = obj.email ? obj.email.toLowerCase() : "";
    this.phoneNumber = obj.phoneNumber || "";
    this.emergencyContact = obj.emergencyContact || "";
    this.country = obj.country || "Uruguay";
    this.disabled = obj.disabled || false;
    this.address = obj.address || null;
    this.birthDate = obj.birthDate || null;
    this.medicalService = obj.medicalService || null;
    this.licensePaused = obj.licensePaused || false;
    if (this.type == "paselibre") {
      this.company = obj.company || null;
    }
    this.observation = obj.observation || "";

    this.plan = obj.plan || 0;

    this.injuries = obj.injuries || [];

    this.objectives = obj.objectives || [];

    this.equipment = obj.equipment || [];

    if (obj.endOfSubscription) {
      this.endOfSubscription = obj.endOfSubscription;
      let date1 = new Date(obj.endOfSubscription.seconds * 1000);
      let date2 = new Date();
      //normalize the hours.minutes,seconds and milliseconds to 0
      date1.setHours(0, 0, 0, 0);
      date2.setHours(0, 0, 0, 0);

      this.expired = moment(date1).diff(moment(date2), "days");
    } else {
      this.expired = -1000000;
    }

    if (this.licensePaused) {
      this.expired = "PAUSADO LICENCIA";
    }

    this.routines = obj.routines || [];

    this.mainWorkout = obj.mainWorkout || {
      type: null,
      plan_id: null,
      start: null,
      end: null,
    };

    this.gender = obj.gender || null;
  }

  static async getAll(civilId) {
    let userQuery;
    const db = getFirestore();
    if (store.state.Auth.token.claims.type == "superuser") {
      userQuery = query(collection(db, "users"), where("disabled", "!=", true));
    } else if (store.state.Auth.token.claims.type == "admin") {
      userQuery = query(collection(db, "users"), where("disabled", "!=", true));
    } else if (store.state.Auth.token.claims.type == "entrenador") {
      userQuery = query(
        collection(db, "users"),
        where("type", "==", "usuario"),
        where("disabled", "!=", true)
      );
    } else if (store.state.Auth.token.claims.type == "usuario") {
      userQuery = query(
        collection(db, "users"),
        where("id", "==", store.state.Auth.token.claims.id),
        where("disabled", "!=", true)
      );
    }

    if (civilId && civilId != "") {
      userQuery = query(
        collection(db, "users"),
        where("civilId", "==", civilId.replace(/[.-]/g, "")),
        where("type", "==", "usuario")
      );
    }

    const querySnapshot = await getDocs(userQuery);
    let result = querySnapshot.docs.map((user) => {
      let userData = user.data();

      return new User({
        ...userData,
        id: user.id,
      });
    });

    if (
      store.state.Auth.token.claims.type == "admin" ||
      store.state.Auth.token.claims.type == "entrenador"
    ) {
      result = result.filter((user) => user.type != "superuser");
    }

    await logAuditEvent(
      "read",
      store.state.Auth.token.claims.user_id,
      "Read all users"
    );
    return result;
  }

  static async searchUsers(searchText) {
    const db = getFirestore();
    const usersRef = collection(db, "users");
    // Calculate the end string for the range query
    const endString =
      searchText.substring(0, searchText.length - 1) +
      String.fromCharCode(searchText.charCodeAt(searchText.length - 1) + 1);

    const q = query(
      usersRef,

      where("civilId", ">=", searchText),
      where("civilId", "<", endString)
    );

    const querySnapshot = await getDocs(q);

    let result = querySnapshot.docs.map((user) => {
      let userData = user.data();

      if (
        userData.type != "superuser" &&
        userData.type != "admin" &&
        userData.type != "entrenador"
      ) {
        return new User({
          ...userData,
          id: user.id,
        });
      } else {
        return null;
      }
    });

    return result;
  }

  static async getAllDisabled() {
    let userQuery;
    const db = getFirestore();

    userQuery = query(collection(db, "users"), where("disabled", "==", true));

    const querySnapshot = await getDocs(userQuery);
    let result = querySnapshot.docs.map((user) => {
      let userData = user.data();

      return new User({
        ...userData,
        id: user.id,
      });
    });

    await logAuditEvent(
      "read",
      store.state.Auth.token.claims.user_id,
      "Read all disabled users"
    );

    return result;
  }

  static async update(user) {
    const functions = getFunctions();

    if (user.expired) {
      delete user.expired;
    }

    if (user.disabled && user.endOfSubscription != null) {
      delete user.endOfSubscription;
    }

    user.civilId = user.civilId.replace(/\D/g, "");

    const userUpdate = httpsCallable(functions, "gym/userUpdate");
    user.auth = store.state.Auth.token.claims.user_id;

    let result = await userUpdate(user);

    await logAuditEvent(
      "update",
      store.state.Auth.token.claims.user_id,
      "Update user " + user.id + `with data ${JSON.stringify(user)}`
    );

    return result;
  }

  static async add(user) {
    const functions = getFunctions();
    user.civilId = user.civilId.replace(/\D/g, "");
    user.password = user.civilId.replace(/[.-]/g, "");

    delete user.expired;

    user.createdAt = moment().toDate();

    if (user.endOfSubscription) {
      user.endOfSubscription = Timestamp.fromDate(
        moment(user.endOfSubscription, "YYYY-MM-DD").toDate()
      );
    }

    const userAdd = httpsCallable(functions, "gym/userAdd");
    user.auth = store.state.Auth.token.claims.user_id;

    let result = await userAdd(user);

    await logAuditEvent(
      "create",
      store.state.Auth.token.claims.user_id,
      "Create user " + user.civilId + ` with data ${JSON.stringify(user)}`
    );
    return result;
  }

  static async remove(user) {
    const functions = getFunctions();

    const userDelete = httpsCallable(functions, "gym/userDelete");
    user.auth = store.state.Auth.token.claims.user_id;
    let result = await userDelete(user);

    await logAuditEvent(
      "delete",
      store.state.Auth.token.claims.user_id,
      "Delete user " + user.id + `with data ${JSON.stringify(user)}`
    );
    return result;
  }

  getObject() {
    return this;
  }

  static validate(user) {
    return !!user.id;
  }

  clone() {
    return new User(this);
  }

  static async updateCivilId(user, newCivilId) {
    const functions = getFunctions();
    const userUpdateCivilId = httpsCallable(functions, "gym/recreateUser");

    const data = { user: user, newCivilId: newCivilId };
    data.auth = store.state.Auth.token.claims.user_id;
    let result = await userUpdateCivilId(data);

    await logAuditEvent(
      "update",
      store.state.Auth.token.claims.user_id,
      "Update civilId from user " + user.id + `to ${newCivilId}`
    );
    return result;
  }

  static async updateEmail(user, newEmail) {
    const functions = getFunctions();
    const userUpdateEmail = httpsCallable(functions, "gym/updateEmail");
    user.email = newEmail;
    user.auth = store.state.Auth.token.claims.user_id;
    let result = await userUpdateEmail(user);

    await logAuditEvent(
      "update",
      store.state.Auth.token.claims.user_id,
      "Update email from user " + user.id + `to ${newEmail}`
    );
    return result;
  }

  static async sendMail(template, userid) {
    const functions = getFunctions();
    const sendEmail = httpsCallable(functions, "sendEmail");
    let result = await sendEmail({ template: template, userid: userid });
    return result;
  }

  static async sendMessageToDevices(user, title, message, click_action) {
    const functions = getFunctions();
    const sendMessageToDevices = httpsCallable(
      functions,
      "gym/sendMessageToDevices"
    );
    let result = await sendMessageToDevices({
      user_id: user,
      title: title,
      message: message,
      click_action: click_action,
    });
    return result;
  }
}
