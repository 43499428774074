<template>
    <div class="simple-view">
        <v-fade-transition>
            <div v-if="loading" class="px-4 mt-4">
                <v-skeleton-loader v-for="i in 3" :key="i" type="card" class="mb-6" />
            </div>
        </v-fade-transition>

        <v-slide-y-transition>
            <div v-if="dayExercises.length > 0" class="exercise-timeline pa-4">
                <div v-for="(exercise, index) in dayExercises" :key="index" class="timeline-item">
                    <!-- Timeline connector -->
                    <div class="timeline-connector">
                        <div class="connector-line"></div>
                        <v-avatar size="40" class="timeline-avatar elevation-3" :color="exercise.color || 'success'">
                            <span class="white--text text-h6">{{ abecedario[index] }}</span>
                        </v-avatar>
                    </div>

                    <!-- Exercise Card -->
                    <v-card class="exercise-card ml-4" :class="{
                        'cluster-card': exercise.isCluster,
                        'cluster-expanded': expandedClusters[index]
                    }" @click="exercise.isCluster ? toggleCluster(index) : selectExercise(exercise)" elevation="2">
                        <!-- Cluster Exercise Card -->
                        <template v-if="exercise.isCluster">
                            <v-card-title class="cluster-header d-flex align-center py-3">
                                <div class="cluster-header-content">
                                    <div class="d-flex align-center">
                                        <div class="">
                                            {{ exercise.title || `${exercise.exercises.length} Ejercicios en este grupo`
                                            }}
                                        </div>
                                        <!--    <v-chip
                                            x-small
                                            label
                                            color="primary"
                                            class="ml-2"
                                        >
                                            Cluster
                                        </v-chip> -->
                                    </div>

                                    <!-- Cluster stats -->
                                    <div class="exercise-stats-container mt-2">
                                        <v-chip small outlined class="stat-chip" color="success">
                                            <v-icon left x-small>mdi-repeat</v-icon>
                                            {{ exercise.sets }} Sets
                                        </v-chip>

                                        <v-chip v-if="exercise.rest" small outlined class="stat-chip" color="error">
                                            <v-icon left x-small>mdi-timer-sand</v-icon>
                                            {{ exercise.rest }}'' Descanso
                                        </v-chip>
                                    </div>
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn icon @click.stop="toggleCluster(index)">
                                    <v-icon>{{ expandedClusters[index] ? 'mdi-chevron-up' : 'mdi-chevron-down'
                                        }}</v-icon>
                                </v-btn>
                            </v-card-title>

                            <!-- Sub-exercises -->
                            <v-expand-transition>
                                <div v-show="expandedClusters[index]">
                                    <v-divider></v-divider>
                                    <v-card-text class="cluster-exercises pa-0">
                                        <div v-for="(subExercise, exIndex) in exercise.exercises" :key="exIndex"
                                            class="sub-exercise-item" @click.stop="selectExercise(subExercise)">
                                            <div class="sub-exercise-content">
                                                <div class="d-flex align-center mb-2">
                                                    <v-chip label small :color="exercise.color || 'success'" dark
                                                        class="mr-3">
                                                        {{ abecedario[index] }}{{ exIndex + 1 }}
                                                    </v-chip>
                                                    <div class="subtitle-1">
                                                        {{ subExercise.data.name }}
                                                    </div>
                                                </div>

                                                <!-- Sub-exercise stats -->
                                                <div class="exercise-stats-container">
                                                    <template v-if="subExercise.type === 'reps'">
                                                        <template v-if="Array.isArray(subExercise.value)">
                                                            <v-chip v-for="(reps, index) in subExercise.value"
                                                                :key="index" small outlined class="stat-chip"
                                                                color="info">
                                                                <v-icon left x-small>mdi-numeric</v-icon>
                                                                Set {{ index + 1 }}: {{ reps }} reps
                                                            </v-chip>
                                                        </template>
                                                        <v-chip v-else small outlined class="stat-chip" color="info">
                                                            <v-icon left x-small>mdi-numeric</v-icon>
                                                            {{ subExercise.value }} reps
                                                        </v-chip>
                                                    </template>
                                                    <v-chip v-else small outlined class="stat-chip" color="warning">
                                                        <v-icon left x-small>mdi-timer</v-icon>
                                                        {{ subExercise.value }}'' Trabajo
                                                    </v-chip>

                                                    <v-chip v-if="subExercise.rest" small outlined class="stat-chip"
                                                        color="error">
                                                        <v-icon left x-small>mdi-timer-sand</v-icon>
                                                        {{ subExercise.rest }}'' Descanso
                                                    </v-chip>

                                                    <RPEVisualizer v-if="subExercise.rpe"
                                                        :rpe="parseInt(subExercise.rpe)" />
                                                </div>
                                            </div>
                                        </div>
                                    </v-card-text>
                                </div>
                            </v-expand-transition>
                        </template>

                        <!-- Regular Exercise Card -->
                        <template v-else>
                            <v-card-title class="py-3">
                                <div class="">{{ exercise.data.name }}</div>
                                <v-spacer></v-spacer>
                                <v-chip v-if="exercise.type === 'tempo'" x-small color="primary" label>
                                    Tempo
                                </v-chip>
                            </v-card-title>

                            <v-card-text>
                                <div class="exercise-stats-container">
                                    <v-chip small outlined class="stat-chip" color="success">
                                        <v-icon left x-small>mdi-repeat</v-icon>
                                        {{ exercise.sets }} Sets
                                    </v-chip>

                                    <template v-if="exercise.type === 'reps'">
                                        <template v-if="Array.isArray(exercise.value)">
                                            <v-chip v-for="(reps, index) in exercise.value" :key="index" small outlined
                                                class="stat-chip" color="info">
                                                <v-icon left x-small>mdi-numeric</v-icon>
                                                Set {{ index + 1 }}: {{ reps }} reps
                                            </v-chip>
                                        </template>
                                        <v-chip v-else small outlined class="stat-chip" color="info">
                                            <v-icon left x-small>mdi-numeric</v-icon>
                                            {{ exercise.value }} reps
                                        </v-chip>
                                    </template>
                                    <v-chip v-else small outlined class="stat-chip" color="warning">
                                        <v-icon left x-small>mdi-timer</v-icon>
                                        {{ exercise.value }}'' Trabajo
                                    </v-chip>

                                    <v-chip v-if="exercise.rest" small outlined class="stat-chip" color="error">
                                        <v-icon left x-small>mdi-timer-sand</v-icon>
                                        {{ exercise.rest }}'' Descanso
                                    </v-chip>


                                    <RPEVisualizer v-if="exercise.rpe" :rpe="parseInt(exercise.rpe)" />

                                    <!--  <v-chip v-if="exercise.rpe" small outlined class="stat-chip" color="deep-purple">
                                                        <v-icon left x-small>mdi-trending-up</v-icon>
                                                        RPE {{ exercise.rpe }}
                                                    </v-chip> -->
                                </div>

                                <div class="d-flex align-center mt-3">
                                    <v-btn v-if="exercise.type === 'tempo'" color="primary" small outlined rounded
                                        @click.stop="setChronoSetup(exercise)">
                                        <v-icon left small>mdi-timer</v-icon>
                                        Iniciar Cronómetro
                                    </v-btn>

                                    <v-spacer></v-spacer>

                                    <!--                                     <RPEVisualizer v-if="exercise.rpe" :rpe="exercise.rpe" class="mr-2" />
                                    -->
                                </div>
                            </v-card-text>
                        </template>
                    </v-card>
                </div>
            </div>
        </v-slide-y-transition>

        <!-- Rest day display with animation -->
        <v-fade-transition>
            <div v-if="!loading && dayExercises.length === 0" class="rest-day-container pa-4">
                <v-img src="@/assets/rest.svg" max-width="300px" max-height="250px" class="rest-day-image mx-auto mb-4">
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                            <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </v-row>
                    </template>
                </v-img>

                <v-alert text type="info">
                    Día de Descanso, asegúrate de recuperarte lo mejor posible!
                </v-alert>
            </div>
        </v-fade-transition>

        <!-- Dialogs -->
        <v-dialog v-model="dialog" max-width="500">
            <v-card v-if="selectedExercise" class="rounded-lg overflow-hidden">

                <v-card-text class="pa-0"
                    v-if="selectedExercise.thumbnail || selectedExercise.videoURL || selectedExercise.url">
                    <v-carousel v-model="carousel" height="unset" :show-arrows="getLengthOfMedia > 1" hide-delimiters>
                        <v-carousel-item v-if="selectedExercise.thumbnail">
                            <v-img class="mx-auto" :src="selectedExercise.thumbnail" aspect-ratio="1"></v-img>
                        </v-carousel-item>
                        <v-carousel-item v-if="selectedExercise.videoURL">
                            <v-responsive>
                                <video width="100%" height="250px" :src="selectedExercise.videoURL" controls></video>
                            </v-responsive>
                        </v-carousel-item>
                        <v-carousel-item v-if="selectedExercise.url && dialog">
                            <v-responsive aspect-ratio="16/9">
                                <iframe width="100%" height="250px" :src="getURL(selectedExercise.url)"
                                    title="YouTube video player" frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </v-responsive>
                        </v-carousel-item>
                    </v-carousel>
                </v-card-text>
                <v-card-title class="py-2 " style="font-size: 0.94rem !important;">
                    {{ selectedExercise.name }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-2">
                    {{ selectedExercise.description }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary darken-1" @click="dialog = false">
                        Volver
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="chrono" persistent fullscreen>
            <v-card class="rounded-0">
                <v-card-title>
                    <v-btn icon @click="chrono = false">
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="chrono = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                </v-card-title>
                <ChronoAppVue v-if="chrono" :sets="chronoSetup.sets" :type="chronoSetup.type"
                    :setSecondsDuration="chronoSetup.seconds" :restSecondsDuration="chronoSetup.restSeconds"
                    ref='chrono'>
                </ChronoAppVue>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { getFirestore, collection, getDocs, doc, getDoc, setDoc, addDoc, deleteDoc, updateDoc, runTransaction, Timestamp } from 'firebase/firestore';

import ChronoAppVue from '@/views/ChronoApp.vue';
import RPEVisualizer from "@/components/training/RPEVisualizer.vue";


export default {
    props: {
        exercises: {
            type: Array,
            required: true
        },
        rest: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    components: {
        draggable, ChronoAppVue, RPEVisualizer
    },
    data() {
        return {
            abecedario: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

            loading: true,
            dialog: false,
            dayExercises: [],
            selectedExercise: null,
            carousel: 0,
            chrono: false,
            chronoSetup: {
                type: 'workrest',
                seconds: 30,
                restSeconds: 10,
                sets: 1
            },
            expandedClusters: {},
        };
    },
    mounted() {
        let promises = this.$props.exercises.map(async (exercise) => {
            if (exercise.isCluster) {
                // Initialize cluster as expanded
                this.$set(this.expandedClusters, this.dayExercises.length, true);

                // Create an array of promises for the cluster's exercises
                let exercisesPromises = exercise.exercises.map(async (ex) => {
                    // Retrieve each exercise data from the database
                    let exerciseData = await this.getExercise(ex.id);
                    return {
                        ...ex, // Keep original exercise attributes
                        data: exerciseData // Add the retrieved data to the exercise
                    };
                });

                // Wait for all exercises within the cluster to resolve
                exercise.exercises = await Promise.all(exercisesPromises);
            } else {
                // Retrieve the data for a non-cluster exercise
                let exerciseData = await this.getExercise(exercise.id);
                exercise = {
                    ...exercise, // Keep original exercise attributes
                    data: exerciseData // Add the retrieved data to the exercise
                };
            }
            return exercise;
        });

        Promise.all(promises).then((exercises) => {
            this.dayExercises = exercises;
            // Initialize all clusters as expanded
            exercises.forEach((exercise, index) => {
                if (exercise.isCluster) {
                    this.$set(this.expandedClusters, index, true);
                }
            });
            this.loading = false;
        });
    },
    methods: {
        setChronoSetup(exercise) {

            if (exercise.type == 'tempo') {
                this.chronoSetup = {
                    type: exercise.rest ? 'workrest' : 'interval',
                    sets: exercise.sets,
                    seconds: parseInt(exercise.value),
                    restSeconds: exercise.rest ? parseInt(exercise.rest) : null
                }

                this.chrono = true;
            }

        },
        getURL(url) {
            let newURL = url.replace("watch?v=", "embed/");
            if (newURL.includes("shorts")) {
                newURL = newURL.replace("shorts", "embed");
            }
            return newURL;
        },
        async getExercise(id) {
            const db = getFirestore();
            const docRef = doc(db, "exercises", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {

                return docSnap.data();
            }
        },
        get() {
            return this.dayExercises;
        },
        set(data) {
            this.dayExercises = data;
        },
        selectExercise(exercise) {
            this.selectedExercise = exercise.data;
            this.dialog = true;
        },
        toggleCluster(index) {
            this.$set(this.expandedClusters, index, !this.expandedClusters[index]);
        },
    },
    computed: {
        getLengthOfMedia() {
            let exercise = this.selectedExercise;
            let length = 0;
            if (exercise.videoURL) {
                length++;
            }
            if (exercise.thumbnail) {
                length++;
            }
            if (exercise.url) {
                length++;
            }
            return length;
        }
    },
};
</script>
<style scoped>
.simple-view {
    max-width: 960px;
    margin: 0 auto;
}

.exercise-timeline {
    position: relative;
}

.timeline-item {
    display: flex;
    align-items: stretch;
    margin-bottom: 2rem;
    position: relative;
}

.timeline-connector {
    position: relative;
    flex-shrink: 0;
    width: 40px;
    display: flex;
    flex-direction: column;
}

.connector-line {
    position: absolute;
    top: 40px;
    left: 20px;
    width: 2px;
    height: 100%;
    background: var(--v-success-base);
}

/* Adjust the gradient fade */
.connector-line::after {
    content: '';
    position: absolute;
    bottom: -2rem;
    left: 0;
    width: 100%;
    height: 2rem;
    background: linear-gradient(to bottom, var(--v-success-base), transparent);
}

.timeline-item:last-child .connector-line {
    display: none;
}

.timeline-avatar {
    position: relative;
    z-index: 2;
    border: 3px solid var(--v-surface-base);
}

.exercise-card {
    flex-grow: 1;
    border-radius: 12px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.exercise-card::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    background-color: var(--v-success-base);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.exercise-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.exercise-card:hover::before {
    opacity: 1;
}

.cluster-card {
    background-color: var(--v-surface-lighten1);
}

.cluster-expanded {
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.sub-exercise-item {
    transition: background-color 0.2s ease;
    border-bottom: 1px solid rgba(var(--v-border-color), 0.12);
    position: relative;
    padding-left: 40px;
}

/* Update the vertical connector style */
.sub-exercise-item::before {
    content: '';
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    width: 2px;
    background: var(--v-success-base);
}

/* Add gradient fade for first item */
.sub-exercise-item:first-child::before {
    background: linear-gradient(to bottom, transparent, var(--v-success-base));
    top: 0;
}

/* Add gradient fade for last item */
.sub-exercise-item:last-child::before {
    background: linear-gradient(to bottom, var(--v-success-base), transparent);
    bottom: 0;
}

/* Update horizontal connector */
.sub-exercise-item::after {
    content: '';
    position: absolute;
    left: 20px;
    top: 50%;
    width: 20px;
    height: 2px;
    background: var(--v-success-base);
}

/* Add padding to sub-exercise content */
.sub-exercise-content {
    padding: 1rem;
}

.sub-exercise-item:last-child {
    border-bottom: none;
}

.sub-exercise-item:hover {
    background-color: rgba(var(--v-primary-base), 0.05);
}

/* Theme specific styles */
.theme--dark .exercise-card {
    background-color: rgba(255, 255, 255, 0.05);
}

.theme--dark .sub-exercise-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.theme--dark .timeline-avatar {
    border-color: rgba(0, 0, 0, 0.3);
}

/* Rest of the styles remain the same */

.exercise-stats-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.stat-chip {
    height: 24px !important;
    font-size: 0.75rem;
}

.cluster-header-content {
    flex-grow: 1;
}

.cluster-stats {
    opacity: 0.8;
}

.sub-exercise-content {
    position: relative;
}

.sub-exercise-content::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: var(--v-success-lighten2);
    opacity: 0;
    transition: opacity 0.2s ease;
}

.sub-exercise-item:hover .sub-exercise-content::before {
    opacity: 1;
}

/* Theme specific updates */
.theme--dark .sub-exercise-content::before {
    background-color: var(--v-success-darken1);
}

.theme--dark .cluster-stats {
    opacity: 0.7;
}

/* Add these new style rules */
.cluster-header-content .d-flex .text-h6,
.cluster-header-content .d-flex div,
.exercise-card .v-card-title div:first-child,
.sub-exercise-content .subtitle-1 {
    word-break: break-word;
}
</style>
