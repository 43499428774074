<!-- correct ISAK accreditation scheme, here is the enumeration of direct measurements for each level:

ISAK Level 1 (Technician - Restricted Profile Anthropometrist)
A Level 1 anthropometrist is trained to measure a total of 21 anthropometric dimensions, comprising:

Base Measures (4):

Stature
Body Mass
Sitting Height
Arm Span
Skinfolds (8): 5. Triceps 6. Subscapular 7. Biceps 8. Iliac Crest 9. Supraspinale 10. Abdominal 11. Front Thigh 12. Medial Calf

Girths (6): 13. Arm (Relaxed) 14. Arm (Flexed and Tensed) 15. Waist 16. Hip 17. Thigh 18. Calf

Breadths (3): 19. Humerus 20. Femur 21. Biacromial (Shoulder Width)

ISAK Level 2 (Technician - Full Profile Anthropometrist)
A Level 2 anthropometrist is trained to measure a total of 43 anthropometric dimensions, including:

Base Measures (4):

Stature
Body Mass
Sitting Height
Arm Span
Skinfolds (8): 5. Triceps 6. Subscapular 7. Biceps 8. Iliac Crest 9. Supraspinale 10. Abdominal 11. Front Thigh 12. Medial Calf

Girths (13): 13. Head 14. Neck 15. Arm (Relaxed) 16. Arm (Flexed and Tensed) 17. Forearm 18. Wrist 19. Chest 20. Waist 21. Hip 22. Thigh (Proximal) 23. Thigh (Mid) 24. Calf 25. Ankle

Lengths and Heights (9): 26. Stature 27. Sitting Height 28. Arm Span 29. Upper Arm Length 30. Forearm Length 31. Hand Length 32. Upper Leg Length 33. Lower Leg Length 34. Foot Length

Breadths and Depths (9): 35. Biacromial (Shoulder Width) 36. Biiliocristal (Hip Width) 37. Chest Depth 38. Chest Width 39. Elbow Breadth 40. Wrist Breadth 41. Knee Breadth 42. Ankle Breadth 43. Head Breadth

These measurements enable anthropometrists to assess body composition, proportions, and somatotype accurately. -->


<template>
    <v-form ref="form" v-model="valid">
        <v-toolbar flat>
            <v-btn icon @click="$emit('close')">
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-toolbar-title class="text-h6">
                Evaluación ISAK-{{ isakLevel }}
            </v-toolbar-title>

            <v-spacer></v-spacer>
            <!-- :disabled="!valid" -->
            <v-btn v-if="!isReadonly" color="primary" @click="submitForm" :loading="submitting">
                <v-icon left>mdi-content-save</v-icon>
                Guardar Evaluación
            </v-btn>
            <v-btn v-else color="primary" @click="startEditing">
                <v-icon left>mdi-pencil</v-icon>
                Actualizar
            </v-btn>

        </v-toolbar>

        <v-divider></v-divider>

        <v-card class="form-card pa-4" elevation="0">
            <v-data-table dense :items="tableItems" :items-per-page="-1" hide-default-footer class="elevation-1">
                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th>Categoría</th>
                            <th>Medida</th>
                            <!-- Historical headers -->
                            <template v-for="hist in sortedHistoricalData">
                                <th :key="hist.id" class="text-center historical-header">
                                    {{ formatDate(hist.date) }}
                                </th>
                            </template>
                            <th class="text-center">Valor Actual</th>
                        </tr>
                    </thead>
                </template>

                <template v-slot:item="{ item }">
                    <tr>
                        <td><v-chip small>{{ item.category }}</v-chip></td>
                        <td>{{ item.label }}</td>
                        <!-- Historical values columns -->
                        <template v-for="hist in sortedHistoricalData">
                            <td :key="hist.id" class="text-center historical-cell">
                                {{ hist.data[item.section][item.key] || '-' }}
                            </td>
                        </template>
                        <!-- Current value column -->
                        <td>
                            <measurement-field v-model="formData[item.section][item.key]" :label="item.label"
                                :unit="item.unit" :icon="item.icon" :rules="[rules.required, rules.number]"
                                :readonly="isReadonly" dense>
                                <template v-slot:append>
                                    <v-chip x-small label :color="item.isak2 ? 'secondary' : 'primary'">
                                        {{ item.isak2 ? 'ISAK-2' : 'ISAK-1' }}
                                    </v-chip>
                                </template>
                            </measurement-field>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- Photos Section - Keep this separate -->
            <v-card class="mt-6" elevation="1">
                <v-card-title>
                    <v-icon left color="primary">mdi-camera</v-icon>
                    Fotos
                    <v-spacer></v-spacer>
                    <v-chip small color="info" class="mr-2">3 fotos</v-chip>
                    <v-chip :color="getPhotosProgress" small>
                        {{ selectedPhotosCount }}/3
                    </v-chip>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="4" v-for="view in photoViews" :key="view.id">
                            <v-card outlined class="pa-4" max-height="">
                                <div class="text-center mb-4">
                                    <div class="text-h6">{{ view.label }}</div>
                                    <!--  <v-icon large color="primary">{{ view.icon }}</v-icon> -->
                                </div>

                                <div v-if="photos[view.id]" class="mb-4 text-center">
                                    <v-img :src="photos[view.id].preview" max-height="100" contain
                                        class="grey lighten-2"></v-img>
                                </div>

                                <v-file-input v-model="photos[view.id].file" :label="`Foto ${view.label}`"
                                    prepend-icon="mdi-camera" accept="image/*"
                                    @change="handlePhotoSelect($event, view.id)" :disabled="isReadonly" outlined
                                    dense></v-file-input>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <!-- Submit Button -->
            <v-card-actions class="justify-center mt-6">
                <v-btn v-if="!isReadonly" color="primary" x-large :disabled="!valid" @click="submitForm" class="px-12"
                    :loading="submitting">
                    <v-icon left>mdi-content-save</v-icon>
                    Guardar Evaluación
                </v-btn>
                <v-btn v-else color="primary" large @click="startEditing">
                    <v-icon left>mdi-pencil</v-icon>
                    Actualizar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form>
</template>

<script>
import MeasurementField from './MeasurementField.vue'
import { getFirestore, addDoc, collection, doc, updateDoc, query, where, orderBy, limit, getDocs } from "firebase/firestore";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import moment from 'moment';

export default {
    name: 'IsakAnthropometricForm',
    components: {
        MeasurementField
    },
    props: {
        isakLevel: {
            type: Number,
            default: 1,
            validator: value => [1, 2].includes(value)
        },
        evaluationId: {
            type: String,
            required: false,
            default: null
        },
        userId: {
            type: String,
            required: false,
            default: null
        },
        formInput: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            valid: false,
            formData: {
                basicMeasurements: {
                    bodyWeight: null,        // Peso Corporal
                    standingHeight: null,    // Estatura Completa
                    sittingHeight: null,     // Estatura Sentada (ISAK-2)
                    armSpan: null,          // Envergadura de Brazos (ISAK-2)
                },
                skinfolds: {
                    triceps: null,           // ISAK-1&2
                    subscapular: null,       // ISAK-1&2
                    biceps: null,            // ISAK-1&2
                    iliacCrest: null,        // ISAK-1&2
                    supraspinale: null,       // ISAK-1&2
                    abdominal: null,         // ISAK-1&2
                    frontThigh: null,        // ISAK-1&2
                    medialCalf: null,        // ISAK-1&2
                },
                girths: {
                    head: null, // ISAK-2
                    neck: null, // ISAK-2
                    relaxedArm: null,        // ISAK-1&2
                    flexedArm: null,         // ISAK-1&2
                    forearmMaximum: null,     // ISAK-2
                    wrist: null,              // ISAK-2
                    chest: null,             // ISAK-2
                    waist: null,             // ISAK-1&2
                    hips: null,               // ISAK-1&2
                    thigh: null,             // ISAK-2
                    midThigh: null,          // ISAK-2
                    calfMaximum: null,              // ISAK-1&2
                    ankle: null,              // ISAK-2
                },
                lengths: {
                    acromiale_radiale: null,
                    radiale_stylon: null,
                    mid_stylon_dactylion: null,
                    iliospinale_height: null,
                    tronchaterion_height: null,
                    tronchaterion_tibiale_laterale: null,
                    tibiale_laterale_height: null,
                    tibiale_mediale_sphyrion: null,
                },
                breadths: {
                    biacromial: null,        // ISAK-2
                    a_p_abdominal_depth: null, // ISAK-2
                    biiliocristal: null,     // ISAK-2
                    foot_length: null,       // ISAK-2
                    transverseChest: null,    // ISAK-2
                    a_p_chest_depth: null,          // ISAK-2
                    humerus: null, // ISAK-1&2
                    bi_styloid: null, // ISAK-2
                    femur: null, // ISAK-1&2
                },
            },
            // Inicializar las propiedades adicionales de ISAK-2 solo cuando sea necesario
            isak2Data: null,
            rules: {
                required: v => !!v || 'Este campo es requerido',
                number: v => !isNaN(v) || 'Debe ser un número',
            },
            measurementFields: {
                basic: {
                    bodyWeight: {
                        label: 'Peso Corporal',
                        unit: 'kg',
                        icon: 'mdi-weight',
                        isak1: true
                    },
                    standingHeight: {
                        label: 'Estatura Completa',
                        unit: 'cm',
                        icon: 'mdi-human-male-height',
                        isak1: true
                    },
                    sittingHeight: {
                        label: 'Estatura Sentada',
                        unit: 'cm',
                        icon: 'mdi-human-male-height',
                        isak2: true
                    },
                    armSpan: {
                        label: 'Envergadura de Brazos',
                        unit: 'cm',
                        icon: 'mdi-human-male-height',
                        isak2: true
                    }
                },
                skinfolds: {
                    triceps: { label: 'Tricipital', unit: 'mm', isak1: true },
                    subscapular: { label: 'Subescapular', unit: 'mm', isak1: true },
                    biceps: { label: 'Bíceps', unit: 'mm', isak1: true },
                    iliacCrest: { label: 'Cresta Ilíaca', unit: 'mm', isak1: true },
                    supraspinale: { label: 'Supraespinal', unit: 'mm', isak1: true },
                    abdominal: { label: 'Abdominal', unit: 'mm', isak1: true },
                    frontThigh: { label: 'Muslo Anterior', unit: 'mm', isak1: true },
                    medialCalf: { label: 'Pantorrilla Medial', unit: 'mm', isak1: true },
                },
                girths: {
                    head: { label: 'Longitud de la Cabeza', unit: 'cm', isak2: true },
                    neck: { label: 'Longitud del Cuello', unit: 'cm', isak2: true },
                    relaxedArm: { label: 'Brazo Relajado', unit: 'cm', isak1: true },
                    flexedArm: { label: 'Brazo Flexionado y Tenso', unit: 'cm', isak1: true },
                    forearmMaximum: { label: 'Antebrazo Máximo', unit: 'cm', isak2: true },
                    wrist: { label: 'Muñeca', unit: 'cm', isak2: true },
                    chest: { label: 'Pectoral', unit: 'mm', isak2: true },
                    waist: { label: 'Cintura (nivel más estrecho)', unit: 'cm', isak1: true },
                    hip: { label: 'Cadera', unit: 'cm', isak1: true },
                    thigh: { label: 'Muslo', unit: 'cm', isak2: true },
                    midThigh: { label: 'Muslo Medio', unit: 'cm', isak2: true },
                    calfMaximum: { label: 'Pantorrilla Máxima', unit: 'cm', isak1: true },
                    ankle: { label: 'Ankle', unit: 'cm', isak2: true },
                },
                lengths: {
                    acromiale_radiale: { label: 'Acromial-Radial', unit: 'cm', isak2: true },
                    radiale_stylon: { label: 'Radiale-Stylón', unit: 'cm', isak2: true },
                    mid_stylon_dactylion: { label: 'Mid-Stylón-Dactylion', unit: 'cm', isak2: true },
                    iliospinale_height: { label: 'Iliospinale-Altura', unit: 'cm', isak2: true },
                    tronchaterion_height: { label: 'Tronchaterion-Altura', unit: 'cm', isak2: true },
                    tronchaterion_tibiale_laterale: { label: 'Tronchaterion-Tibiale-Lateral', unit: 'cm', isak2: true },
                    tibiale_laterale_height: { label: 'Tibiale-Lateral-Altura', unit: 'cm', isak2: true },
                    tibiale_mediale_sphyrion: { label: 'Tibiale-Medial-Sphyrion', unit: 'cm', isak2: true },
                },
                breadths: {
                    biacromial: { label: 'Biacromial', unit: 'cm', isak2: true },
                    a_p_abdominal_depth: { label: 'Profundidad del Abdomen', unit: 'cm', isak2: true },
                    biiliocristal: { label: 'Biiliocristal', unit: 'cm', isak2: true },
                    foot_length: { label: 'Longitud del Pie', unit: 'cm', isak2: true },
                    transverseChest: { label: 'Transversal del Pecho', unit: 'cm', isak2: true },
                    a_p_chest_depth: { label: 'Anteroposterior del Pecho', unit: 'cm', isak2: true },
                    humerus: { label: 'Diámetro del Codo', unit: 'cm', isak1: true },
                    bi_styloid: { label: 'Bi-Styloid', unit: 'cm', isak2: true },
                    femur: { label: 'Diámetro de la Rodilla', unit: 'cm', isak1: true },
                }
            },
            submitting: false,
            originalData: null, // To store the original data when editing
            photos: {
                front: { file: null, preview: null },
                back: { file: null, preview: null },
                side: { file: null, preview: null }
            },
            photoViews: [
                { id: 'front', label: 'Frente', icon: 'mdi-account-outline' },
                { id: 'back', label: 'Espalda', icon: 'mdi-account-outline' },
                { id: 'side', label: 'Perfil', icon: 'mdi-account-outline' }
            ],
            editingDoc: null,
            historicalData: [], // Add this new property
        }
    },
    async created() {
        if (this.formInput) {
            debugger
            this.formData = this.formInput.data;
            // Initialize photos with null values first
            Object.keys(this.photos).forEach(key => {
                this.photos[key] = { file: null, preview: null };
            });
            // Load saved photos if they exist
            if (this.formInput.data.photos) {
                Object.entries(this.formInput.data.photos).forEach(([view, url]) => {
                    if (url) {
                        this.photos[view] = {
                            file: null,
                            preview: url
                        };
                    }
                });
            }
        }

        // Load historical data
        await this.loadHistoricalData();
    },
    methods: {
        initializeIsak2Data() {
            // Agregar las propiedades adicionales para ISAK-2
            /*  this.formData.additionalSkinfolds = {
                 midaxillary: '',
                 chest: '',
                 subscapularX: '',
                 abdominalX: '',
                 thighX: '',
             }
             this.formData.additionalGirths = {
                 head: '',
                 neck: '',
                 chest: '',
                 forearmRelaxed: '',
                 forearmMaximum: '',
                 wrist: '',
                 thigh: '',
                 thighMid: '',
                 ankle: '',
             } */
            // ... inicializar otras propiedades de ISAK-2
        },
        async submitForm() {
            if (this.$refs.form.validate()) {
                this.submitting = true;
                try {
                    let user_id;

                    if (this.$props.userId) {
                        user_id = this.$props.userId;
                    } else {
                        user_id = this.$store.state.Auth.token.claims.user_id;
                    }

                    // Upload photos if any are selected
                    const photoUrls = await this.uploadPhotos(user_id);

                    const db = getFirestore();
                    let anthropometryId;

                    const formDataWithPhotos = {
                        ...this.formData,
                        photos: photoUrls
                    };

                    // Check if we're updating an existing document using editingDoc
                    if (this.editingDoc?.id) {
                        await updateDoc(doc(db, 'anthropometry', this.editingDoc.id), {
                            data: formDataWithPhotos,
                            user_id: user_id,
                            lastUpdated: new Date(),
                            evaluator_id: this.$store.state.Auth.token.claims.user_id
                        });
                        anthropometryId = this.editingDoc.id;
                    } else {
                        const anthropometryDoc = await addDoc(collection(db, 'anthropometry'), {
                            date: new Date(),
                            data: formDataWithPhotos,
                            user_id: user_id,
                            evaluationId: this.evaluationId,
                            isakLevel: this.isakLevel,
                            evaluator_id: this.$store.state.Auth.token.claims.user_id
                        });
                        anthropometryId = anthropometryDoc.id;
                    }

                    // Emit similar data structure as FormRunner for consistency
                    this.$emit('submitted', {
                        anthropometryId: anthropometryId,
                        evaluatorId: user_id,
                        date: new Date(),
                        evaluationId: this.evaluationId
                    });

                    this.$notify({
                        title: this.originalData ? 'Evaluación Actualizada' : 'Evaluación Guardada',
                        message: this.originalData
                            ? 'La evaluación antropométrica se ha actualizado correctamente.'
                            : 'La evaluación antropométrica se ha guardado correctamente.',
                        type: 'success'
                    });

                    // Clear the editing state
                    this.editingDoc = null;
                    this.originalData = null;

                } catch (error) {
                    console.error('Error al guardar evaluación antropométrica:', error);
                    this.$notify({
                        title: 'Error',
                        message: 'Error al guardar evaluación antropométrica.',
                        type: 'error'
                    });
                } finally {
                    this.submitting = false;
                }
            }
        },
        resetForm() {
            if (this.$refs.form) {
                this.$refs.form.reset()
                // Reset form data
                Object.keys(this.formData).forEach(key => {
                    if (typeof this.formData[key] === 'object') {
                        Object.keys(this.formData[key]).forEach(subKey => {
                            this.formData[key][subKey] = ''
                        })
                    } else {
                        this.formData[key] = ''
                    }
                })
                // Reset photos
                Object.keys(this.photos).forEach(key => {
                    this.photos[key] = { file: null, preview: null };
                });
            }
        },
        getSectionProgress(section) {
            let completed = 0
            let total = 0

            switch (section) {
                case 'basicMeasurements':
                    total = this.isakLevel === 1 ? 2 : 3
                    const basicFields = Object.entries(this.measurementFields.basic)
                        .filter(([_, field]) => this.isakLevel === 2 || field.isak1)
                        .map(([key]) => key)
                    completed = basicFields
                        .map(key => this.formData.basicMeasurements[key])
                        .filter(Boolean).length
                    break
                case 'skinfolds':
                    total = this.isakLevel === 1 ? 7 : 10
                    const skinfoldFields = Object.entries(this.measurementFields.skinfolds)
                        .filter(([_, field]) => this.isakLevel === 2 || field.isak1)
                        .map(([key]) => key)
                    completed = skinfoldFields
                        .map(key => this.formData.skinfolds[key])
                        .filter(Boolean).length
                    break
                case 'girths':
                    total = this.isakLevel === 1 ? 6 : 14
                    const girthFields = Object.entries(this.measurementFields.girths)
                        .filter(([_, field]) => this.isakLevel === 2 || field.isak1)
                        .map(([key]) => key)
                    completed = girthFields
                        .map(key => this.formData.girths[key])
                        .filter(Boolean).length
                    break
                case 'lengths':
                    total = 3
                    completed = Object.values(this.formData.lengths)
                        .filter(Boolean).length
                    break
                case 'breadths':
                    total = this.isakLevel === 1 ? 2 : 7
                    const breadthFields = Object.entries(this.measurementFields.breadths)
                        .filter(([_, field]) => this.isakLevel === 2 || field.isak1)
                        .map(([key]) => key)
                    completed = breadthFields
                        .map(key => this.formData.breadths[key])
                        .filter(Boolean).length
                    break
                case 'segments':
                    total = 5
                    completed = Object.values(this.formData.segments)
                        .filter(Boolean).length
                    break
            }

            return {
                completed,
                total,
                color: completed === total ? 'success' : completed === 0 ? 'error' : 'warning'
            }
        },
        startEditing() {
            // Store the original data in case we need to revert
            this.originalData = JSON.parse(JSON.stringify(this.formData));
            // Instead of nullifying formInput, store it
            this.editingDoc = { ...this.formInput };
            // Exit readonly mode but keep the reference
            this.$emit('update:formInput', null);
        },
        handlePhotoSelect(file, viewId) {
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.photos[viewId].preview = e.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.photos[viewId].preview = null;
            }
        },
        async uploadPhotos(userId) {
            const storage = getStorage();
            const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
            const uploadPromises = [];
            const uploadedUrls = {};
            const oldUrls = this.formInput?.data?.photos || {};
            let hasChanges = false;

            // First preserve ALL existing URLs
            Object.assign(uploadedUrls, oldUrls);

            // Then process each photo slot
            for (const [view, photo] of Object.entries(this.photos)) {
                if (photo.file) {
                    // New file to upload
                    hasChanges = true;
                    const fileExt = photo.file.name.split('.').pop();
                    const path = `anthropometry/${userId}/${view}-${timestamp}.${fileExt}`;
                    const fileRef = storageRef(storage, path);

                    const uploadPromise = uploadBytes(fileRef, photo.file)
                        .then(snapshot => getDownloadURL(snapshot.ref))
                        .then(url => {
                            uploadedUrls[view] = url;
                        });

                    uploadPromises.push(uploadPromise);
                } else if (photo.preview && !photo.file) {
                    // Existing photo without changes - ensure it's preserved
                    uploadedUrls[view] = photo.preview;
                } else {
                    // No photo for this slot - ensure it's not in uploadedUrls
                    delete uploadedUrls[view];
                }
            }

            if (uploadPromises.length > 0) {
                await Promise.all(uploadPromises);
                if (hasChanges) {
                    await this.cleanupOldPhotos(oldUrls, uploadedUrls);
                }
            }

            return uploadedUrls;
        },
        async cleanupOldPhotos(oldUrls, newUrls) {
            const storage = getStorage();
            const deletedPhotos = [];

            // Find URLs that were in oldUrls but not in newUrls
            for (const [view, oldUrl] of Object.entries(oldUrls || {})) {
                if (!newUrls[view] || newUrls[view] !== oldUrl) {
                    try {
                        // Extract the path from the URL
                        const urlPath = decodeURIComponent(oldUrl.split('/o/')[1].split('?')[0]);
                        const fileRef = storageRef(storage, urlPath);
                        await deleteObject(fileRef);
                        deletedPhotos.push(view);
                    } catch (error) {
                        console.error(`Error deleting old ${view} photo:`, error);
                    }
                }
            }

            if (deletedPhotos.length > 0) {
                console.log('Cleaned up old photos:', deletedPhotos);
            }
        },
        async loadHistoricalData() {
            if (!this.userId) return;

            try {
                const db = getFirestore();
                const anthropometryRef = collection(db, 'anthropometry');

                const historicalQuery = query(
                    anthropometryRef,
                    where('user_id', '==', this.userId),
                    where('date', '<', new Date()),
                    orderBy('date', 'desc'),
                    limit(4)
                );

                const querySnapshot = await getDocs(historicalQuery);
                this.historicalData = querySnapshot.docs
                    .map(doc => ({
                        id: doc.id,
                        date: doc.data().date.toDate(),
                        data: doc.data().data
                    }))
                    .filter(hist => hist.id !== this.formInput?.id);
            } catch (error) {
                console.error('Error loading historical data:', error);
            }
        },
        formatDate(date) {
            debugger
            return moment(date).format('DD/MM/YYYY');
        },
    },
    computed: {
        totalProgress() {
            const sections = ['basicMeasurements', 'skinfolds', 'girths', 'breadths']
            if (this.isakLevel === 2) {
                sections.push('lengths')
            }

            const progress = sections.map(section => this.getSectionProgress(section))
            const totalCompleted = progress.reduce((sum, p) => sum + p.completed, 0)
            const totalFields = progress.reduce((sum, p) => sum + p.total, 0)

            return {
                completed: totalCompleted,
                total: totalFields,
                percentage: Math.round((totalCompleted / totalFields) * 100)
            }
        },
        isReadonly() {
            return !!this.formInput;
        },
        selectedPhotosCount() {
            return Object.values(this.photos).filter(p => p.file || p.preview).length;
        },
        getPhotosProgress() {
            const count = this.selectedPhotosCount;
            return count === 0 ? 'error' : count === 3 ? 'success' : 'warning';
        },
        tableHeaders() {
            const baseHeaders = [
                { text: 'Categoría', value: 'category' },
                { text: 'Medida', value: 'label' },
            ];

            // Add headers for historical data with dates
            const historicalHeaders = this.sortedHistoricalData.map(hist => ({
                text: 'Histórico',
                value: `historical_${hist.id}`,
                align: 'center',
                class: 'historical-header',
                sortable: false,
                date: hist.date // Pass the date to use it in the header slot
            }));

            // Add current value header
            const currentHeader = {
                text: 'Valor Actual',
                value: 'value',
                align: 'center'
            };

            return [...baseHeaders, ...historicalHeaders, currentHeader];
        },
        sortedHistoricalData() {
            // Sort historical data from oldest to newest
            return [...this.historicalData].sort((a, b) => a.date - b.date);
        },
        tableItems() {
            const items = []

            // Basic Measurements (keep as is - already has correct icons)
            Object.entries(this.measurementFields.basic).forEach(([key, field]) => {
                if (this.isakLevel === 2 || field.isak1) {
                    items.push({
                        category: 'Medidas Básicas',
                        key,
                        label: field.label,
                        unit: field.unit,
                        icon: field.icon,
                        section: 'basicMeasurements',
                        isak2: field.isak2
                    })
                }
            })

            // Skinfolds
            Object.entries(this.measurementFields.skinfolds).forEach(([key, field]) => {
                if (this.isakLevel === 2 || field.isak1) {
                    items.push({
                        category: 'Pliegues Cutáneos',
                        key,
                        label: field.label,
                        unit: 'mm',
                        icon: 'mdi-percent', // Skinfolds icon
                        section: 'skinfolds',
                        isak2: field.isak2
                    })
                }
            })

            // Girths
            Object.entries(this.measurementFields.girths).forEach(([key, field]) => {
                if (this.isakLevel === 2 || field.isak1) {
                    items.push({
                        category: 'Medidas de Perímetros',
                        key,
                        label: field.label,
                        unit: 'cm',
                        icon: 'mdi-tape-measure', // Girths icon
                        section: 'girths',
                        isak2: field.isak2
                    })
                }
            })

            // Lengths
            Object.entries(this.measurementFields.lengths).forEach(([key, field]) => {
                if (this.isakLevel === 2 || field.isak1) {
                    items.push({
                        category: 'Medidas de Longitudes',
                        key,
                        label: field.label,
                        unit: 'cm',
                        icon: 'mdi-arrow-expand', // Lengths icon
                        section: 'lengths',
                        isak2: field.isak2
                    })
                }
            })

            // Breadths
            Object.entries(this.measurementFields.breadths).forEach(([key, field]) => {
                if (this.isakLevel === 2 || field.isak1) {
                    items.push({
                        category: 'Medidas de Anchos',
                        key,
                        label: field.label,
                        unit: 'cm',
                        icon: 'mdi-arrow-expand-horizontal', // Breadths icon
                        section: 'breadths',
                        isak2: field.isak2
                    })
                }
            })

            // Segments
            /* Object.entries(this.measurementFields.segments).forEach(([key, field]) => {
                if (this.isakLevel === 2 || field.isak1) {
                    items.push({
                        category: 'Medidas de Segmentos',
                        key,
                        label: field.label,
                        unit: 'cm',
                        icon: 'mdi-human-male-height', // Segments icon
                        section: 'segments',
                        isak2: field.isak2
                    })
                }
            }) */

            return items
        }
    },
    emits: ['close', 'submitted', 'update:formInput'],
}
</script>

<style scoped>
.form-card {
    background: var(--v-background-base);
    border-radius: 12px;
}

.section-header {
    background: var(--v-primary-base);
    color: var(--v-primary-contrast);
    padding: 12px 20px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.v-card {
    transition: transform 0.2s, box-shadow 0.2s;
    background: var(--v-surface-base) !important;
}

.v-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.v-text-field.v-text-field--outlined:hover .v-input__control .v-input__slot {
    border-color: var(--v-primary-base);
}

.v-btn {
    text-transform: none;
    letter-spacing: 0.5px;
    font-weight: 600;
}

.v-btn:hover {
    transform: translateY(-1px);
}

.v-card-title {
    font-size: 1.25rem;
    font-weight: 600;
}

.v-input {
    transition: all 0.3s ease;
}

/* Custom scrollbar - using CSS variables for colors */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: var(--v-background-base);
}

::-webkit-scrollbar-thumb {
    background: var(--v-primary-lighten2);
    border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--v-primary-base);
}

.v-expansion-panel-header {
    padding: 12px 20px;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.v-expansion-panel-header__icon .v-icon {
    color: var(--v-primary-base);
}

.v-expansion-panels {
    border-radius: 8px;
}

.v-expansion-panel {
    margin-bottom: 8px;
    background: var(--v-surface-base) !important;
}

.v-expansion-panel-header {
    background: var(--v-surface-base);
}

.v-expansion-panel-content__wrap {
    padding: 0;
}

.v-data-table ::v-deep .v-data-table__wrapper {
    overflow-x: hidden;
}

.v-data-table ::v-deep tbody tr:hover {
    background: var(--v-surface-lighten1) !important;
}

.v-data-table ::v-deep .v-data-table__wrapper td {
    padding: 8px 16px;
}

.historical-values {
    padding: 8px;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    min-height: 40px;
}

.historical-value {
    font-size: 0.875rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 4px;
}

.theme--dark .historical-value {
    color: rgba(255, 255, 255, 0.7);
}

.theme--dark .historical-values {
    background: rgba(255, 255, 255, 0.05);
}

.historical-cell {
    background: rgba(0, 0, 0, 0.02);
    font-size: 0.875rem;
    min-width: 100px;
    padding: 0 8px;
}

.theme--dark .historical-cell {
    background: rgba(255, 255, 255, 0.05);
}

.historical-header {
    min-width: 120px;
}

.historical-header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.875rem;
}

.historical-date {
    font-weight: 500;
    white-space: nowrap;
}

.theme--dark .historical-cell {
    background: rgba(255, 255, 255, 0.05);
}

.theme--dark .historical-header {
    color: rgba(255, 255, 255, 0.7);
}
</style>