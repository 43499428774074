<template>
  <div class="form-builder">


    <v-container fluid class="pa-0">
      <!-- Top App Bar -->
      <v-app-bar flat class="form-builder-header px-4 " height="70" :app="$vuetify.breakpoint.mdAndUp"
        v-bind:class="{ 'pl-16': $vuetify.breakpoint.mdAndUp }">
        <v-btn icon @click="cancelForm" class="mr-4" :disabled="loading">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-text-field v-model="form.title" placeholder="Formulario sin título" class="form-title-field" hide-details
          dense solo flat></v-text-field>

        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="undo" :disabled="currentHistoryIndex <= 0 || loading" class="mr-2" v-bind="attrs"
              v-on="on">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span>Deshacer ({{ currentHistoryIndex }} pasos disponibles)</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="redo" :disabled="currentHistoryIndex >= history.length - 1 || loading" class="mr-4"
              v-bind="attrs" v-on="on">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </template>
          <span>Rehacer ({{ history.length - currentHistoryIndex - 1 }} pasos disponibles)</span>
        </v-tooltip>

        <!--   <v-btn @click="previewForm" color="secondary" class="mr-3" outlined rounded>
          <v-icon left>mdi-eye</v-icon>
          Previsualizar
        </v-btn> -->

        <v-btn @click="saveForm" color="primary" class="rounded-lg" :loading="loading">
          <v-icon left>mdi-content-save</v-icon>
          Guardar
        </v-btn>
      </v-app-bar>

      <!-- Main Content -->
      <v-container class="form-content">
        <v-row justify="center">
          <v-col cols="12" md="8" lg="7">
            <!-- Sections Container -->
            <draggable v-model="form.sections" group="sections" @start="drag = true" @end="drag = false"
              handle=".section-drag-handle" class="sections-container">
              <transition-group name="section">
                <v-card v-for="(section, sectionIndex) in form.sections" :key="section.id" class="section-card mb-6"
                  rounded="lg">
                  <!-- Section Header -->
                  <div class="section-header d-flex align-center pa-4">
                    <v-btn icon small class="section-drag-handle mr-2" v-if="form.sections.length > 1">
                      <v-icon>mdi-drag</v-icon>
                    </v-btn>

                    <v-text-field v-model="section.title" placeholder="Título de la sección" class="section-title-field"
                      hide-details dense solo flat></v-text-field>

                    <v-btn icon small @click="removeSection(sectionIndex)" class="delete-btn ml-2"
                      v-if="form.sections.length > 1">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>

                  <v-divider></v-divider>

                  <!-- Section Content -->
                  <div class="section-content pa-4">
                    <v-textarea v-model="section.description" placeholder="Descripción de la sección (opcional)"
                      class="section-description" hide-details auto-grow rows="1" solo flat></v-textarea>

                    <!-- Questions List -->
                    <draggable v-model="section.questions" group="questions" @start="drag = true" @end="drag = false"
                      handle=".question-drag-handle" class="questions-container mt-4">
                      <transition-group name="question">
                        <v-card v-for="(question, qIndex) in section.questions" :key="question.id"
                          class="question-card mb-4" outlined>
                          <!-- Question Header -->
                          <div class="question-header d-flex align-center pa-2">
                            <v-btn icon x-small class="question-drag-handle mr-2">
                              <v-icon>mdi-drag</v-icon>
                            </v-btn>

                            <v-select v-model="question.type" :items="questionTypes" item-text="text" item-value="value"
                              class="question-type-select" hide-details dense solo flat
                              @change="handleQuestionTypeChange(question)">
                              <template v-slot:selection="{ item }">
                                <v-icon left small>
                                  {{ getQuestionIcon(item.value) }}
                                </v-icon>
                                {{ item.text }}
                              </template>
                            </v-select>

                            <v-btn icon x-small @click="removeQuestion(section, qIndex)" class="delete-btn ml-2">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </div>

                          <!-- Question Content -->
                          <div class="question-content pa-4">
                            <v-textarea v-model="question.text" label="Pregunta" class="question-text" hide-details
                              auto-grow rows="1" outlined></v-textarea>

                            <!-- Question Type Specific Options -->
                            <div class="question-options mt-4">
                              <component :is="getQuestionConfigComponent(question.type)" :question="question">
                              </component>
                            </div>

                            <!-- Question Footer -->
                            <div class="question-footer d-flex align-center mt-4">
                              <v-spacer></v-spacer>
                              <v-switch v-model="question.required" label="Obligatorio" class="ma-0" hide-details
                                dense></v-switch>
                            </div>
                          </div>
                        </v-card>
                      </transition-group>
                    </draggable>

                    <!-- Add Question Button -->
                    <v-btn block text color="primary" class="mt-4" @click="addQuestion(section)">
                      <v-icon left>mdi-plus</v-icon>
                      Agregar Pregunta
                    </v-btn>
                  </div>
                </v-card>
              </transition-group>
            </draggable>

            <!-- Add Section Button -->
            <v-btn block outlined color="primary" class="mt-6" @click="addSection">
              <v-icon left>mdi-plus</v-icon>
              Agregar Sección
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <!-- Preview Dialog -->
    <v-dialog v-model="previewDialog" fullscreen transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="previewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Vista previa del formulario</v-toolbar-title>
        </v-toolbar>

        <v-container class="preview-container py-8">
          <v-row justify="center">
            <v-col cols="12" md="8" lg="7">
              <v-card class="preview-card" outlined>
                <!--   <v-card-title class="text-h4 pb-6">
                  {{ form.title || 'Untitled Form' }}
                </v-card-title> -->

                <v-card-text>
                  <div v-for="section in form.sections" :key="section.id" class="preview-section mb-8">
                    <h2 class="text-h5 mb-2">{{ section.title }}</h2>
                    <p class="subtitle-1 mb-6" v-if="section.description">
                      {{ section.description }}
                    </p>

                    <v-divider class="mb-6"></v-divider>

                    <v-card outlined v-for="question in section.questions" :key="question.id"
                      class="preview-question mb-6">
                      <v-card-title>{{ question.text }}</v-card-title>
                      <v-card-text>
                        <component :is="getPreviewComponent(question.type)" :question="question"></component>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getFirestore, collection, addDoc, doc, updateDoc } from 'firebase/firestore';
import draggable from 'vuedraggable';
import ShortAnswerQuestion from './questions/ShortAnswerQuestion.vue';
import LongAnswerQuestion from './questions/LongAnswerQuestion.vue';
import MultipleChoiceQuestion from './questions/MultipleChoiceQuestion.vue';
import CheckboxQuestion from './questions/CheckboxQuestion.vue';
import DateQuestion from './questions/DateQuestion.vue';
import TimeQuestion from './questions/TimeQuestion.vue';
import RatingQuestion from './questions/RatingQuestion.vue';
import SliderQuestion from './questions/SliderQuestion.vue';
import ShortAnswerQuestionConfig from './questions/ShortAnswerQuestionConfig.vue';


export default {
  components: {
    draggable,
    ShortAnswerQuestion,
    LongAnswerQuestion,
    MultipleChoiceQuestion,
    CheckboxQuestion,
    DateQuestion,
    TimeQuestion,
    RatingQuestion,
    SliderQuestion,
    ShortAnswerQuestionConfig
  },
  props: {
    selectedForm: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      form: this.selectedForm
        ? this.selectedForm
        : {
          id: null,
          title: null,
          sections: [
            {
              id: this.generateId(),
              title: null,
              description: null,
              questions: [

              ]
            }
          ]
        },
      questionTypes: [
        { text: 'Respuesta corta', value: 'short_answer' },
        { text: 'Respuesta larga', value: 'long_answer' },
        { text: 'Opción múltiple', value: 'multiple_choice' },
        { text: 'Casillas de verificación', value: 'checkbox' },
        { text: 'Fecha', value: 'date' },
        { text: 'Hora', value: 'time' },
        { text: 'Calificación', value: 'rating' },
        { text: 'Escala', value: 'slider' }
      ],
      fileTypeOptions: ['Imágenes', 'Documentos', 'Audio', 'Video'],
      previewDialog: false,
      drag: false,
      history: [],
      currentHistoryIndex: -1,
      maxHistorySize: 50,
      isHistoryMuted: false,
    };
  },
  methods: {
    getQuestionIcon(type) {
      switch (type) {
        case 'short_answer':
          return 'mdi-text-short';
        case 'long_answer':
          return 'mdi-text-long';
        case 'multiple_choice':
          return 'mdi-format-list-bulleted';
        case 'checkbox':
          return 'mdi-checkbox-multiple-marked';
        case 'date':
          return 'mdi-calendar';
        case 'time':
          return 'mdi-clock-time-four-outline';
        case 'rating':
          return 'mdi-star';
        case 'slider':
          return 'mdi-tune';
        case 'file_upload':
          return 'mdi-upload';
        default:
          return 'mdi-help-circle';
      }
    },
    generateId() {
      return '_' + Math.random().toString(36).substr(2, 9);
    },
    addSection() {
      this.saveToHistory();
      this.form.sections.push({
        id: this.generateId(),
        title: null,
        description: null,
        questions: []
      });
    },
    removeSection(index) {
      this.saveToHistory();
      if (index !== 0) {
        this.form.sections.splice(index, 1);
      }
    },
    addQuestion(section) {
      this.saveToHistory();
      const newQuestion = {
        id: this.generateId(),
        text: '',
        type: 'short_answer',
        required: false,
        options: [],
        fileTypes: [],
        configs: {
          min: 0,        // Default min value for slider
          max: 100,      // Default max value for slider
          step: 1,       // Default step value for slider
          thumbLabel: true,  // Show thumb label by default
        },
        value: null
      };
      section.questions.push(newQuestion);
    },
    removeQuestion(section, index) {
      this.saveToHistory();
      // Prevent removing the first two base questions from the first section
      section.questions.splice(index, 1);

    },
    handleQuestionTypeChange(question) {
      // Reset question-specific properties
      question.options = [];
      question.fileTypes = [];

      // Set type-specific configs
      switch (question.type) {
        case 'slider':
          question.configs = {
            min: 0,
            max: 100,
            step: 1,
            thumbLabel: true,
          };
          break;
        case 'rating':
          question.configs = {
            max: 5
          };
          break;
        default:
          question.configs = {};
      }
    },
    addOption(question) {
      question.options.push('');
    },
    removeOption(question, optionIndex) {
      question.options.splice(optionIndex, 1);
    },
    previewForm() {
      this.previewDialog = true;
    },
    async saveForm() {
      try {
        this.loading = true;
        const db = getFirestore();
        if (this.form.id) {
          // Update existing form
          await updateDoc(doc(db, 'forms', this.form.id), this.form);
        } else {
          // Create new form
          const docRef = await addDoc(collection(db, 'forms'), this.form);
          this.form.id = docRef.id;
        }
        console.log('Form saved successfully');
        this.$emit('formSaved');
        this.loading = false;
      } catch (error) {
        console.error('Error saving form:', error);
        this.loading = false;
      }
    },
    cancelForm() {
      this.$emit('formCanceled');
    },
    getQuestionConfigComponent(type) {
      const componentMap = {
        short_answer: 'ShortAnswerQuestion',
        long_answer: 'LongAnswerQuestion',
        multiple_choice: 'MultipleChoiceQuestion',
        checkbox: 'CheckboxQuestion',
        date: 'DateQuestion',
        time: 'TimeQuestion',
        rating: 'RatingQuestion',
        slider: 'SliderQuestion'
      };
      return componentMap[type] || 'ShortAnswerQuestion';
    },
    getPreviewComponent(type) {
      // You can create separate preview components or use the same ones
      return this.getQuestionConfigComponent(type);
    },
    saveToHistory() {
      if (this.isHistoryMuted) return;

      // Remove any future history entries if we're not at the latest state
      if (this.currentHistoryIndex < this.history.length - 1) {
        this.history = this.history.slice(0, this.currentHistoryIndex + 1);
      }

      // Create a deep copy of the current form state
      const formCopy = JSON.parse(JSON.stringify(this.form));

      // Add current state to history
      this.history.push(formCopy);
      this.currentHistoryIndex++;

      // Limit history size
      if (this.history.length > this.maxHistorySize) {
        this.history.shift();
        this.currentHistoryIndex--;
      }
    },
    undo() {
      if (this.currentHistoryIndex > 0) {
        this.isHistoryMuted = true;
        this.currentHistoryIndex--;
        this.form = JSON.parse(JSON.stringify(this.history[this.currentHistoryIndex]));
        this.$nextTick(() => {
          this.isHistoryMuted = false;
        });
      }
    },
    redo() {
      if (this.currentHistoryIndex < this.history.length - 1) {
        this.isHistoryMuted = true;
        this.currentHistoryIndex++;
        this.form = JSON.parse(JSON.stringify(this.history[this.currentHistoryIndex]));
        this.$nextTick(() => {
          this.isHistoryMuted = false;
        });
      }
    }
  },
  watch: {
    'form': {
      deep: true,
      handler(newVal) {
        if (!this.isHistoryMuted) {
          // Debounce the history save to prevent too frequent updates
          if (this.saveTimeout) clearTimeout(this.saveTimeout);
          this.saveTimeout = setTimeout(() => {
            this.saveToHistory();
          }, 300);
        }
      }
    }
  },
  created() {
    this.$nextTick(() => {
      this.saveToHistory(); // Save initial state after component is fully created
    });
  }
};
</script>

<style scoped>
.form-builder {
  background: var(--v-background-base);
  min-height: 100vh;
}

.form-builder-header {
  background: var(--v-surface-base);
  border-bottom: 1px solid var(--v-border-base, rgba(0, 0, 0, 0.08));
}

.form-title-field ::v-deep .v-input__slot {
  background: transparent !important;
}

.section-card {
  background: var(--v-surface-base);
  border: 1px solid var(--v-border-base, rgba(0, 0, 0, 0.08));
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.theme--dark .section-card {
  background: var(--v-secondary-darken2);
}

.section-card:hover {
  box-shadow: 0 4px 20px var(--v-shadow-base, rgba(0, 0, 0, 0.08));
}

.question-card {
  background: var(--v-surface-base);
  transition: all 0.2s ease;
}

.question-card:hover {
  border-color: var(--v-primary-base);
}

.delete-btn {
  opacity: 0.6;
  transition: opacity 0.2s;
}

.delete-btn:hover {
  opacity: 1;
  color: var(--v-error-base);
}

/* Transitions */
.section-enter-active,
.section-leave-active,
.question-enter-active,
.question-leave-active {
  transition: all 0.3s ease;
}

.section-enter,
.section-leave-to,
.question-enter,
.question-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.preview-container {
  background: var(--v-background-base);
}

.preview-card {
  background: var(--v-surface-base);
}

/* Additional theme-aware styles */
.section-header {
  background: var(--v-surface-variant-base, rgba(0, 0, 0, 0.02));
}

.question-header {
  background: var(--v-surface-variant-base, rgba(0, 0, 0, 0.02));
}

.drag-handle {
  color: var(--v-text-secondary-base, rgba(0, 0, 0, 0.6));
}
</style>
