<template>
  <v-container fluid class="pa-0">
    <v-card class=" pa-0" rounded="0">
      <v-toolbar flat>
        <v-text-field filled rounded class="rounded-lg" dense single-line v-model="search" label="Buscar Métricas"
          append-icon="mdi-magnify" hide-details=""></v-text-field>

        <v-spacer></v-spacer>
        <v-btn color="primary" @click="openCreateDialog" class="rounded-lg">
          <v-icon left>mdi-plus</v-icon>
          Nueva Métrica</v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table :headers="headers" :items="filteredMetrics" :search="search" :items-per-page="10"
          :loading="loading">
          <template v-slot:item.linked="{ item }">
            <div>
              <v-span dark v-if="item.linked && item.exerciseName">
                {{ item.exerciseName }}
              </v-span>
              <span v-else>Independiente</span>
            </div>
          </template>

          <template v-slot:item.type="{ item }">
            <div>
              <v-chip v-if="item.type != null" dark>
                <v-avatar size="20" :color="returnColor(item.type)" class="mr-4">



                  <v-icon v-if="item.type == 0" small>
                    mdi-weight-kilogram
                  </v-icon>
                  <v-icon v-else-if="item.type == 1" small>
                    mdi-counter
                  </v-icon>
                  <v-icon v-else-if="item.type == 2 || item.type == 5" small>
                    mdi-clock-time-four-outline
                  </v-icon>
                  <v-icon v-else-if="item.type == 3" small>
                    mdi-ruler
                  </v-icon>
                  <v-icon v-else small>
                    mdi-weight-kilogram
                  </v-icon>
                </v-avatar>



                {{ metricTypes.find(t => t.value === item.type).text }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.tags="{ item }">
            <div>
              <v-chip v-for="(tag, i) in item.tags" :key="i" class="ma-1" outlined>
                {{ tag }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="editMetric(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Editar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="confirmDelete(item)">
                  <v-list-item-icon>
                    <v-icon color="error">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Eliminar</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!-- Create/Update Dialog -->
    <v-dialog v-model="showDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ editingMetricId ? 'Editar Métrica' : 'Nueva Métrica' }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />

        <v-card-text class="pt-4">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field filled label="Nombre de la Métrica" v-model="metricForm.name" :rules="[rules.required]"
              required></v-text-field>

            <v-radio-group v-model="metricForm.linked" :rules="[rules.requiredLinked]" row>
              <v-radio label="Independiente" :value="false"></v-radio>
              <v-radio label="Vinculada a Ejercicio" :value="true"></v-radio>
            </v-radio-group>

            <v-select filled v-if="metricForm.linked" :items="exerciseOptions" item-text="name" item-value="id"
              label="Seleccionar Ejercicio" v-model="metricForm.exerciseId" :rules="[rules.requiredIfLinked]" required>
            </v-select>

            <v-select filled label="Tipo" v-model="metricForm.type" :items="metricTypes" :rules="[rules.requiredAllow0]"
              required></v-select>

            <v-autocomplete filled v-model="metricForm.tags" label="Etiquetas" multiple chips clearable
              :items="availableTags" allow-overflow hide-selected :menu-props="{ maxHeight: 300 }"
              @change="onTagsChange">
              <template v-slot:no-data>
                <v-list-item @click="addNewTag(searchTags)">
                  <v-list-item-content>
                    <v-list-item-title>
                      Añadir "<strong>{{ searchTags }}</strong>"
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>

            <v-textarea filled rows="2" auto-grow label="Descripción (opcional)"
              v-model="metricForm.description"></v-textarea>
          </v-form>
        </v-card-text>
        <v-divider />

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="success" :loading="saving" @click="saveMetric">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Delete Confirmation Dialog -->
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title>Eliminar Métrica</v-card-title>
        <v-card-text>
          ¿Estás seguro de que deseas eliminar la métrica <strong>{{ metricToDelete && metricToDelete.name }}</strong>?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="deleteDialog = false">Cancelar</v-btn>
          <v-btn color="error" :loading="deleting" @click="removeMetric">Eliminar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Notification Snackbar -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3000">
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { getFirestore, collection, doc, getDocs, setDoc, updateDoc, deleteDoc, addDoc } from "firebase/firestore";
import { Timestamp } from "firebase/firestore";

export default {
  name: "MetricsManager",
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      loading: true,
      // Data lists
      metrics: [],
      exercises: [],
      search: "",

      // Dialog states
      showDialog: false,
      deleteDialog: false,
      saving: false,
      deleting: false,

      // Form data
      metricForm: {
        name: "",
        linked: false,
        exerciseId: null,
        type: null,
        tags: [],
        icon: "",
        description: ""
      },

      editingMetricId: null,
      metricToDelete: null,
      valid: true,

      // Notifications
      snackbar: {
        show: false,
        text: "",
        color: ""
      },

      // Predefined metric types (example)
      metricTypes: [
        { text: "Max RM x Peso", value: 0 },
        { text: "Basado en Velocidad (m/s)", value: 1 },
        { text: "Basado en Tiempo (segundos)", value: 2 },
        { text: "Basado en Distancia (cm)", value: 3 },
        { text: "Peso (kg)", value: 4 },
        { text: "Mejor tiempo + Promedio (segundos)", value: 5 }
      ],

      // Available tags (can add more at runtime)
      availableTags: ["Antropométrico", "Fuerza"],
      searchTags: "",

      // Table headers
      headers: [
        { text: "Nombre", value: "name" },
        { text: "Vinculada/Independiente", value: "linked" },
        { text: "Tipo", value: "type" },
        { text: "Etiquetas", value: "tags" },
        { text: "Acciones", value: "actions", sortable: false }
      ],
      rules: {
        required: value => !!value || "Requerido",
        requiredAllow0: value => value != null,
        requiredIfLinked: value => {
          return this.metricForm.linked
            ? !!value || "Selecciona un ejercicio"
            : true;
        },
        requiredLinked: value => value !== null || "Elige una opción"
      }
    };
  },
  computed: {
    exerciseOptions() {
      // Return exercises in a format suitable for selects
      return this.exercises.map(ex => ({
        id: ex.id,
        name: ex.name,
      }));
    },
    filteredMetrics() {
      if (!this.search) return this.metrics;
      return this.metrics.filter(m =>
        m.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    returnColor(type) {
      const colors = {
        0: "#1E88E5", // Blue
        1: "#43A047", // Green
        2: "#FB8C00", // Orange
        3: "#8E24AA", // Purple
        4: "#F4511E", // Deep Orange
        5: "#3949AB"  // Indigo
      };
      return colors[type] || ""; // Default Grey
    }

    ,
    async loadData() {
      await this.loadExercises();
      await this.loadMetrics();
    },
    getUserId() {
      // Fallback logic if user prop not provided, adapt to your auth logic
      return this.user ? this.user.id : this.$store.state.Auth.token.claims.user_id;
    },
    async loadMetrics() {
      const db = getFirestore();
      const userId = this.getUserId();
      const metricsRef = collection(db, `metrics`);
      const snap = await getDocs(metricsRef);
      this.metrics = snap.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      this.loading = false
    },
    async loadExercises() {
      const db = getFirestore();
      const userId = this.getUserId();
      const exercisesRef = collection(db, `exercises`);
      const snap = await getDocs(exercisesRef);
      this.exercises = snap.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
    },
    openCreateDialog() {
      this.editingMetricId = null;
      this.resetForm();
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    async saveMetric() {
      if (!this.$refs.form.validate()) return;
      this.saving = true;

      const db = getFirestore();
      const userId = this.getUserId();

      let dataToSave = {
        name: this.metricForm.name,
        linked: this.metricForm.linked,
        type: this.metricForm.type,
        tags: this.metricForm.tags,
        icon: this.metricForm.icon || null,
        description: this.metricForm.description || null,
        updatedAt: Timestamp.fromDate(new Date())
      };

      if (this.metricForm.linked) {
        const exerciseObj = this.exercises.find(ex => ex.id === this.metricForm.exerciseId);
        dataToSave.exerciseId = this.metricForm.exerciseId;
        dataToSave.exerciseName = exerciseObj ? exerciseObj.name : null;
      } else {
        dataToSave.exerciseId = null;
        dataToSave.exerciseName = null;
      }

      try {
        if (this.editingMetricId) {
          const metricDoc = doc(db, `metrics`, this.editingMetricId);
          await updateDoc(metricDoc, dataToSave);
          this.showSnackbar("Métrica actualizada con éxito", "success");
        } else {
          const metricsRef = collection(db, `metrics`);
          await addDoc(metricsRef, {
            ...dataToSave,
            createdAt: Timestamp.fromDate(new Date())
          });
          this.showSnackbar("Métrica creada con éxito", "success");
        }
        await this.loadMetrics();
        this.closeDialog();
      } catch (error) {
        console.error(error);
        this.showSnackbar("Error al guardar la métrica", "error");
      } finally {
        this.saving = false;
      }
    },
    editMetric(metric) {
      this.editingMetricId = metric.id;
      this.metricForm.name = metric.name;
      this.metricForm.linked = metric.linked;
      this.metricForm.exerciseId = metric.exerciseId || null;
      this.metricForm.type = metric.type;
      this.metricForm.tags = metric.tags || [];
      this.metricForm.icon = metric.icon || "";
      this.metricForm.description = metric.description || "";
      this.showDialog = true;
    },
    confirmDelete(metric) {
      this.metricToDelete = metric;
      this.deleteDialog = true;
    },
    async removeMetric() {
      this.deleting = true;
      const db = getFirestore();
      const userId = this.getUserId();
      try {
        const metricDoc = doc(db, `metrics`, this.metricToDelete.id);
        await deleteDoc(metricDoc);
        this.showSnackbar("Métrica eliminada con éxito", "success");
        await this.loadMetrics();
        this.deleteDialog = false;
      } catch (error) {
        console.error(error);
        this.showSnackbar("Error al eliminar la métrica", "error");
      } finally {
        this.deleting = false;
      }
    },
    resetForm() {
      this.metricForm = {
        name: "",
        linked: false,
        exerciseId: null,
        type: null,
        tags: [],
        icon: "",
        description: ""
      };
      //this.$refs.form.resetValidation();
    },
    showSnackbar(text, color) {
      this.snackbar.text = text;
      this.snackbar.color = color;
      this.snackbar.show = true;
    },
    onTagsChange(val) {
      // If user types something that is not in the list, they can add it.
      // The v-autocomplete with no-data slot handles adding new tags.
    },
    addNewTag(tag) {
      if (tag && !this.availableTags.includes(tag)) {
        this.availableTags.push(tag);
      }
      this.metricForm.tags.push(tag);
    }
  }
};
</script>

<style scoped></style>
