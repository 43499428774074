<template>
    <v-container fluid>
        <!-- Loop over weeks -->
        <v-row v-for="(week, weekIndex) in weeks" :key="weekIndex" align-stretch>
            <v-col cols="12" class="text-center pa-0">
                <v-card class="rounded-0">
                    <v-divider />
                    <v-card-title class="pa-2">{{ 'Semana ' + (weekIndex + 1) }}
                        <v-spacer></v-spacer>
                        <v-tooltip bottom v-if="weekIndex + 1 == weeks.length">
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="addWeek">
                                    <v-icon small>
                                        mdi-plus
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Agregar semana</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn icon small v-on="on" @click="deleteWeek(weekIndex)">
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Eliminar semana</span>
                        </v-tooltip>
                    </v-card-title>
                    <v-divider />
                </v-card>
            </v-col>



            <draggable v-model="week.days" handle=".drag-handle-column" class="row no-gutters">

                <v-col v-for="(dayData, index) in week.days" :key="index"
                    class="calendar-col fill-height d-flex flex-column" @click="selectDay(dayData, index)"
                    v-bind:class="{ 'selected': dayData == selected }">
                    <v-card class="calendar-day rounded-0 fill-height" height="100%">
                        <v-toolbar class="day-number px-0" flat dense>

                            <!-- option menu -->
                            <v-menu offset-y offset-x>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on">
                                        <v-icon small>
                                            mdi-dots-vertical
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <v-list class="py-0" dense>
                                    <v-list-item @click="dayData.title = null" v-if="dayData.title">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-close</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-title>
                                            Eliminar título
                                        </v-list-item-title>
                                    </v-list-item>

                                    <v-list-item v-else @click="dayData.title = 'Titulo'">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-pencil

                                            </v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-title>Agregar titulo</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item @click="showDayReferences(dayData, index)">
                                        <v-list-item-avatar>
                                            <v-icon>mdi-plus</v-icon>
                                        </v-list-item-avatar>
                                        <v-list-item-title>Calentamiento / Enfriamiento</v-list-item-title>
                                    </v-list-item>




                                    <template v-if="dayData.exercises.length > 0">
                                        <v-divider />
                                        <v-list-item @click="dayData.exercises = []">
                                            <v-list-item-avatar>
                                                <v-icon>mdi-broom</v-icon>
                                            </v-list-item-avatar>
                                            <v-list-item-title>Limpiar ejercicios</v-list-item-title>
                                        </v-list-item>

                                    </template>




                                </v-list>

                            </v-menu>


                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on" @click="copyElems(dayData.exercises)"
                                        v-show="dayData.exercises.length > 0">
                                        <v-icon small>
                                            mdi-content-copy
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Copiar contenido</span>
                            </v-tooltip>

                            <v-tooltip top v-show="copy">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on" @click="pasteElems(dayData)" v-show="copy">
                                        <v-icon small>
                                            mdi-content-paste
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Pegar contenido</span>
                            </v-tooltip>


                            <v-tooltip top v-if="selectedExercises.length > 0">
                                <template v-slot:activator="{ on }">



                                    <v-badge color="error" overlap :content="selectedExercises.length"
                                        :value="selectedExercises.length" v-show="dayData == selected">


                                        <v-btn icon small v-on="on" v-show="dayData == selected"
                                            @click="createClusterFromSelected(dayData)">
                                            <v-icon small>
                                                mdi-plus-box
                                            </v-icon>
                                        </v-btn>
                                    </v-badge>
                                </template>
                                <span>
                                    {{
                                        selectedExercises.length == 0 ?
                                            'Selecciona ejercicios para crear un superset'
                                            : 'Crear superset / grupo con seleccionados'
                                    }}
                                </span>
                            </v-tooltip>


                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small class="drag-handle-column " v-on="on">
                                        <v-icon small>
                                            mdi-cursor-move
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Mover</span>
                            </v-tooltip>




                            <v-spacer></v-spacer>








                            {{ index + 1 }}
                        </v-toolbar>


                        <v-toolbar v-show="dayData.title != null" class="px-0 toolbar-title" flat dense
                            style="padding-left:0px !important">
                            <v-text-field label="Titulo" v-model="dayData.title" dense outlined single-line
                                hide-details=""></v-text-field>

                        </v-toolbar>

                        <v-card v-show="dayData.isRestDay && dayData.exercises.length == 0 && !moving" elevation="0"
                            class="rounded-0">
                            <v-card-text class="text-center">
                                <v-icon large>mdi-sleep</v-icon>
                                <p class="caption">Día de descanso</p>
                            </v-card-text>
                        </v-card>



                        <v-list dense class="py-0"
                            v-if="dayData != selected && dayData.warmup && dayData.warmup.length > 0">

                            <template v-for="(warmup, index) in dayData.warmup">
                                <v-list-item class="px-2" :key="index">

                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-avatar size="8" :color="listRoutines.find(r => r.id === warmup)?.color"
                                                class="mr-2">

                                            </v-avatar>
                                            {{
                                                listRoutines.find(r => r.id === warmup)?.name
                                            }}
                                        </v-list-item-title>

                                    </v-list-item-content>



                                </v-list-item>
                                <v-divider :key="index + '_warmup'" />
                            </template>
                        </v-list>


                        <v-list v-if="dayData == selected && dayData.warmup && dayData.warmup.length > 0" class="py-0">
                            <draggable v-model="dayData.warmup" group="extras" handle=".drag-handle-warmup">
                                <template v-for="(warmup, index) in dayData.warmup">
                                    <v-toolbar flat dense class="px-0 caption" :key="index" height="40">
                                        <v-avatar size="8" :color="listRoutines.find(r => r.id === warmup)?.color"
                                            class="mr-2">

                                        </v-avatar>
                                        {{ listRoutines.find(r => r.id === warmup)?.name }}
                                        <v-spacer></v-spacer>
                                        <v-btn icon small @click="removeWarmup(dayData, index)">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                        <v-btn icon small class="drag-handle-warmup">
                                            <v-icon small>mdi-cursor-move</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                    <v-divider :key="index + '_warmup'" />
                                </template>
                            </draggable>
                        </v-list>



                        <v-list dense class="py-0">
                            <draggable v-model="dayData.exercises" group="exercises" handle=".drag-handle" @end="end"
                                @start="start" :move="onMoveDayExercises" v-bind:class="{ 'dragging': moving }">

                                <v-list-item v-for="(exercise, exIndex) in dayData.exercises" :key="exIndex"
                                    @click="toggleSelect(exIndex)" :class="{
                                        'selected-item': isSelected(exIndex) && dayData == selected,
                                        'px-1': !exercise.isCluster,
                                    }" class="drag-handle px-0">

                                    <template v-if="exercise.isCluster">
                                        <!-- Render cluster -->
                                        <div class="cluster-container">
                                            <v-divider v-if="exIndex > 0" />
                                            <v-list-item class="px-2">

                                                <v-list-item-content>
                                                    <v-list-item-title
                                                        style="font-weight: bold;text-transform:uppercase">
                                                        <template v-if="exercise.title">
                                                            {{ abecedario[exIndex] }}) {{ exercise.title }}
                                                        </template>
                                                        <template v-else>
                                                            {{ abecedario[exIndex] }}) Superset
                                                        </template>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle>
                                                        {{ exercise.sets }} Vueltas{{ exercise.rest ?
                                                            ', ' + exercise.rest + `'' pausa` : ' sin descanso' }}
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-toolbar-items v-if="dayData == selected">
                                                    <v-btn icon small @click.stop="removeExercise(dayData, exIndex)">
                                                        <v-icon color="error" small>mdi-close</v-icon>
                                                    </v-btn>

                                                    <v-menu offset-y :close-on-content-click="false" max-width="500">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" text small icon>
                                                                <v-icon x-small size="8">mdi-pencil</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <!-- Menu content for editing cluster -->
                                                        <v-card>
                                                            <v-card-text>
                                                                <v-text-field label="Título" v-model="exercise.title"
                                                                    dense outlined></v-text-field>
                                                                <v-text-field label="Sets" v-model="exercise.sets"
                                                                    type="number" min="1" step="1" dense
                                                                    outlined></v-text-field>
                                                                <v-text-field label="Descanso (segundos)"
                                                                    v-model="exercise.rest" type="number" min="1"
                                                                    step="1" dense outlined></v-text-field>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>


                                                </v-toolbar-items>
                                            </v-list-item>

                                            <v-divider />

                                            <!-- Display exercises inside the cluster -->
                                            <v-list dense class="cluster-exercise-list py-0">
                                                <draggable v-model="exercise.exercises" group="exercises"
                                                    handle=".drag-handle-cluster" v-bind:class="{ 'dragging': moving }"
                                                    @end="end" @start="start">
                                                    <v-list-item v-for="(subExercise, subExIndex) in exercise.exercises"
                                                        :key="subExIndex" class="clustered-exercise">

                                                        <v-list-item-content class="pa-0 py-1">
                                                            <v-list-item-title>

                                                                <v-autocomplete style="width:200px"
                                                                    :rules="[v => !!v || 'Item is required']"
                                                                    :prefix="abecedario[exIndex] + (subExIndex + 1) + ')'"
                                                                    class="caption" v-model="subExercise.id"
                                                                    :items="exercises" dense single-line
                                                                    item-text="name" item-value="id" hide-details
                                                                    v-if="selected == dayData"></v-autocomplete>


                                                                <template v-else>
                                                                    {{ abecedario[exIndex] }}{{ subExIndex + 1 }}) {{
                                                                        exerciseHashIdNames[subExercise.id] }}
                                                                </template>



                                                            </v-list-item-title>
                                                            <v-list-item-subtitle>

                                                                <template
                                                                    v-if="subExercise.type === 'reps' && Array.isArray(subExercise.value)">
                                                                    {{ subExercise.value.join('-') }} reps
                                                                </template>
                                                                <template v-else>
                                                                    {{ subExercise.type === 'reps' ? subExercise.value +
                                                                        ' reps' : subExercise.value + `''` }}
                                                                </template>
                                                                {{ subExercise.rest ? ' x ' + subExercise.rest + `''
                                                                descanso` : '' }}
                                                                <template v-if="subExercise.rpe">
                                                                    <v-chip x-small label class="ml-1">
                                                                        RPE {{ subExercise.rpe }}
                                                                    </v-chip>
                                                                </template>
                                                            </v-list-item-subtitle>
                                                        </v-list-item-content>
                                                        <v-toolbar-items v-if="dayData == selected">
                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" icon small
                                                                        @click.stop="removeClusterExercise(exIndex, subExIndex, dayData)">
                                                                        <v-icon color="error" small>mdi-close</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar</span>
                                                            </v-tooltip>

                                                            <v-menu offset-y :close-on-content-click="false"
                                                                max-width="500">
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" text small icon>
                                                                        <v-icon x-small size="8">mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <!-- Menu content for editing sub-exercise -->
                                                                <v-card>
                                                                    <v-card-text>
                                                                        <v-select v-model="subExercise.type"
                                                                            :items="exerciseTypes" label="Tipo" dense
                                                                            outlined></v-select>
                                                                        <v-row>

                                                                            <v-col cols="12"
                                                                                v-if="subExercise.type === 'time'">
                                                                                <v-text-field
                                                                                    v-model="subExercise.value"
                                                                                    label="Segundos" type="number"
                                                                                    min="1" step="1" dense
                                                                                    outlined></v-text-field>
                                                                            </v-col>
                                                                            <v-col cols="12" v-else>
                                                                                <v-text-field
                                                                                    v-model="subExercise.valueInput"
                                                                                    label="Repeticiones (ej:12 o 12-10-8 variado)"
                                                                                    @input="handleRepsInput($event, subExercise, exercise.sets)"
                                                                                    @keypress="e => {
                                                                                        const char = String.fromCharCode(e.keyCode);
                                                                                        if (char === '-') {
                                                                                            const currentValue = subExercise.valueInput || '';
                                                                                            if (currentValue.endsWith('-') ||
                                                                                                currentValue.split('-').length - 1 >= exercise.sets - 1 ||
                                                                                                currentValue.length === 0) {
                                                                                                e.preventDefault();
                                                                                                return;
                                                                                            }
                                                                                        }
                                                                                        const pattern = /[0-9-]/;
                                                                                        if (!pattern.test(char)) e.preventDefault();
                                                                                    }" dense hide-details="" outlined
                                                                                    :hint="'Usa guiones para series piramidales'"
                                                                                    persistent-hint></v-text-field>
                                                                            </v-col>
                                                                        </v-row>
                                                                        <v-row>
                                                                            <v-col cols="6">
                                                                                <v-text-field
                                                                                    label="Descanso (segundos)"
                                                                                    v-model="subExercise.rest"
                                                                                    type="number" min="1" step="1" dense
                                                                                    outlined></v-text-field>
                                                                            </v-col>
                                                                            <v-col cols="6">
                                                                                <v-text-field label="RPE"
                                                                                    v-model="subExercise.rpe"
                                                                                    type="number" min="0" max="10"
                                                                                    step="1" dense outlined
                                                                                    :rules="[v => (v >= 0 && v <= 10) || 'RPE debe estar entre 0 y 10']"
                                                                                    hint="Escala de esfuerzo percibido (0-10)"
                                                                                    persistent-hint>
                                                                                    <template v-slot:append>
                                                                                        <RPEVisualizer
                                                                                            :rpe="Number(subExercise.rpe) || 0" />
                                                                                    </template>
                                                                                </v-text-field>
                                                                            </v-col>
                                                                        </v-row>
                                                                    </v-card-text>
                                                                </v-card>
                                                            </v-menu>

                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn icon small class="drag-handle-cluster"
                                                                        style="cursor:pointer;" v-on="on">
                                                                        <v-icon x-small>mdi-cursor-move</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Mover</span>
                                                            </v-tooltip>




                                                        </v-toolbar-items>
                                                    </v-list-item>
                                                </draggable>
                                            </v-list>
                                        </div>
                                    </template>

                                    <template v-else>
                                        <!-- Render regular exercise -->

                                        <v-list-item-content v-bind:class="{ 'py-1': selected == dayData }">
                                            <v-list-item-title style="font-weight: bold;text-transform:uppercase">


                                                <v-autocomplete style="width:200px" class="caption"
                                                    v-model="exercise.id" :items="exercises" dense single-line
                                                    :prefix="abecedario[exIndex] + ')'" item-text="name" item-value="id"
                                                    hide-details v-if="selected == dayData"></v-autocomplete>


                                                <template v-else>
                                                    {{ abecedario[exIndex] }}) {{ exerciseHashIdNames[exercise.id] }}
                                                </template>


                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ exercise.sets }} x
                                                <template
                                                    v-if="exercise.type === 'reps' && Array.isArray(exercise.value)">
                                                    {{ exercise.value.join('-') }} reps
                                                </template>
                                                <template v-else>
                                                    {{ exercise.type === 'reps' ? exercise.value + ' reps' :
                                                        exercise.value + `''` }}
                                                </template>
                                                {{ exercise.rest ? ' x ' + exercise.rest + `'' descanso` : '' }}
                                                <template v-if="exercise.rpe">
                                                    <v-chip x-small label class="ml-1">
                                                        RPE {{ exercise.rpe }}
                                                    </v-chip>
                                                </template>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-toolbar-items v-if="dayData == selected">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon small @click.stop="removeExercise(dayData, exIndex)"
                                                        v-on="on">
                                                        <v-icon color="error" small>mdi-close</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Eliminar</span>
                                            </v-tooltip>

                                            <v-menu offset-y :close-on-content-click="false" max-width="500">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text small icon>
                                                        <v-icon x-small size="8">mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <!-- Menu content for editing exercise -->
                                                <v-card>
                                                    <v-card-text>
                                                        <v-select v-model="exercise.type" :items="exerciseTypes"
                                                            label="Tipo" dense outlined></v-select>
                                                        <v-row>
                                                            <v-col cols="4">
                                                                <v-text-field label="Sets" v-model="exercise.sets"
                                                                    type="number" min="1" step="1" dense
                                                                    outlined></v-text-field>
                                                            </v-col>
                                                            <v-col cols="8" v-if="exercise.type === 'time'">
                                                                <v-text-field v-model="exercise.value" label="Segundos"
                                                                    type="number" min="1" step="1" dense
                                                                    outlined></v-text-field>
                                                            </v-col>
                                                            <v-col cols="8" v-else>
                                                                <v-text-field v-model="exercise.valueInput"
                                                                    label="Repeticiones (ej:12 o 12-10-8 variado)"
                                                                    @input="handleRepsInput($event, exercise, exercise.sets)"
                                                                    @keypress="e => {
                                                                        const char = String.fromCharCode(e.keyCode);
                                                                        if (char === '-') {
                                                                            const currentValue = exercise.valueInput || '';
                                                                            if (currentValue.endsWith('-') ||
                                                                                currentValue.split('-').length - 1 >= exercise.sets - 1 ||
                                                                                currentValue.length === 0) {
                                                                                e.preventDefault();
                                                                                return;
                                                                            }
                                                                        }
                                                                        const pattern = /[0-9-]/;
                                                                        if (!pattern.test(char)) e.preventDefault();
                                                                    }" dense hide-details="" outlined
                                                                    :hint="'Usa guiones para series piramidales'"
                                                                    persistent-hint></v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <v-text-field label="Descanso (segundos)"
                                                                    v-model="exercise.rest" type="number" min="1"
                                                                    step="1" dense outlined></v-text-field>
                                                            </v-col>
                                                            <v-col cols="6">
                                                                <v-text-field label="RPE" v-model="exercise.rpe"
                                                                    type="number" min="0" max="10" step="1" dense
                                                                    outlined
                                                                    :rules="[v => (v >= 0 && v <= 10) || 'RPE debe estar entre 0 y 10']"
                                                                    hint="Escala de esfuerzo percibido (0-10)"
                                                                    persistent-hint>
                                                                    <template v-slot:append>
                                                                        <RPEVisualizer
                                                                            :rpe="Number(exercise.rpe) || 0" />
                                                                    </template>
                                                                </v-text-field>
                                                            </v-col>
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </v-toolbar-items>
                                    </template>
                                </v-list-item>
                            </draggable>

                            <!-- Option to add new exercise -->



                        </v-list>



                        <v-menu :close-on-content-click="false" :offset-x="true" v-if="dayData == selected">
                            <template v-slot:activator="{ on: menuOn }">
                                <v-btn style="text-transform:capitalize" color="primary" elevation="0" class="rounded-0"
                                    block large v-on="{ ...menuOn }" v-show="dayData == selected">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    ejercicio
                                </v-btn>
                            </template>

                            <v-card>
                                <v-card-text>
                                    <v-select v-model="newExerciseType" :items="exerciseTypes" label="Tipo" dense
                                        outlined></v-select>
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field label="Sets" v-model="newExerciseSets" type="number" min="1"
                                                step="1" dense outlined></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field v-model="newExerciseValue"
                                                :label="newExerciseType === 'reps' ? 'Repeticiones' : 'Segundos'"
                                                type="number" min="1" step="1" dense outlined></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-autocomplete v-model="selectedExercise" :items="exercises" hide-details
                                        hide-selected label="Seleccionar ejercicio" item-text="name" item-value="id"
                                        dense outlined @change="addExercise(dayData)"></v-autocomplete>
                                </v-card-text>
                            </v-card>
                        </v-menu>


                        <v-list dense class="py-0"
                            v-if="dayData != selected && dayData.cooldown && dayData.cooldown.length > 0">
                            <template v-for="(cooldown, index) in dayData.cooldown">
                                <v-divider :key="index + '_cooldown'" />
                                <v-list-item class="px-2" :key="index">
                                    <v-list-item-content>
                                        <v-list-item-title>

                                            <v-avatar size="8" :color="listRoutines.find(r => r.id === cooldown)?.color"
                                                class="mr-2">

                                            </v-avatar>
                                            {{ listRoutines.find(r => r.id === cooldown)?.name }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                            </template>
                        </v-list>

                        <v-list v-if="dayData == selected && dayData.cooldown && dayData.cooldown.length > 0"
                            class="py-0">
                            <draggable v-model="dayData.cooldown" group="extras" handle=".drag-handle-cooldown">
                                <template v-for="(cooldown, index) in dayData.cooldown">
                                    <v-toolbar flat dense class="px-0" :key="index" height="40">
                                        <v-avatar size="8" :color="listRoutines.find(r => r.id === cooldown)?.color"
                                            class="mr-2">

                                        </v-avatar>
                                        {{ listRoutines.find(r => r.id === cooldown)?.name }}
                                        <v-spacer></v-spacer>
                                        <v-btn icon small @click="removeWarmup(dayData, index)">
                                            <v-icon small>mdi-close</v-icon>
                                        </v-btn>
                                        <v-btn icon small class="drag-handle-cooldown">
                                            <v-icon small>mdi-cursor-move</v-icon>
                                        </v-btn>
                                    </v-toolbar>
                                </template>
                            </draggable>

                        </v-list>



                        <v-tooltip bottom v-if="dayData == selected">
                            <template v-slot:activator="{ on }">
                                <v-btn block class="rounded-0" elevation="0" v-on="on" large
                                    style="text-transform: capitalize" @click="openAssistant">
                                    <v-icon left small>
                                        mdi-robot-happy
                                    </v-icon>
                                    Asistente
                                </v-btn>
                            </template>
                            <span>Generar con Asistente</span>
                        </v-tooltip>

                    </v-card>
                </v-col>

            </draggable>

        </v-row>

        <Builder @confirmWorkout="confirmGeneratedWorkout" ref="asistantBuilder" />



        <v-dialog v-model="dialogReferences" width="500">
            <v-card>
                <v-card-title>
                    Referencias

                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialogReferences = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text v-if="selected" class="pa-4">
                    <v-autocomplete dense v-model="dayData.warmup" :items="listRoutines" multiple chips small-chips
                        label="Seleccionar calentamiento" item-text="name" item-value="id" rounded filled
                        class="rounded-lg" @change="dayData.isRestDay = false">
                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on" @click="dayData.warmup = []" v-show="dayData.warmup">
                                        <v-icon small>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar seleccionado</span>
                            </v-tooltip>
                        </template>

                    </v-autocomplete>

                    <v-autocomplete v-model="dayData.cooldown" :items="listRoutines" label="Seleccionar enfriamiento"
                        multiple chips small-chips item-text="name" item-value="id" rounded filled class="rounded-lg"
                        @change="dayData.isRestDay = false">

                        <template v-slot:append>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon small v-on="on" @click="dayData.cooldown = []"
                                        v-show="dayData.cooldown">
                                        <v-icon small>
                                            mdi-close
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Eliminar seleccionado</span>
                            </v-tooltip>
                        </template>
                    </v-autocomplete>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="hideReferencesDialog"> Volver</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>




    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

import Builder from './Builder.vue';
import RPEVisualizer from '../training/RPEVisualizer.vue';

export default {
    props: {
        exercises: {
            type: Array,
            required: false,
            default: () => [],
        },

        listRoutines: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {
        draggable,
        Builder,
        RPEVisualizer
    },
    data() {
        return {
            dialogReferences: false,
            moving: false,
            drawer: false,
            weeks: [],
            selectedExercise: null,
            newExerciseSets: 1,
            newExerciseType: 'reps',
            newExerciseValue: 10,
            exerciseTypes: [
                { text: 'Repeticiones', value: 'reps' },
                { text: 'Tiempo', value: 'tempo' },
            ],
            exerciseHashIdNames: {},
            copy: null,
            selectedExercises: [],
            dayData: {},
            selected: null,
            draggedItem: null, // Track the currently dragged item
            abecedario: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

        };
    },
    mounted() {
        this.generateWeeks();
    },
    methods: {

        removeWarmup(dayData, index) {
            dayData.warmup.splice(index, 1);
        },
        removeCooldown(dayData, index) {
            dayData.cooldown.splice(index, 1);
        },

        hideReferencesDialog() {
            this.dialogReferences = false;
        },

        onMoveDayExercises(evt) {


            let toDivElements = evt.to
            //check if a children element has class clustered-exercise
            let isClustered = Array.from(toDivElements.children).some((el) => el.classList.contains("clustered-exercise"));

            let draggedItem = evt.draggedContext.element;

            if (isClustered && draggedItem.isCluster) {
                console.warn("Cannot move exercises inside a cluster.");
                return false; // Block exercises from being moved inside clusters
            }










            return true; // Allow other moves
        },

        // Allow only exercises inside cluster's exercises lists
        onMoveClusterExercises(evt) {
            if (this.draggedItem?.isCluster) {
                console.warn("Clusters cannot be nested.");
                return false; // Block clusters from cluster lists
            }

            return true; // Allow exercises to be dropped
        },
        confirmGeneratedWorkout(exerciseList) {
            //this must concatenate the exercises of the selected day with the exercises generated by the assistant
            this.selected.exercises = this.selected.exercises.concat(exerciseList);

            this.selected.isRestDay = false;
        },
        async openAssistant() {
            this.$refs.asistantBuilder.toggleDialog();
        },
        end(evt) {
            this.moving = false;
        },
        start(evt) {
            this.moving = true;
        },
        async toggleRest() {
            if (this.selected.exercises.length > 0) {
                let validate = await this.$confirm("¿Estás seguro de que deseas iniciar un día de descanso? Se eliminarán los ejercicios actuales",
                    {
                        color: "info",
                        title: "Dia de descanso",
                        buttonTrueText: "Confirmar",
                    }
                )
                if (validate) {
                    this.selected.exercises = [];

                    this.selected.cooldown = null;
                    this.selected.warmup = null;
                } else {
                    return;

                }
            }



            this.selected.isRestDay = true;

        },



        generateWeeks() {
            const numberOfWeeks = 4;
            this.weeks = [];

            for (let i = 0; i < numberOfWeeks; i++) {
                const week = { title: null, days: [] };
                for (let j = 0; j < 7; j++) {
                    week.days.push({
                        warmup: [],
                        exercises: [],
                        cooldown: [],
                        notes: [],
                        form: [],
                        isRestDay: false,
                        title: null,
                    });
                }
                this.weeks.push(week);
            }
        },
        selectDay(dayData) {
            this.moving = false;

            if (this.selected === dayData) return;

            this.selected = dayData;
            this.selectedExercise = null;
            this.newExerciseSets = 1;
            this.newExerciseType = 'reps';
            this.newExerciseValue = 10;
            this.selectedExercises = [];

            this.dayData = dayData;
        },
        addExercise(dayData) {
            if (this.selectedExercise) {
                const selectedExerciseData = this.exercises.find(ex => ex.id === this.selectedExercise);

                if (selectedExerciseData) {
                    const newExercise = {
                        id: selectedExerciseData.id,
                        sets: this.newExerciseSets,
                        type: this.newExerciseType,
                        value: this.newExerciseValue,
                    };

                    dayData.exercises.push(newExercise);

                    this.selectedExercise = null;
                    this.newExerciseSets = 1;
                    this.newExerciseType = 'reps';
                    this.newExerciseValue = 10;

                    this.selected.isRestDay = false;
                }

            }


        },
        copyElems(exercises) {
            this.copy = JSON.parse(JSON.stringify(exercises));
        },
        pasteElems(dayData) {
            if (this.copy) {
                this.copy.forEach(exercise => {
                    dayData.exercises.push(exercise);
                });

                this.copy = null;
            }
        },
        removeExercise(dayData, index) {
            dayData.exercises.splice(index, 1);

            this.selectedExercises = this.selectedExercises.filter(i => i !== index);
        },
        removeClusterExercise(exIndex, subExIndex, dayData) {
            dayData.exercises[exIndex].exercises.splice(subExIndex, 1);
        },
        isSelected(index) {
            return this.selectedExercises.includes(index);
        },
        toggleSelect(index) {

            try {
                // If the exercise is a cluster, we don't want to select it
                if (this.selected.exercises[index].isCluster) return;

                if (this.selectedExercises.includes(index)) {
                    this.selectedExercises = this.selectedExercises.filter(i => i !== index);
                } else {
                    this.selectedExercises.push(index);
                }

            } catch (e) {
                console.log(e);
            }


        },
        createClusterFromSelected(dayData) {
            if (this.selectedExercises.length > 0) {
                const selectedExercisesData = this.selectedExercises.map(index => dayData.exercises[index]);
                const newCluster = {
                    isCluster: true,
                    title: null,
                    sets: 1,
                    exercises: selectedExercisesData,
                };

                dayData.exercises.push(newCluster);

                dayData.exercises = dayData.exercises.filter((_, index) => !this.selectedExercises.includes(index));
                this.selectedExercises = [];
            }
        },
        addWeek() {
            const week = { title: null, days: [] };
            for (let j = 0; j < 7; j++) {
                week.days.push({
                    warmup: [],
                    exercises: [],
                    cooldown: [],
                    notes: [],
                    form: [],
                    isRestDay: false,
                });
            }
            this.weeks.push(week);
        },
        async deleteWeek(index) {

            let validate = await this.$confirm("¿Estás seguro de que deseas eliminar esta semana?",
                {
                    color: "error",
                    title: "Eliminar semana",
                    buttonTrueText: "Confirmar",
                }
            )
            if (!validate) return;

            this.weeks.splice(index, 1);
        },
        set(value) {
            this.weeks = value;
        },
        get() {


            return this.weeks;
        },
        showDayReferences(dayData, index) {
            this.selectDay(dayData, index)
            //select the day to show the references

            this.$nextTick(() => {

                this.dialogReferences = true;
            })
        },
        handleRepsInput(input, exercise, clusterSets) {
            if (!input) return;

            // Remove any trailing dash
            if (input.endsWith('-')) {
                exercise.valueInput = input.slice(0, -1);
                return;
            }

            // Use cluster sets if provided (for subexercises), otherwise use exercise sets
            const maxSets = clusterSets || exercise.sets;

            // Handle pyramid sets input (e.g., "12-10-8")
            if (input.includes('-')) {
                const values = input.split('-').map(v => parseInt(v.trim())).filter(v => !isNaN(v));
                // Validate that number of values doesn't exceed number of sets
                if (values.length > maxSets) {
                    // Trim excess values
                    exercise.valueInput = values.slice(0, maxSets).join('-');
                    exercise.value = values.slice(0, maxSets);
                } else {
                    exercise.value = values;
                }
            } else {
                // Handle single value
                const value = parseInt(input);
                exercise.value = isNaN(value) ? 0 : value;
            }
        },
    },
    watch: {
        exercises: {
            handler() {
                this.exerciseHashIdNames = this.exercises.reduce((acc, curr) => {
                    acc[curr.id] = curr.name;
                    return acc;
                }, {});
            },
            immediate: true,
        },
    },
};
</script>

<style scoped>
.calendar-col {
    padding: 0px;
    /*     min-width: 14.2857%;
 */
    height: auto;
    /*     transition: width 0.5s ease;
 */
}

/* selected calendar col and not selected, selected must be 20% with and the other 6 the percentage left */
.calendar-col.selected {
    width: 20% !important;
}

.calendar-col:not(.selected) {
    width: 6% !important;
}

.calendar-day {
    min-height: 200px
}

.selected-item {
    background-color: #e0f7fa;
}

.cluster-container {
    width: 100%;
}

.clustered-exercise {
    margin-bottom: 5px;
    padding-right: 0px;
    padding-left: 8px !important
}

.drag-handle {
    cursor: move;
}

.theme--dark .selected-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.theme--light .selected-item {
    background-color: rgba(0, 0, 0, 0.2);
}

.cluster-container {
    width: 100%;
    border-left: 2px solid var(--v-primary-base);
}

.cluster-container-warm {
    width: 100%;
    border-left: unset
}

.cluster-container-cooldown {
    width: 100%;
    border-left: unset
}


.dragging {
    outline: 1px dashed #42a5f5;
    min-height: 60px;
}

.drag-handle-column {
    cursor: move;
}

.drag-handle-cluster {
    cursor: move !important;
}
</style>
<style>
.v-input.caption {
    margin: 0px !important;
}

.v-input.caption input {
    text-overflow: ellipsis;
    padding: 0px !important;
    max-height: 20px !important;
}


.toolbar-title>.v-toolbar__content {
    padding: 2px !important;
}
</style>