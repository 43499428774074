<template>
    <div>
        <form-runner :formId="form" :formInput="formData" />
        <v-divider class="my-4"></v-divider>
        <form-responses-summary :formId="form" />
    </div>
</template>

<script>
import FormRunner from '@/components/forms/FormRunner.vue';
import FormResponsesSummary from '@/components/forms/FormResponsesSummary.vue';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const db = getFirestore();

export default {
    components: {
        FormRunner,
        FormResponsesSummary
    },
    data() {
        return {
            form: '1AWMalG3tfAMIoGmeYEL',
            formData: null
        }
    },
    created() {
        //this.getFormData();
    },
    methods: {
        async getFormData() {
            const docRef = doc(db, 'forms_inputs', 'kgMdLfzkDt0U8Lroi4mF');
            const docSnap = await getDoc(docRef);

            this.formData = docSnap.data();
        }
    }
}
</script>