import { render, staticRenderFns } from "./QRreader.vue?vue&type=template&id=143722cc&scoped=true"
import script from "./QRreader.vue?vue&type=script&lang=js"
export * from "./QRreader.vue?vue&type=script&lang=js"
import style0 from "./QRreader.vue?vue&type=style&index=0&id=143722cc&prod&scoped=true&lang=css"
import style1 from "./QRreader.vue?vue&type=style&index=1&id=143722cc&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "143722cc",
  null
  
)

export default component.exports