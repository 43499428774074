<template>
    <div>
        <!-- RPE Avatar -->
        <v-chip outlined :color="getRPEColor" small :style="{ color: getRPEColor }" class="cursor-pointer"
            @click.stop="dialog = true">
            <v-icon left x-small>mdi-trending-up</v-icon>
            RPE {{ rpe }}
        </v-chip>

        <!-- RPE Information Dialog -->
        <v-dialog v-model="dialog" max-width="500">
            <v-card class="pa-0">
                <v-toolbar flat>
                    <v-icon left>mdi-ruler</v-icon>
                    Escala de Esfuerzo Percibido

                    <v-spacer></v-spacer>
                    <v-btn icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text class="pa-0">
                    <v-toolbar dense flat v-for="(desc, value) in rpeScale" :key="value" light
                        :class="{ 'grey lighten-4 dark-alternate': value % 2 === 0 }"
                        :style="parseInt(value) === parseInt(rpe) ? { border: '2px solid var(--v-primary-base)' } : {}">
                        <v-avatar size="36" :color="getRPEColorByValue(value)" class="white--text mr-2">
                            {{ value }}
                        </v-avatar>
                        {{ desc }}
                    </v-toolbar>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                        Cerrar
                    </v-btn>
                    <v-spacer></v-spacer>

                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'RPEVisualizer',

    props: {
        rpe: {
            type: Number,
            required: true,
            validator: value => value >= 1 && value <= 10
        }
    },

    data() {
        return {
            dialog: false,
            rpeScale: {
                0: 'Sin esfuerzo',
                1: 'Actividad Muy Ligera - Como caminar casualmente',
                2: 'Actividad Ligera - Cómodo, respiración ligeramente elevada',
                3: 'Actividad Moderada - Aún cómodo, respiración notoria',
                4: 'Entre Moderado y Algo Duro',
                5: 'Algo Duro - Respiración pesada, puede mantener conversación',
                6: 'Entre Algo Duro y Duro',
                7: 'Actividad Dura - Casi al límite, solo frases cortas',
                8: 'Muy Duro - Apenas puede hablar, cerca del máximo',
                9: 'Extremadamente Duro - No puede hablar, cerca del esfuerzo máximo',
                10: 'Esfuerzo Máximo - Límite absoluto, no se puede mantener'
            }
        }
    },

    computed: {
        getRPEColor() {
            return this.getRPEColorByValue(this.rpe)
        }
    },

    methods: {
        getRPEColorByValue(value) {
            const colorScale = {
                0: '#757575', // Gris (for completeness, though not used in 1-10 scale)
                1: '#4CAF50', // Verde claro
                2: '#66BB6A', // Verde
                3: '#81C784', // Verde más intenso
                4: '#FFB74D', // Naranja claro
                5: '#FFA726', // Naranja
                6: '#FF7043', // Naranja rojizo
                7: '#F44336', // Rojo claro
                8: '#E53935', // Rojo
                9: '#D32F2F', // Rojo oscuro
                10: '#C62828' // Rojo más oscuro
            }
            return colorScale[value] || '#757575'
        }
    }
}
</script>

<style scoped>
.cursor-pointer {
    cursor: pointer;
}
</style>