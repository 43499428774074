<template>
    <v-container>
        <v-card class="rounded-lg overflow-hidden" elevation="1">
            <v-card-title>
                <v-icon left color="primary">mdi-calendar-month</v-icon>
                Evaluaciones

                <v-spacer></v-spacer>

                <v-btn class="rounded-lg" color="primary" @click="dialogs.newEvaluation = true">
                    <v-icon left>mdi-plus</v-icon>
                    Nueva evaluación
                </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
                <v-row>
                    <!-- Calendar Column -->
                    <v-col cols="12" md="12">
                        <v-toolbar flat class="pl-2">
                            <!--   <template v-if="$vuetify.breakpoint.mdAndUp">
                                <v-toolbar-title>Calendario</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </template> -->
                            <v-btn @click="prev" class="mr-4" fab small elevation="0">
                                <v-icon>
                                    mdi-chevron-left
                                </v-icon>
                            </v-btn>

                            <span style="text-transform: uppercase">{{ actualMonth }}</span>

                            <v-btn @click="next" class="mx-4" fab small elevation="0">
                                <v-icon>
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                            <v-spacer></v-spacer>

                            <v-select v-model="calendarType" :items="calendarTypes" label="Vista" dense hide-details=""
                                @change="scrollToTime" filled rounded style="max-width: 150px;" single-line
                                class="rounded-lg"></v-select>

                        </v-toolbar>

                        <v-calendar ref="calendar" v-model="currentMonth" :events="evaluations" :type="calendarType"
                            color="primary" @click:date="openAddDialog" @click:event="handleEventClick"
                            @click:more="handleMoreClick">
                            <template v-slot:event="{ event }">
                                <div class="d-flex align-center">
                                    <v-icon small left dark class="mx-1">
                                        mdi-clock-outline
                                    </v-icon>
                                    {{ event.start.split(' ')[1] + ' ' }}

                                    <span class="text-caption ml-1">
                                        {{ users.find(u => u.id === event.id)?.displayName || 'Usuario' }}
                                        <template v-if="event.type === 'anthropometric'">
                                            <span class="ml-1 grey--text text--lighten-1">
                                                ISAK-{{ event.isakLevel }}
                                            </span>
                                        </template>
                                    </span>
                                </div>
                            </template>

                            <template v-slot:day-body="{ date, week }">
                                <div class="v-current-time" :class="{ first: date === week[0].date }"
                                    :style="{ top: nowY }"></div>
                            </template>
                        </v-calendar>



                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>



        <v-dialog v-model="dialogs.newEvaluation" max-width="600">
            <v-card flat class="rounded-0 h-100">
                <v-card-title flat dense class="border-bottom">
                    <v-icon left>mdi-calendar</v-icon>
                    {{ editMode ? 'Editar Evaluación' : 'Nueva Evaluación' }}
                    <v-spacer></v-spacer>
                    <v-btn icon small @click="dialogs.newEvaluation = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <v-card-text class="pa-4">
                    <v-form ref="evaluationDateForm" v-model="formIsValid">
                        <div class="d-flex mb-6">
                            <v-btn-toggle dense v-model="evaluationDateObj.type" mandatory class="d-flex flex-grow-1">
                                <v-btn text class="flex-grow-1" :value="'form'">
                                    <v-icon left>mdi-file-document-outline</v-icon>
                                    Formulario
                                </v-btn>
                                <v-btn text class="flex-grow-1" :value="'anthropometric'">
                                    <v-icon left>mdi-human-male-height</v-icon>
                                    Antropométrica
                                </v-btn>
                            </v-btn-toggle>
                        </div>

                        <v-row dense>
                            <v-col cols="4">
                                <v-menu v-model="dateMenu" :close-on-content-click="false" transition="scale-transition"
                                    offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="evaluationDateObj.date" label="Fecha" readonly
                                            v-bind="attrs" v-on="on" :rules="rules.required" prepend-icon="mdi-calendar"
                                            dense outlined></v-text-field>
                                    </template>
                                    <v-date-picker v-model="evaluationDateObj.date" no-title
                                        @input="dateMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="4">
                                <v-menu ref="timeMenu" v-model="timeMenu" :close-on-content-click="false"
                                    :return-value.sync="evaluationDateObj.time" transition="scale-transition" offset-y
                                    min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="evaluationDateObj.time" label="Hora" readonly
                                            v-bind="attrs" v-on="on" :rules="rules.required"
                                            prepend-icon="mdi-clock-outline" dense outlined></v-text-field>
                                    </template>
                                    <v-time-picker v-if="timeMenu" v-model="evaluationDateObj.time" full-width
                                        format="24hr"
                                        @click:minute="$refs.timeMenu.save(evaluationDateObj.time)"></v-time-picker>
                                </v-menu>
                            </v-col>

                            <v-col cols="4">
                                <v-text-field v-model="evaluationDateObj.duration" label="Duración (minutos)"
                                    type="number" min="15" max="240" :rules="rules.required"
                                    prepend-icon="mdi-clock-outline" dense outlined></v-text-field>
                            </v-col>
                        </v-row>

                        <v-autocomplete v-model="evaluationDateObj.id" :items="users" item-text="displayName"
                            item-value="id" label="Usuario" :rules="rules.required" :loading="loadingUsers"
                            prepend-icon="mdi-account" dense outlined class="mt-4"></v-autocomplete>

                        <template v-if="evaluationDateObj.type === 'form'">
                            <v-autocomplete v-model="evaluationDateObj.formId" :items="forms" item-text="title"
                                item-value="id" label="Formulario" :rules="rules.required" :loading="loadingForms"
                                prepend-icon="mdi-file-document" dense outlined></v-autocomplete>
                        </template>

                        <template v-else>
                            <v-select v-model="evaluationDateObj.isakLevel" :items="isakLevels" label="Nivel ISAK"
                                :rules="rules.required" prepend-icon="mdi-ruler" dense outlined></v-select>
                        </template>

                        <v-autocomplete v-model="evaluationDateObj.evaluatorId" :items="evaluators"
                            item-text="displayName" item-value="id" label="Evaluador" :rules="rules.required"
                            :loading="loadingEvaluators" prepend-icon="mdi-account-tie" dense outlined></v-autocomplete>

                        <!--  <div v-if="editMode && evaluationDateObj.evaluatedBy" class="mt-6 pa-4 grey lighten-4 rounded">
                            <div class="subtitle-2 mb-2">Información de evaluación</div>
                            <v-text-field v-model="evaluationDateObj.evaluatedBy" label="Evaluado por" readonly dense
                                outlined class="mb-2"></v-text-field>
                            <v-text-field v-model="evaluationDateObj.evaluatedDate" label="Fecha de evaluación" readonly
                                dense outlined></v-text-field>
                        </div> -->
                    </v-form>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn v-if="editMode" color="error" text @click="confirmDelete" :loading="loading">
                        <v-icon left>mdi-delete</v-icon>
                        Eliminar
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="saveEvaluationDate" :loading="loading">
                        <v-icon left>{{ editMode ? 'mdi-content-save' : 'mdi-plus' }}</v-icon>
                        {{ editMode ? 'Actualizar evaluación' : 'Agendar evaluación' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>



        <!-- Confirmation Dialog -->
        <v-dialog v-model="dialogs.confirm" max-width="400">
            <v-card>
                <v-card-title>Confirmar eliminación</v-card-title>
                <v-card-text>
                    ¿Está seguro que desea eliminar esta evaluación?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="grey darken-1" text @click="dialogs.confirm = false">Cancelar</v-btn>
                    <v-btn color="error" text @click="deleteEvaluation(evaluationDateObj.doc_id)">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-menu v-model="eventMenu.show" :position-x="eventMenu.x" :position-y="eventMenu.y" absolute offset-y>
            <v-card min-width="300" class="event-menu">
                <v-list dense>
                    <v-list-item>
                        <v-list-item-avatar color="primary lighten-4">
                            <v-icon :color="'primary'">
                                {{ selectedEvent?.type === 'form' ? 'mdi-file-document-outline' :
                                    'mdi-human-male-height' }}
                            </v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium">
                                {{ users.find(u => u.id === selectedEvent?.id)?.displayName || 'Usuario' }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ formatDateTime(selectedEvent?.date) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider></v-divider>

                    <template v-if="selectedEvent?.evaluatedBy">
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle class="d-flex align-center">
                                    <v-icon small left color="success">mdi-check-circle</v-icon>
                                    Completada: {{ formatDateTime(selectedEvent?.evaluatedDate) }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider></v-divider>
                    </template>

                    <v-list-item v-if="selectedEvent?.type === 'form'">
                        <v-list-item-content>
                            <v-list-item-subtitle>
                                <v-icon small left>mdi-file-document</v-icon>
                                Formulario: {{ forms.find(f => f.id === selectedEvent?.formId)?.title }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>

                <v-card-actions class="justify-end pa-2">
                    <v-btn small text color="primary" @click="editEvent">
                        <v-icon left small>mdi-pencil</v-icon>
                        Editar
                    </v-btn>
                    <template v-if="selectedEvent?.evaluatedBy">
                        <v-btn small text color="info" @click="viewCompletedForm">
                            <v-icon left small>mdi-eye</v-icon>
                            Ver
                        </v-btn>
                    </template>
                    <v-btn v-else small text color="success" @click="startEvaluation">
                        <v-icon left small>mdi-play</v-icon>
                        Iniciar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>

        <!-- Evaluation Dialog -->
        <v-dialog v-model="dialogs.evaluation" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card class="pa-0">
                <v-toolbar dark color="primary" v-if="selectedEvent?.type === 'form' && dialogs.evaluation">
                    <v-btn icon dark @click="closeEvaluationDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        {{ selectedEvent?.type === 'form' ? 'Formulario de Evaluación' : 'Evaluación Antropométrica' }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>

                <v-card-text class="pa-0">
                    <template v-if="selectedEvent?.type === 'form' && dialogs.evaluation">
                        <form-runner ref="formRunner" :formId="selectedEvent?.formId"
                            :evaluationId="selectedEvent?.doc_id"
                            :formInput="selectedEvent?.evaluatedBy ? formInput : null"
                            @submitted="handleFormSubmitted" />
                    </template>
                    <template v-else-if="dialogs.evaluation">
                        <isak-anthropometric-form :evaluationId="selectedEvent?.doc_id" :userId="selectedEvent?.id"
                            :isakLevel="selectedEvent?.isakLevel || 1"
                            :formInput="selectedEvent?.evaluatedBy ? anthropometryInput : null" ref="anthropometricForm"
                            @update:formInput="anthropometryInput = $event" @submitted="handleAnthropometrySubmitted"
                            @close="closeEvaluationDialog" />
                    </template>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { getFirestore, doc, collection, addDoc, updateDoc, deleteDoc, getDocs, query, where, getDoc } from "firebase/firestore";
import moment from "moment";
import FormRunner from '@/components/forms/FormRunner.vue';
import IsakAnthropometricForm from '@/components/forms/IsakAnthropometricForm.vue';

export default {
    components: {
        FormRunner,
        IsakAnthropometricForm
    },
    data() {
        return {
            ready: false,
            nowY: null,
            interval: null,
            calendarType: "month",
            calendarTypes: [
                { text: "Mensual", value: "month" },
                { text: "Semanal", value: "week" },
                { text: "Diario", value: "day" },
            ],
            currentMonth: moment().format("YYYY-MM-DD"),
            evaluations: [],
            dialogs: {
                confirm: false,
                newEvaluation: false,
                evaluation: false,
            },
            evaluationDateObj: {
                date: null,
                time: null,
                id: null,
                type: 'form',
                formId: null,
                evaluatorId: null,
                evaluatedBy: null,
                evaluatedDate: null,
                doc_id: null,
                duration: 60,
                isakLevel: 1,
            },
            editMode: false,
            loading: false,
            formIsValid: false,
            rules: {
                required: [(v) => !!v || "This field is required"],
            },
            dateMenu: false,
            timeMenu: false,
            users: [],
            forms: [],
            evaluators: [],
            loadingUsers: false,
            loadingForms: false,
            loadingEvaluators: false,
            eventMenu: {
                show: false,
                x: 0,
                y: 0
            },
            selectedEvent: null,
            formInput: null,
            anthropometryInput: null,
            isakLevels: [
                { text: 'Nivel 1', value: 1 },
                { text: 'Nivel 2', value: 2 },
            ],
        };
    },
    methods: {
        closeEvaluationDialog() {
            this.dialogs.evaluation = false;
            if (this.selectedEvent?.type === 'form') {
                this.$refs.formRunner.resetForm();
            } else if (this.selectedEvent?.type === 'anthropometric') {
                this.$refs.anthropometricForm.resetForm();
            }
            this.formInput = null;
        },
        async loadEvaluations() {
            const db = getFirestore();
            const evaluationsSnapshot = await getDocs(collection(db, "evaluations"));
            evaluationsSnapshot.forEach((doc) => {
                let evaluation = doc.data();
                evaluation.doc_id = doc.id;

                // Convert Firestore timestamp to JS Date
                let evaluationDate = new Date(evaluation.date.seconds * 1000);
                let evaluatedDate = evaluation.evaluatedBy ? new Date(evaluation.evaluatedDate.seconds * 1000) : null;

                this.evaluations.push({
                    name: evaluation.id,
                    start: moment(evaluationDate).format("YYYY-MM-DD HH:mm"),
                    // end is required, but we don't have an end time
                    end: moment(evaluationDate).add(1, "hour").format("YYYY-MM-DD HH:mm"),
                    color: evaluation.evaluatedBy ? "green" : "red",
                    ...evaluation,
                    date: evaluationDate,
                    evaluatedDate: evaluatedDate,
                });
            });
        },
        openAddDialog(date) {
            // Convert the date to a moment object and format it
            const selectedDate = moment(date.date).format('YYYY-MM-DD');
            this.evaluationDateObj.date = selectedDate;
            this.dialogs.newEvaluation = true;
            this.editMode = false;
        },
        closeDialog() {
            this.dialogs.evaluationDate = false;
            this.evaluationDateObj = {
                date: null,
                time: null,
                id: null,
                evaluatedBy: null,
                evaluatedDate: null,
                doc_id: null,
            };
        },
        async saveEvaluationDate() {
            try {
                if (!this.$refs.evaluationDateForm.validate()) return;

                let evaluationDateObject = {
                    date: moment(
                        `${this.evaluationDateObj.date} ${this.evaluationDateObj.time}:00`,
                        "YYYY-MM-DD HH:mm:ss"
                    ).toDate(),
                    id: this.evaluationDateObj.id,
                    type: this.evaluationDateObj.type,
                    formId: this.evaluationDateObj.type === 'form' ? this.evaluationDateObj.formId : null,
                    isakLevel: this.evaluationDateObj.type === 'anthropometric' ? this.evaluationDateObj.isakLevel : null,
                    evaluatorId: this.evaluationDateObj.evaluatorId,
                    evaluatedBy: this.evaluationDateObj.evaluatedBy,
                    evaluatedDate: this.evaluationDateObj.evaluatedBy
                        ? moment(this.evaluationDateObj.evaluatedDate, "YYYY-MM-DD HH:mm:ss").toDate()
                        : null,
                    duration: parseInt(this.evaluationDateObj.duration) || 60,
                };

                this.loading = true;
                const db = getFirestore();

                if (this.editMode && this.evaluationDateObj.doc_id) {
                    await updateDoc(
                        doc(db, "evaluations", this.evaluationDateObj.doc_id),
                        evaluationDateObject
                    );
                    this.updateLocalEvaluations(evaluationDateObject);
                } else {
                    let result = await addDoc(
                        collection(db, "evaluations"),
                        evaluationDateObject
                    );
                    evaluationDateObject.doc_id = result.id;
                    this.evaluations.push(this.createEventObject(evaluationDateObject));
                }

                this.resetForm();
                this.dialogs.newEvaluation = false;
            } catch (error) {
                console.error("Error saving evaluation:", error);
            } finally {
                this.loading = false;
            }
        },
        handleEventClick(event) {
            // Get mouse position from the event
            this.eventMenu.x = event.nativeEvent.clientX;
            this.eventMenu.y = event.nativeEvent.clientY;
            this.selectedEvent = event.event;
            this.eventMenu.show = true;
        },
        async deleteEvaluation(evaluationId) {
            try {
                this.loading = true;
                const db = getFirestore();
                await deleteDoc(doc(db, "evaluations", evaluationId));
                this.evaluations = this.evaluations.filter((ev) => ev.doc_id !== evaluationId);
                this.dialogs.confirm = false;
                this.dialogs.newEvaluation = false;
                this.resetForm();
            } catch (error) {
                console.error("Error deleting evaluation: ", error);
            } finally {
                this.loading = false;
            }
        },
        updateLocalEvaluations(evaluationDateObject) {
            const index = this.evaluations.findIndex(
                (item) => item.doc_id === this.evaluationDateObj.doc_id
            );

            if (index !== -1) {
                this.evaluations.splice(index, 1, this.createEventObject(evaluationDateObject));
            }
        },
        prev() {
            let mode = this.calendarType;
            let newDate = moment(this.currentMonth).subtract(1, mode).format("YYYY-MM-DD");
            this.currentMonth = newDate;


        },
        next() {
            let mode = this.calendarType;
            let newDate = moment(this.currentMonth).add(1, mode).format("YYYY-MM-DD");
            this.currentMonth = newDate;


        },
        async loadUsers() {
            this.loadingUsers = true;
            try {
                const db = getFirestore();
                const usersSnapshot = await getDocs(collection(db, "users"));
                this.users = usersSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));
            } catch (error) {
                console.error("Error loading users:", error);
            } finally {
                this.loadingUsers = false;
            }
        },

        async loadForms() {
            this.loadingForms = true;
            try {
                const db = getFirestore();
                const formsSnapshot = await getDocs(collection(db, "forms"));
                this.forms = formsSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id
                }));
            } catch (error) {
                console.error("Error loading forms:", error);
            } finally {
                this.loadingForms = false;
            }
        },

        async loadEvaluators() {
            this.loadingEvaluators = true;
            try {
                const db = getFirestore();
                const evaluatorsQuery = query(
                    collection(db, "users"),
                    where("type", "in", ["admin", "evaluator"])
                );
                const evaluatorsSnapshot = await getDocs(evaluatorsQuery);
                this.evaluators = evaluatorsSnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
            } catch (error) {
                console.error("Error loading evaluators:", error);
            } finally {
                this.loadingEvaluators = false;
            }
        },

        confirmDelete() {
            this.dialogs.confirm = true;
        },

        createEventObject(evaluation) {
            return {
                name: this.users.find(u => u.id === evaluation.id)?.displayName || 'Usuario',
                start: moment(evaluation.date).format("YYYY-MM-DD HH:mm"),
                end: moment(evaluation.date).add(evaluation.duration || 60, "minutes").format("YYYY-MM-DD HH:mm"),
                color: evaluation.evaluatedBy ? "green" : "red",
                tint: evaluation.type === 'anthropometric' ? 0.6 : 1,
                time: moment(evaluation.date).format("HH:mm"),
                ...evaluation,
            };
        },

        resetForm() {
            this.editMode = false;
            this.evaluationDateObj = {
                date: null,
                time: null,
                id: null,
                type: 'form',
                formId: null,
                evaluatorId: null,
                evaluatedBy: null,
                evaluatedDate: null,
                doc_id: null,
                duration: 60,
                isakLevel: 1,
            };
        },

        handleMoreClick({ date }) {
            this.calendarType = 'day';
            this.currentMonth = date;
        },

        editEvent() {
            this.evaluationDateObj = {
                date: moment(this.selectedEvent.date).format("YYYY-MM-DD"),
                time: moment(this.selectedEvent.date).format("HH:mm"),
                id: this.selectedEvent.id,
                type: this.selectedEvent.type || 'form',
                formId: this.selectedEvent.formId,
                isakLevel: this.selectedEvent.isakLevel || 1,
                evaluatorId: this.selectedEvent.evaluatorId,
                evaluatedBy: this.selectedEvent.evaluatedBy,
                evaluatedDate: this.selectedEvent.evaluatedDate ?
                    moment(this.selectedEvent.evaluatedDate).format("YYYY-MM-DD HH:mm") : null,
                doc_id: this.selectedEvent.doc_id,
                duration: this.selectedEvent.duration || 60,
            };
            this.eventMenu.show = false;
            this.dialogs.newEvaluation = true;
            this.editMode = true;
        },

        startEvaluation() {
            this.eventMenu.show = false;
            this.dialogs.evaluation = true;
        },

        formatDateTime(date) {
            if (!date) return '';
            return moment(date).format('DD/MM/YYYY HH:mm');
        },

        async handleFormSubmitted({ formInputId, evaluatorId, date }) {
            try {
                const db = getFirestore();

                // Update the evaluation in Firestore
                await updateDoc(doc(db, "evaluations", this.selectedEvent.doc_id), {
                    evaluatedBy: evaluatorId,
                    evaluatedDate: date,
                    formInputId: formInputId
                });

                // Update local state
                const index = this.evaluations.findIndex(e => e.doc_id === this.selectedEvent.doc_id);
                if (index !== -1) {
                    const updatedEvaluation = {
                        ...this.evaluations[index],
                        evaluatedBy: evaluatorId,
                        evaluatedDate: date,
                        formInputId: formInputId,
                        color: 'green' // Update the color to show it's completed
                    };
                    this.$set(this.evaluations, index, updatedEvaluation);
                }

                // Close the dialog
                this.dialogs.evaluation = false;

                // Show success message
                this.$notify({
                    title: 'Evaluación completada',
                    message: 'Evaluación completada exitosamente',
                    type: 'success'
                });
            } catch (error) {
                this.$notify({
                    title: 'Error',
                    message: 'Error al actualizar el estado de la evaluación',
                    type: 'error'
                });
            }
        },

        async getFormInputData(formInputId) {
            try {
                const db = getFirestore();
                const docRef = doc(db, 'forms_inputs', formInputId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.formInput = docSnap.data();
                }
            } catch (error) {
                console.error('Error fetching form input:', error);
            }
        },

        async viewCompletedForm() {
            if (this.selectedEvent?.formInputId) {
                await this.getFormInputData(this.selectedEvent.formInputId);
            } else if (this.selectedEvent?.type === 'anthropometric' && this.selectedEvent?.anthropometryId) {
                await this.getAnthropometryData(this.selectedEvent.anthropometryId);
            }
            this.eventMenu.show = false;
            this.dialogs.evaluation = true;
        },

        async handleAnthropometrySubmitted({ anthropometryId, evaluatorId, date }) {
            try {
                const db = getFirestore();

                // Update the evaluation in Firestore
                await updateDoc(doc(db, "evaluations", this.selectedEvent.doc_id), {
                    evaluatedBy: evaluatorId,
                    evaluatedDate: date,
                    anthropometryId: anthropometryId
                });

                // Update local state
                const index = this.evaluations.findIndex(e => e.doc_id === this.selectedEvent.doc_id);
                if (index !== -1) {
                    const updatedEvaluation = {
                        ...this.evaluations[index],
                        evaluatedBy: evaluatorId,
                        evaluatedDate: date,
                        anthropometryId: anthropometryId,
                        color: 'green' // Update the color to show it's completed
                    };
                    this.$set(this.evaluations, index, updatedEvaluation);
                }

                // Close the dialog
                this.dialogs.evaluation = false;

                // Show success message
                this.$notify({
                    title: 'Evaluación completada',
                    message: 'Evaluación completada exitosamente',
                    type: 'success'
                });
            } catch (error) {
                this.$notify({
                    title: 'Error',
                    message: 'Error al actualizar el estado de la evaluación',
                    type: 'error'
                });
            }
        },

        async getAnthropometryData(anthropometryId) {
            try {
                const db = getFirestore();
                const docRef = doc(db, 'anthropometry', anthropometryId);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    this.anthropometryInput = {
                        ...docSnap.data(),
                        id: docSnap.id // Include the document ID
                    };
                }
            } catch (error) {
                console.error('Error fetching anthropometry data:', error);
            }
        },
        getCurrentTime() {
            return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
        },
        scrollToTime() {
            this.$nextTick(() => {


                if (this.calendarType != 'month') {
                    const now = moment()
                    this.cal.scrollToTime({ hour: now.hour(), minute: now.minute() })
                    this.nowY = this.cal.timeToY({ hour: now.hour(), minute: now.minute() }) + 'px'
                    this.updateTime();

                } else {
                    clearInterval(this.interval)
                }

            })
        },

        updateTime() {
            this.interval = setInterval(() => {
                const now = moment()
                this.nowY = this.cal.timeToY({ hour: now.hour(), minute: now.minute() }) + 'px'
            }, 60 * 1000)
        },
    },
    mounted() {
        this.ready = true;
        this.loadEvaluations();
        this.loadUsers();
        this.loadForms();
        this.loadEvaluators();
    },
    computed: {
        cal() {
            return this.ready ? this.$refs.calendar : null
        },
        actualMonth() {
            return moment(this.currentMonth).locale('es').format("MMMM YYYY");
        },
        formRules() {
            return {
                ...this.rules,
                formRequired: [
                    v => {
                        if (this.evaluationDateObj.type === 'form') {
                            return !!v || "El formulario es requerido para evaluaciones de tipo formulario"
                        }
                        return true
                    }
                ]
            }
        }
    },
};
</script>

<style scoped>
.v-calendar {
    height: 600px;
}

/* Add these new styles */
.theme--dark :deep(.v-calendar-weekly__head-weekday) {
    border-right: 1px solid #e0e0e02a !important;
    border-bottom: 1px solid #e0e0e02a !important;
}

.theme--dark :deep(.v-calendar-weekly__day) {
    border-right: 1px solid #e0e0e02a !important;
    border-bottom: 1px solid #e0e0e02a !important;
}

.theme--dark :deep(.v-calendar-weekly) {
    border: 1px solid #e0e0e02a !important;
    border-bottom: none !important;
}

.event-menu {
    border-radius: 8px;
    overflow: hidden;
}



.theme--dark :deep(.v-calendar-daily__intervals-head) {
    border-right: #e0e0e02a 1px solid !important;
}

.theme--dark :deep(.v-calendar-daily_head-day) {
    border-right: #e0e0e02a 1px solid !important;
    border-bottom: #e0e0e02a 1px solid !important;
    color: #FFFFFF;
}

.theme--dark :deep(.v-calendar-daily__day-interval) {
    border-top: #e0e0e02a 1px solid !important;
}

.theme--dark :deep(.v-calendar-daily__day) {
    border-right: #e0e0e02a 1px solid !important;
    border-bottom: #e0e0e02a 1px solid !important;
}


.theme--dark :deep(.v-calendar-daily) {
    border-left: #e0e0e02a 1px solid !important;
    border-top: #e0e0e02a 1px solid !important;
}
</style>

<style lang="scss">
.v-calendar .v-event-timed {
    overflow: hidden;
}


.v-current-time {
    height: 2px;
    background-color: #ea4335;
    position: absolute;
    left: -1px;
    right: 0;
    pointer-events: none;

    &.first::before {
        content: '';
        position: absolute;
        background-color: #ea4335;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-top: -5px;
        margin-left: -6.5px;
    }
}
</style>
