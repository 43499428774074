<template>
  <v-container id="activity">
    <v-row :no-gutters="$vuetify.breakpoint.smAndDown">

      <v-col cols="12" md="8">
        <!-- Stats Cards Row -->
        <v-row class="mb-4">
          <v-col cols="12" md="6">
            <v-card :loading="loading" :disabled="loading" class="rounded-lg elevation-3 stats-card">
              <v-card-text class="text-center py-6">
                <div class="text-h3 font-weight-bold primary--text mb-2">
                  {{ workouts.length }}
                </div>
                <div class="d-flex align-center justify-center">
                  <v-icon left color="primary" size="24">mdi-weight-lifter</v-icon>
                  <div class="text-subtitle-1 grey--text text--darken-1">
                    Total de Entrenamientos
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card :loading="loading" :disabled="loading" class="rounded-lg elevation-3 stats-card">
              <v-card-text class="text-center py-6">
                <div class="text-h3 font-weight-bold amber--text text--darken-2 mb-2">
                  {{ averageRating.toFixed(1) }}
                </div>
                <div class="d-flex align-center justify-center">
                  <v-icon left color="amber darken-2" size="24">mdi-star</v-icon>
                  <div class="text-subtitle-1 grey--text text--darken-1">
                    Calificación Promedio
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <!-- Activity Card -->
        <v-card class="rounded-lg elevation-3 mb-4">
          <v-toolbar color="transparent" flat class="pl-4">
            <v-toolbar-title class="text-h6 font-weight-bold">Actividad</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" text class="text-capitalize" v-on="on" elevation="0">
                  <v-icon left>mdi-calendar</v-icon>
                  {{ selectedPeriod }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in periodOptions" :key="index" @click="selectedPeriod = item">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider v-if="selectedPeriod === 'Periodo personalizado'" />
          <v-toolbar flat v-if="selectedPeriod === 'Periodo personalizado'">
            <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
              max-width="290px" min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field v-model="dateRangeText" placeholder="Selecciona un rango de fechas"
                  prepend-inner-icon="mdi-calendar" readonly dense hide-details filled rounded single-line
                  class="rounded-lg" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="dateRange" range @change="onDateRangeChange" locale="es"></v-date-picker>
            </v-menu>
          </v-toolbar>
          <v-divider />
          <v-card-text class="activityCard pa-0"
            v-bind:class="{ 'small': $vuetify.breakpoint.smAndDown, 'pa-4': !loading && workouts.length == 0 }">
            <v-fade-transition hide-on-leave>
              <!-- Loading Skeleton -->
              <v-skeleton-loader v-if="loading"
                type="list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line,list-item-avatar-two-line"
                :loading="loading"></v-skeleton-loader>
            </v-fade-transition>

            <v-fade-transition hide-on-leave>
              <!-- Activity List -->
              <v-expansion-panels v-if="!loading && workouts.length" class="elevation-0">
                <v-expansion-panel v-for="(workout, index) in workouts" :key="index" class="elevation-0 workout-panel">
                  <v-expansion-panel-header class="px-4 py-3">
                    <v-row no-gutters align="center">
                      <v-col cols="auto">
                        <v-avatar size="40">
                          <UserImage :user="{ id: workout.user_id }" small />
                        </v-avatar>
                      </v-col>
                      <v-col class="ml-3">
                        <div class="font-weight-medium">{{ workout.user_name }}</div>
                        <div class="caption grey--text">{{ formatDate(workout.date) }}

                          <template v-if="workout.late">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon color="amber" small class="mr-1" v-bind="attrs" v-on="on">
                                  mdi-clock-outline
                                </v-icon>
                              </template>
                              <span>Completado {{ getDaysLate(workout.late, workout.date) }} {{
                                getDaysLate(workout.late,
                                  workout.date) === 1 ? 'día' : 'días' }} tarde</span>
                            </v-tooltip>
                          </template>

                        </div>
                        <div v-if="$vuetify.breakpoint.smAndDown">
                          <v-chip small label color="primary" class="mr-3">
                            {{ workout.plan_name }} | Semana {{ workout.week + 1 }} | Día {{ workout.workoutDays + 1 }}
                          </v-chip>
                        </div>

                      </v-col>
                      <v-col cols="auto" class="ml-auto d-flex align-center">
                        <v-chip small label color="primary" class="mr-3" v-if="!$vuetify.breakpoint.smAndDown">
                          {{ workout.plan_name }} | Semana {{ workout.week + 1 }} | Día {{ workout.workoutDays + 1 }}
                        </v-chip>
                        <div class="d-flex align-center" v-if="!$vuetify.breakpoint.smAndDown">
                          <v-icon color="amber" small class="mr-1">
                            {{ workout.rating >= 5 ? 'mdi-star' : 'mdi-star-half-full' }}
                          </v-icon>
                          <span class="caption">{{ workout.rating }}</span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="elevation-0 px-0">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="12" v-if="workout.day_exercises">
                          <!-- <p v-show="$vuetify.breakpoint.smAndDown">
                            <strong>Calificación: </strong>
                            <v-icon color="yellow">
                              {{ workout.rating >= 5 ? 'mdi-star' : 'mdi-star-half-full' }}
                            </v-icon>
                            {{ workout.rating }}
                          </p> -->


                          <v-card class="glass">
                            <v-card-title class="text-h6" v-if="$vuetify.breakpoint.smAndDown">
                              <v-icon left>mdi-weight-lifter</v-icon>
                              Programación

                              <v-spacer />

                              <template>
                                <v-icon color="yellow">
                                  {{ workout.rating >= 5 ? 'mdi-star' : 'mdi-star-half-full' }}
                                </v-icon>
                                {{ workout.rating }}
                              </template>

                            </v-card-title>
                            <v-divider v-if="$vuetify.breakpoint.smAndDown" />
                            <v-card-text>
                              <Simple :exercises="workout.day_exercises" />
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="workout.comment">
                          <p>
                            <strong>Comentario: </strong>
                            <v-alert text icon="mdi-comment">{{ workout.comment }}</v-alert>
                          </p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-fade-transition>

            <v-scroll-x-transition hide-on-leave>
              <v-alert v-if="!loading && workouts.length == 0" type="info" text class="ma-4 mb-4">
                No hay actividad para mostrar.
              </v-alert>
            </v-scroll-x-transition> </v-card-text>


        </v-card>

      </v-col>



      <v-col cols="12" md="4">
        <v-card :disabled="loading" :loading="loading" class="mb-4 elevation-3 rounded-lg">
          <v-card-text class="text-center">
            <div class="text-h3 font-weight-bold">
              {{ users.length }}
            </div>
            <div class="text-subtitle-1 grey--text">
              Total de Usuarios
            </div>
          </v-card-text>
          <v-card-text class="d-flex justify-space-around">
            <div class="text-center">
              <div class="text-h4 success--text">{{ users.length - inactiveUsers.length }}</div>
              <div class="caption">Activos</div>
            </div>
            <v-divider vertical></v-divider>
            <div class="text-center">
              <div class="text-h4 error--text">{{ inactiveUsers.length }}</div>
              <div class="caption">Inactivos</div>
            </div>
          </v-card-text>
          <!--   <v-divider/>
            <v-card-text class="">
               <apexchart class="rounded overflow-hidden"
                  type="pie"
                  height="250"
                  :options="activeInactiveChartOptions"
                  :series="activeInactiveChartSeries"
                ></apexchart>
            </v-card-text> -->
        </v-card>
        <!-- Inactive Users Table -->
        <v-card class="mt-4 elevation-3 rounded-lg" v-show="inactiveUsers.length > 0">
          <v-toolbar flat color="transparent">
            <v-toolbar-title class="text-h6">Inactivos</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-data-table :headers="inactiveUsersHeaders" :items="inactiveUsers" class="elevation-0" hide-default-footer
              hide-default-header :loading="loading">
              <template v-slot:item.name="{ item }">
                <v-avatar size="35" class="mr-2">
                  <UserImage :user="{ id: item.id }" small />
                </v-avatar>

                {{
                  item.name
                }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from 'moment';
import {
  getFirestore,
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  getDocs,
} from 'firebase/firestore';
import UserImage from '@/components/profile/UserImage.vue';
import Simple from '@/components/planifications/view/Simple.vue';
import Pending from '@/components/tasks/Pending.vue';
import VueApexCharts from 'vue-apexcharts';

export default {
  components: {
    UserImage,
    Simple,
    Pending,
    apexchart: VueApexCharts,
  },
  name: 'Actividad',
  data() {
    return {
      averageRating: 0,
      loading: true,
      selectedPeriod: 'Ultima semana',
      periodOptions: ['Ultima semana', 'Ultimo mes', 'Periodo personalizado'],
      menu: false,
      dateRange: [],
      dateRangeText: '',
      workouts: [],
      users: [],
      inactiveUsers: [],
      inactiveUsersHeaders: [
        { text: 'Nombre', value: 'name' },
      ],
      unsubscribes: [],
      workoutsMap: {},
      // Chart data and options
      activeInactiveChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'pie',
          toolbar: {
            show: false
          }
        },
        labels: ['Activos', 'Inactivos'],
        xaxis: {
          categories: ['Usuarios'],
        },
        colors: ['#4caf50', '#ff5252'],
      },
      activeInactiveChartSeries: [
        {
          name: 'Usuarios Activos',
          data: [0],
        },
        {
          name: 'Usuarios Inactivos',
          data: [0],
        },
      ],
      trainingsOverTimeChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'line',
        },
        xaxis: {
          categories: [],
        },
      },
      trainingsOverTimeChartSeries: [
        {
          name: 'Número de Entrenamientos',
          data: [],
        },
      ],
      averageRatingOverTimeChartOptions: {
        theme: {
          mode: this.$store.state.isDark ? 'dark' : 'light',
          palette: 'palette1',
        },
        chart: {
          type: 'line',
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 0,
          max: 5,
        },
      },
      averageRatingOverTimeChartSeries: [
        {
          name: 'Calificación Promedio',
          data: [],
        },
      ],
    };
  },
  watch: {
    selectedPeriod() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.fetchWorkouts();
      }
    },
    dateRange: {
      handler(newVal) {
        if (newVal.length === 2) {
          this.fetchWorkouts();
        }
      },
      deep: true,
    },
  },
  methods: {
    onPeriodChange() {
      if (this.selectedPeriod !== 'Periodo personalizado') {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },
    onDateRangeChange() {
      this.menu = false;
    },
    formatDate(date) {
      return moment(date.toDate()).locale('es').format('LL'); // e.g., 20 de abril de 2024
    },
    async fetchUsers() {
      const db = getFirestore();
      const usersCollection = collection(db, 'users');
      const usersQuery = query(usersCollection, where('type', '!=', 'admin'), where('disabled', '==', false));

      try {
        const querySnapshot = await getDocs(usersQuery);
        let users = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          name: doc.data().displayName, // Adjust if your field is different
          // ... other user fields
          type: doc.data().type,
        }));

        //remove type superuser
        this.users = users.filter(user => user.type !== 'superuser');
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    },
    async fetchWorkouts() {
      // Clean up previous listeners
      this.unsubscribes.forEach((unsubscribe) => unsubscribe());
      this.unsubscribes = [];
      this.workoutsMap = {};
      this.workouts = [];
      this.loading = true;

      const db = getFirestore();
      let startDate, endDate;

      if (this.selectedPeriod === 'Ultima semana') {
        startDate = moment().subtract(7, 'days').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Ultimo mes') {
        startDate = moment().subtract(1, 'months').startOf('day').toDate();
        endDate = new Date();
      } else if (this.selectedPeriod === 'Periodo personalizado' && this.dateRange.length === 2) {
        startDate = moment(this.dateRange[0]).startOf('day').toDate();
        endDate = moment(this.dateRange[1]).endOf('day').toDate();
      } else {
        startDate = null;
        endDate = null;
      }

      try {
        const workoutsCollection = collection(db, 'workouts');
        let workoutsQuery;

        if (startDate && endDate) {
          workoutsQuery = query(
            workoutsCollection,
            where('date', '>=', startDate),
            where('date', '<=', endDate),
            orderBy('date', 'desc')
          );
        } else {
          workoutsQuery = query(workoutsCollection, orderBy('date', 'desc'));
        }

        const unsubscribe = onSnapshot(
          workoutsQuery,
          async (snapshot) => {
            snapshot.docChanges().forEach((change) => {
              const workoutId = change.doc.id;
              const workoutData = change.doc.data();

              if (change.type === 'added' || change.type === 'modified') {
                // Add or update the workout in workoutsMap
                this.$set(this.workoutsMap, workoutId, {
                  id: workoutId,
                  ...workoutData,
                });
              } else if (change.type === 'removed') {
                // Remove the workout from workoutsMap
                this.$delete(this.workoutsMap, workoutId);
              }
            });
            // Update workouts array
            this.workouts = Object.values(this.workoutsMap);
            // Sort workouts by date (most recent first)
            this.workouts.sort((a, b) => b.date.toMillis() - a.date.toMillis());

            if (this.workouts.length > 0) {
              this.averageRating = this.workouts.reduce((acc, workout) => acc + workout.rating, 0) / this.workouts.length;
            } else {
              this.averageRating = 0;
            }

            // Fetch users and determine inactive users
            await this.fetchUsers();
            const activeUserIds = new Set(this.workouts.map((workout) => workout.user_id));
            this.inactiveUsers = this.users.filter((user) => !activeUserIds.has(user.id));

            this.loading = false;

            // Update charts
            this.updateCharts();
          },
          (error) => {
            console.error('Error listening to workouts:', error);
            this.loading = false;
          }
        );

        this.unsubscribes.push(unsubscribe);
      } catch (error) {
        console.error('Error fetching workouts:', error);
        this.loading = false;
      }
    },
    updateCharts() {
      // Update Active and Inactive Users Chart
      const totalUsers = this.users.length;
      const inactiveUsersCount = this.inactiveUsers.length;
      const activeUsersCount = totalUsers - inactiveUsersCount;
      this.activeInactiveChartSeries = [0, 0]  // Changed from array of objects to array of values
      this.activeInactiveChartSeries = [activeUsersCount, inactiveUsersCount];
      /*   this.activeInactiveChartSeries = [
          {
            name: 'Usuarios Activos',
            data: [activeUsersCount],
          },
          {
            name: 'Usuarios Inactivos',
            data: [inactiveUsersCount],
          },
        ]; */

      // Update Number of Trainings Over Time Chart
      const workoutsByDate = {};
      const ratingsByDate = {};

      this.workouts.forEach((workout) => {
        const dateStr = moment(workout.date.toDate()).format('YYYY-MM-DD');
        if (!workoutsByDate[dateStr]) {
          workoutsByDate[dateStr] = 0;
          ratingsByDate[dateStr] = [];
        }
        workoutsByDate[dateStr]++;
        if (workout.rating) {
          ratingsByDate[dateStr].push(workout.rating);
        }
      });

      const dates = Object.keys(workoutsByDate).sort();

      this.trainingsOverTimeChartOptions.xaxis.categories = dates;
      this.trainingsOverTimeChartSeries = [
        {
          name: 'Número de Entrenamientos',
          data: dates.map((date) => workoutsByDate[date]),
        },
      ];

      // Update Average Workout Rating Over Time Chart
      this.averageRatingOverTimeChartOptions.xaxis.categories = dates;
      this.averageRatingOverTimeChartSeries = [
        {
          name: 'Calificación Promedio',
          data: dates.map((date) => {
            const ratings = ratingsByDate[date];
            const average =
              ratings.reduce((sum, rating) => sum + rating, 0) / ratings.length || 0;
            return parseFloat(average.toFixed(2));
          }),
        },
      ];
    },
    getDaysLate(lateTimestamp, completedTimestamp) {
      const lateDate = moment(lateTimestamp.toDate());
      const completedDate = moment(completedTimestamp.toDate());
      return lateDate.diff(completedDate, 'days');
    },
  },
  mounted() {
    this.fetchWorkouts();
  },
  beforeDestroy() {
    // Clean up listeners
    this.unsubscribes.forEach((unsubscribe) => unsubscribe());
    this.unsubscribes = [];
  },
};
</script>

<style scoped>
.stats-card {
  transition: transform 0.2s;
}

.stats-card:hover {
  transform: translateY(-2px);
}

.workout-panel {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.workout-panel:last-child {
  border-bottom: none;
}

.activityCard.small {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
  scrollbar-width: thin;
}

.activityCard.small::-webkit-scrollbar {
  width: 6px;
}

.activityCard.small::-webkit-scrollbar-track {
  background: transparent;
}

.activityCard.small::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}
</style>

<style>
#activity .v-expansion-panel-content__wrap {
  padding: 0px !important;
}
</style>