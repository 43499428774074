import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("@/views/landing.vue"),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/inicio",
    name: "inicio",
    component: () => import("@/views/MobileHome.vue"),
    meta: {
      requiresAuth: false,
      title: "Inicio",
    },
  },
  {
    path: "/comunidad",
    name: "Community",
    component: () => import("@/views/Feed.vue"),
    meta: { requiresAuth: true, title: "Comunidad" },
  },
  /*  {
     path: "/signup",
     name: "signup",
     component: () => import('@/views/signup.vue'),
     meta: {
       requiresAuth: false,
       title: "Crea tu cuenta"
     }
   }, */

  {
    path: "/encuesta",
    name: "survey",
    component: () => import("@/views/Satisfaction.vue"),
    meta: {
      requiresAuth: true,
      title: "Encuesta de satisfacción",
    },
  },
  {
    path: "/progreso",
    name: "progress",
    component: () => import("@/components/Weights.vue"),
    meta: {
      requiresAuth: true,
      title: "Cargas",
    },
  },
  {
    path: "/perfil",
    name: "user",
    component: () => import("@/views/UserProfile.vue"),
    meta: {
      requiresAuth: true,
      title: "Perfil",
    },
  },
  {
    path: "/cronometro",
    name: "Chrono",
    component: () => import("@/views/ChronoApp.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/import",
    name: "import",
    component: () => import("@/components/UserImport.vue"),
    meta: {
      requiresAuth: true,
      onlyAdmin: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/negocio",
    name: "business",
    component: () => import("@/views/Admin.vue"),
    meta: {
      requiresAuth: true,
      onlyAdmin: true,
    },
  },
  {
    path: "/negocio/ajustes",
    name: "config",
    component: () => import("@/views/Config.vue"),
    meta: {
      requiresAuth: true,
      title: "Ajustes",
      onlyAdmin: true,
    },
  },
  {
    path: "/usuarios",
    name: "users",
    component: () => import("@/views/Users.vue"),
    meta: {
      title: "Usuarios",
      requiresAuth: true,
      onlyAdmin: true,
    },
  },
  /* {
    path: "*",
    name: "errorpage",
    component: () => import('@/views/ErrorPage.vue'),
    meta: {
      requiresAuth: false,
      title: "Error 404"
    }
  }, */
  {
    path: "/clases",
    name: "reservations",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "Reservas de clases",
    },
  },
  {
    path: "/horarios",
    name: "Schedules",
    component: () => import("@/components/Schedules.vue"),
    meta: {
      requiresAuth: true,
      title: "Horarios",
      onlyAdmin: true,
    },
  },
  {
    path: "/tareas",
    name: "Tasks",
    component: () => import("@/views/Tasks.vue"),
    meta: {
      requiresAuth: true,
      title: "Tareas",
      onlyAdmin: true,
    },
  },
  {
    path: "/negocio/ingresos-gastos",
    name: "IngresosGastos",
    component: () => import("@/views/IngressEgress.vue"),
    meta: {
      requiresAuth: true,
      title: "Ingresos y Gastos",
      onlyAdmin: true,
    },
  },

  {
    path: "/formularios",
    name: "forms",
    component: () => import("@/components/forms/FormManager.vue"),
    meta: {
      requiresAuth: true,
      title: "Captura de datos",
    },
  },
  {
    path: "/formularios/test",
    name: "formtest",
    component: () => import("@/views/FormTest.vue"),
    meta: {
      requiresAuth: true,
      title: "Test de formulario",
    },
  },
  {
    path: "/suscripcion",
    name: "suscripcion",
    component: () => import("@/views/Suscripcion.vue"),
    meta: {
      requiresAuth: true,
      title: "Suscripcion Vencida",
    },
  },

  {
    path: "/admin/logs",
    name: "logs",
    component: () => import("@/views/Logs.vue"),
    meta: {
      requiresAuth: true,
      title: "Suscripcion Vencida",
    },
  },

  {
    path: "/programas",
    name: "Routines",
    component: () => import("@/components/Planification.vue"),

    meta: {
      title: "Programas",
      requiresAuth: true,
      onlyAdmin: true,
    },
  },
  {
    path: "/entrenamiento",
    name: "training",
    component: () => import("@/views/PlanView.vue"),
    meta: {
      requiresAuth: true,
      title: "Planificaciónes",
    },
  },
  {
    path: "/glosario",
    name: "Exercises",
    component: () => import("@/views/ExerciseCRUD.vue"),
    meta: {
      requiresAuth: true,
      title: "Ejercicios",
      onlyAdmin: true,
    },
  },
  {
    path: "/actividad",
    name: "Activity",
    component: () => import("@/components/activity/UserActivity.vue"),
    meta: {
      requiresAuth: true,
      title: "Actividad",
    },
  },
  {
    path: "/negocio/analisis",
    name: "analisis",
    component: () => import("@/views/Reports.vue"),
    meta: {
      requiresAuth: true,
      title: "Análisis",
      onlyAdmin: true,
    },
  },
  {
    path: "/negocio/control-acceso",
    name: "control-acceso",
    component: () => import("@/components/QRreader.vue"),
    meta: {
      requiresAuth: true,
      title: "Control de Acceso",
      onlyAdmin: true,
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/components/checkout/MercadoPago.vue"),
    meta: {
      requiresAuth: true,
      title: "checkout",
    },
  },

  {
    path: "/pago",
    name: "payment",
    component: () => import("@/components/checkout/MercadoPago.vue"),
    meta: {
      requiresAuth: true,
      title: "payment",
    },
  },
  //same for pendiente and fallido
  {
    path: "/pendiente",
    name: "checkoutpendiente",
    component: () => import("@/components/checkout/MPpending.vue"),
    meta: {
      requiresAuth: true,
      title: "Pago pendiente",
    },
  },
  {
    path: "/fallido",
    name: "checkoutfallido",
    component: () => import("@/components/checkout/MPfailed.vue"),
    meta: {
      requiresAuth: true,
      title: "Pago fallido",
    },
  },
  {
    path: "/recursos",
    name: "resources",
    component: () => import("@/views/Resources.vue"),
    meta: {
      requiresAuth: true,
      title: "Recursos",
      onlyAdmin: true,
    },
  },

  {
    path: "/configuracion",
    name: "config",
    component: () => import("@/views/Config.vue"),
    meta: {
      requiresAuth: true,
      title: "Configuración",
      onlyAdmin: true,
    },
  },

  {
    path: "/reportar-problema",
    name: "Report",
    component: () => import("@/views/ReportIssue.vue"),
    meta: {
      requiresAuth: true,
      title: "Reportar problema",
    },
  },
  {
    path: "/mensajes",
    name: "Messages",
    component: () => import("@/views/Chat.vue"),
    meta: {
      requiresAuth: true,
      title: "Mensajes",
    },
  },
  {
    path: "/evaluaciones",
    name: "Evaluations",
    component: () => import("@/views/EvaluationsHistory.vue"),
    meta: {
      requiresAuth: true,
      title: "Evaluaciones",
      onlyAdmin: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresOnlyAdmin = to.matched.some((record) => record.meta.onlyAdmin);
  const userClaims = store.state.Auth.token;
  const isWebRTCSupported =
    typeof navigator.getUserMedia != "undefined" ||
    typeof window.RTCPeerConnection != "undefined";
  let WebRTCSupported = false;
  if (isWebRTCSupported) {
    WebRTCSupported = true;
  }

  if (
    /CriOS/i.test(navigator.userAgent) &&
    /iphone|ipod|ipad/i.test(navigator.userAgent)
  ) {
    WebRTCSupported = false;
  }

  if (requiresAuth && !userClaims) {
    next("/login");
    return;
  }

  let checkList = ["index", "login", "signup"];

  if (checkList.includes(to.name) && userClaims) {
    next("/inicio");
    return;
  }

  if (
    (to.name === "admin" ||
      to.name === "users" ||
      to.name === "import" ||
      to.name === "Schedules") &&
    userClaims &&
    !userClaims.claims.type
  ) {
    next("error");
    return;
  }

  if (to.name === "admin" && userClaims && !userClaims.claims.type) {
    next("/inicio");
    return;
  }

  if (
    requiresOnlyAdmin &&
    userClaims &&
    userClaims.claims.type &&
    userClaims.claims.type != "admin" &&
    userClaims.claims.type != "superuser" &&
    userClaims.claims.type != "entrenador"
  ) {
    next("/inicio");
    return;
  }

  if (requiresAuth && !userClaims) {
    next("/login");
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title
      ? to.meta.title + " - Tomas Pereiro"
      : "Tomas Pereiro - Entrenador Personal";
  });
});

export default router;
