<template>
  <v-container
    v-if="($store.state.Auth.token.claims.type != 'admin' && $store.state.Auth.token.claims.type != 'superuser')"
    class="safeMargin home-container pa-0">

    <!-- Hero Section -->
    <div class="hero-section">
      <v-card class="hero-card">
        <div class="hero-content">
          <div class="user-welcome">
            <span class="greeting">
              <!-- greeting hand emoji -->


              Hola,</span>
            <span class="user-name">{{ $store.state.Auth.token.claims.name.split(" ")[0] }}</span>
            <span class="ml-2 hand-emoji">👋</span>
          </div>
          <div class="motivation-text">
            <!-- rocket emoji -->


            ¡Es hora de entrenar!
            <span class="rocket-emoji">🚀</span>
          </div>
        </div>
      </v-card>
    </div>

    <!-- Progress Section -->
    <v-card class="progress-card mx-4 mt-n8">
      <WeeklyProgress :daysUsed="workoutsCompleted" :maxDays="user ? user.plan : 7" :workedOutToday="workedOutToday" />
    </v-card>

    <!-- Quick Actions -->
    <div class="quick-actions px-4 mt-6">
      <h2 class="section-title mb-4">Acciones rápidas</h2>
      <v-row :no-gutters="$vuetify.breakpoint.xsOnly">
        <v-col v-for="(item, index) in menuItems" :key="index" cols="4" class="pa-2">
          <v-card :ripple="true" class="action-card" @click="$router.push(item.route)">
            <div class="action-content">
              <v-icon :color="item.color" size="32">{{ item.icon }}</v-icon>
              <span class="action-title">{{ item.title }}</span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>

  </v-container>
  <Activity v-else />
</template>

<script>
import moment from 'moment';

import UserImage from '@/components/profile/UserImage.vue';
import WeeklyProgress from '@/components/training/WeeklyProgress.vue';


import Activity from '@/views/Activity.vue';

import {
  getFirestore, collection, query, where, getDocs, doc, getDoc
} from 'firebase/firestore';
import { logAuditEvent } from '@/error/audit.js';


export default {
  components: {
    UserImage,
    WeeklyProgress,
    Activity
  },
  data() {
    return {
      workoutsCompleted: -1,
      user: null,
      alert: false,
      workedOutToday: false,
      userImg: null,
      menuItems: [
        {
          title: 'Programa',
          icon: 'mdi-play-circle',
          route: '/entrenamiento',
          color: 'primary'
        },
        {
          title: 'Progreso',
          icon: 'mdi-finance',
          route: '/progreso',
          color: 'success'
        },
        {
          title: 'Actividad',
          icon: 'mdi-history',
          route: '/actividad',
          color: 'info'
        },
        {
          title: 'Comunidad',
          icon: 'mdi-bullhorn',
          route: '/comunidad',
          color: 'warning'
        },
        {
          title: 'Cronómetro',
          icon: 'mdi-timer',
          route: '/cronometro',
          color: 'error'
        }
      ]
    }
  },

  created() {

    this.userImg = { id: this.$store.state.Auth.token.claims.user_id }
  },

  async mounted() {
    this.$vuetify.goTo(0);

    logAuditEvent('read', this.$store.state.Auth.token.claims.user_id, `Loaded home page`);
    await this.getUser();
    this.workoutsCompleted = await this.getWorkoutsCompleted(this.$store.state.Auth.token.claims.user_id)

  },
  methods: {
    showData(route) {
      this.$router.push(route);
    },
    async getWorkoutsCompleted(user) {

      const db = getFirestore()
      const workoutsRef = collection(db, `workouts`);
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = this.getEndOfWeek();

      const q = query(workoutsRef, where("date", ">=", startOfWeek), where("date", "<", endOfWeek),
        where("user_id", "==", user)
      );
      const workoutDocs = await getDocs(q);

      let workoutsCompleted = workoutDocs.size
      const workoutDates = workoutDocs.docs.map(doc => doc.data().date.toDate().toISOString())

      const isSameDay = workoutDates.some(date => moment(date).isSame(moment(), 'day'))

      if (isSameDay) {
        this.workedOutToday = true
      }

      return workoutsCompleted
    },
    getStartOfWeek() {
      const now = new Date();
      let day = now.getDay();
      const diff = (day === 0 ? -6 : 1); // if it's Sunday, subtract 6, otherwise 1
      const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - day + diff, 0, 0, 0, 0);
      return startOfWeek;
    },
    getEndOfWeek() {
      const startOfWeek = this.getStartOfWeek();
      const endOfWeek = new Date(startOfWeek.getFullYear(), startOfWeek.getMonth(), startOfWeek.getDate() + 6, 23, 59, 59, 999);
      return endOfWeek;
    },
    async getUser() {
      const db = getFirestore()
      let user = this.$store.state.Auth.token.claims.user_id;


      const docRef = doc(db, `users/${user}`);
      const docSnap = await getDoc(docRef);

      let data = docSnap.data()

      data.plan = parseInt(data.plan)

      if (data.plan == 0) {
        const db = getFirestore()
        let scheduleIntervalsRef = doc(db, `configurations/schedule`);
        let scheduleIntervals = await getDoc(scheduleIntervalsRef);
        let scheduleIntervalsData = scheduleIntervals.data()
        let maxDays = 0
        scheduleIntervalsData.Domingo.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Lunes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Martes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Miercoles.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Jueves.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Viernes.length > 0 ? maxDays += 1 : null
        scheduleIntervalsData.Sabado.length > 0 ? maxDays += 1 : null

        data.plan = maxDays
      }

      this.user = data

      if (data.disabled) {
        let today = moment().toDate()
        await this.setAlert('user_disabled', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Tu usuario ha sido deshabilitado, contacta con el administrador.",
        });

        this.logout();

      }

      if (this.user.endOfSubscription) {
        let endOfSubscription = new Date(this.user.endOfSubscription.seconds * 1000)
        let today = moment().toDate()
        if (endOfSubscription < today) {
          //await this.setAlert('user_subscription_expired', today, null)
          this.$router.push({ name: 'suscripcion' })

        } else {
          //calculate diffs and if less than 5 days, show alert set this.alert=true

          let diff = moment(endOfSubscription).diff(today, 'days');
          if (diff > 0 && diff <= 5) {

            this.alert = diff;
          }

          if (diff <= 0) {
            //await this.setAlert('user_subscription_expired', today, null)
            this.$router.push({ name: 'suscripcion' })
          }

        }
      }

      let today = moment().toDate()

      //check if user is on an active licsense, for this it needs to check the user 'licensePaused' boolean property.
      if (this.user.licensePaused) {
        //await this.setAlert('user_license_paused', today, null)
        this.$notify({
          group: "feedback",
          title: "Error",
          type: "error",
          text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia. De lo contrario contacta con el administrador.",
        });
        this.$router.push('/perfil')
      }
      /* 
            //and also search in the user 'licenses' collection for license between issuedOn and resumedOn datess.
            // Query Firestore for licenses issued before today
            const licensesRef = collection(db, `users/${user}/licenses`);
            const q = query(licensesRef, where("issuedOn", "<", today));
      
            const licenseDocs = await getDocs(q);
            const filteredLicenses = licenseDocs.docs
              .map(doc => doc.data())
              .filter(license => {
                if (license.resumedOn) {
                  return new Date(license.resumedOn.seconds * 1000) > today;
                } else if (typeof license.resumedOn === 'undefined') {
                  return true;
                }
      
              }); // Filter by resumedOn in client
      
            if (filteredLicenses.length === 0) {
              //console.log('No matching documents.');
            } else {
              this.$notify({
                group: "feedback",
                title: "Error",
                type: "error",
                text: "Estas en una licencia activa, en tu perfil puedes desactivar tu licencia.",
              });
              this.$router.push('/perfil')
            } */





    },
  },
};
</script>

<style scoped>
.hero-section {
  background: linear-gradient(135deg, var(--v-primary-base), var(--v-secondary-base));
  height: 240px;
  border-radius: 0 0 30px 30px;
  position: relative;
  padding: 24px;
}

.hero-card {
  background: transparent !important;
  box-shadow: none !important;
}

.hero-content {
  color: white;
  padding-top: 20px;
}

.user-welcome {
  font-size: 2rem;
  margin-bottom: 8px;
}

.greeting {
  font-weight: 300;
}

.user-name {
  font-weight: 600;
  margin-left: 8px;
}

.motivation-text {
  font-size: 1.2rem;
  opacity: 0.9;
  font-weight: 300;
}

.progress-card {
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1) !important;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--v-primary-darken1);
}

.action-card {
  border-radius: 16px;
  transition: all 0.3s ease;
  height: 100px;
  cursor: pointer;
}

.action-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1) !important;
}

.action-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.action-title {
  margin-top: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 600px) {
  .hero-section {
    height: 200px;
  }

  .user-welcome {
    font-size: 1.75rem;
  }
}
</style>
