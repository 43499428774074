<template>
    <div class="trend-chart">
        <apexchart type="line" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'TrendChart',
    components: {
        apexchart: VueApexCharts
    },

    props: {
        data: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        unit: {
            type: String,
            required: true
        }
    },

    computed: {
        series() {
            if (this.data[0]?.values) {
                // Multiple lines for girths data
                return Object.keys(this.data[0].values).map(key => ({
                    name: this.getGirthLabel(key),
                    data: this.data.map(item => item.values[key])
                }))
            }
            // Single line for weight or skinfolds
            return [{
                name: this.label,
                data: this.data.map(item => item.value)
            }]
        },
        chartOptions() {
            return {
                chart: {
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    curve: 'smooth',
                    width: 2
                },
                xaxis: {
                    categories: this.data.map(item =>
                        new Date(item.date).toLocaleDateString()
                    ),
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    title: {
                        text: this.unit
                    },
                    labels: {
                        formatter: (value) => `${value.toFixed(1)} ${this.unit}`
                    }
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    y: {
                        formatter: (value) => `${value.toFixed(1)} ${this.unit}`
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'right'
                },
                grid: {
                    borderColor: '#f1f1f1'
                }
            }
        }
    },

    methods: {
        getGirthLabel(key) {
            const labels = {
                bmi: 'IMC',
                ffmi: 'FFMI',
                bfm: 'Masa Grasa',
                whr: 'Ratio Cintura-Cadera',
                wht: 'Ratio Cintura-Altura',
                endomorphy: 'Endomorfia',
                mesomorphy: 'Mesomorfia',
                ectomorphy: 'Ectomorfia',
                waist: 'Cintura',
                hip: 'Cadera',
                flexedArm: 'Brazo Flexionado',
                triceps: 'Tríceps',
                subscapular: 'Subescapular',
                biceps: 'Bíceps',
                iliacCrest: 'Cresta Ilíaca',
                supraspinale: 'Supraespinal',
                abdominal: 'Abdominal',
                frontThigh: 'Muslo Frontal',
                medialCalf: 'Pantorrilla Medial'
            }
            return labels[key] || key
        }
    }
}
</script>

<style scoped>
.trend-chart {
    width: 100%;
    height: 300px;
}
</style>