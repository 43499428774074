<template>
    <div class="weekly-progress pa-4">
        <v-row align="center" justify="center">
            <!-- Progress Stats -->
            <v-col cols="12" sm="6" class="pb-2">
                <div class="d-flex align-center">
                    <div class="progress-stats">
                        <transition name="fade" mode="out-in">
                            <v-skeleton-loader v-if="daysUsed === -1" key="skeleton" type="heading"
                                width="120"></v-skeleton-loader>
                            <div v-else key="stats" class="stats-container">
                                <span class="stats-number">{{ daysUsed }}/{{ maxDays }}</span>
                                <span class="stats-label">días completados</span>
                            </div>
                        </transition>
                    </div>
                    <v-spacer></v-spacer>
                    <div class="achievement-icon">
                        <transition name="fade" mode="out-in">
                            <v-skeleton-loader v-if="daysUsed === -1" key="skeleton" type="avatar"
                                width="48"></v-skeleton-loader>
                            <v-icon v-else key="icon" :color="achievementColor" size="48">
                                {{ achievementIcon }}
                            </v-icon>
                        </transition>
                    </div>
                </div>
            </v-col>

            <!-- Progress Bar -->
            <v-col cols="12" class="pt-0">
                <div class="progress-container">
                    <transition name="fade" mode="out-in">
                        <v-skeleton-loader v-if="daysUsed === -1" key="skeleton" type="text"
                            class="mt-1"></v-skeleton-loader>
                        <v-progress-linear v-else key="progress" :value="value" :color="progressColor" height="15"
                            rounded background-opacity="0.2" class="progress-bar">
                            <template v-slot:default="{ value }">
                                <span class="progress-text">{{ Math.ceil(value) }}%</span>
                            </template>
                        </v-progress-linear>
                    </transition>
                </div>

                <!-- Status Message -->
                <div class="status-message mt-3">
                    <transition name="fade" mode="out-in">
                        <v-skeleton-loader v-if="daysUsed === -1" key="skeleton" type="text"
                            class="mt-2"></v-skeleton-loader>
                        <div v-else key="message" class="message-text" :class="messageClass">
                            {{ statusMessage }}
                        </div>
                    </transition>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        daysUsed: {
            type: Number,
            required: false,
            default: null
        },
        maxDays: {
            type: Number,
            required: false,
            default: 7,
        },
        workedOutToday: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    computed: {
        value() {
            return this.daysUsed * 100 / this.maxDays;
        },
        progressColor() {
            if (this.value >= 100) return 'success';
            if (this.value >= 70) return 'primary';
            if (this.value >= 30) return 'warning';
            return 'error';
        },
        achievementIcon() {
            if (this.value >= 100) return 'mdi-trophy-variant';
            if (this.value >= 70) return 'mdi-fire';
            if (this.value >= 30) return 'mdi-run';
            return 'mdi-walk';
        },
        achievementColor() {
            if (this.value >= 100) return 'amber darken-2';
            if (this.value >= 70) return 'success';
            if (this.value >= 30) return 'primary';
            return 'grey';
        },
        statusMessage() {
            if (this.workedOutToday) return '¡Gran trabajo! Has entrenado hoy 💪';
            if (this.daysUsed >= this.maxDays) return '¡Has alcanzado tu meta semanal! 🎉';
            return 'Continúa con tu rutina para alcanzar tus objetivos 🎯';
        },
        messageClass() {
            if (this.workedOutToday || this.daysUsed >= this.maxDays) return 'success--text';
            return 'primary--text';
        }
    },
};
</script>

<style scoped>
.progress-stats {
    flex-grow: 1;
}

.stats-container {
    display: flex;
    flex-direction: column;
}

.stats-number {
    font-size: 2rem;
    font-weight: 700;
    color: var(--v-primary-base);
    line-height: 1;
}

.stats-label {
    font-size: 0.875rem;
    margin-top: 4px;
}

.progress-container {
    margin-top: 8px;
}

.progress-bar {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.progress-text {
    font-weight: 600;
    color: white;
}

.message-text {
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
}

.achievement-icon {
    animation: bounce 2s infinite;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
