<template>
    <v-card class="glass mx-4">
        <v-toolbar flat>
            <v-text-field filled rounded dense single-line v-model="search" append-icon="mdi-magnify" label="Buscar"
                hide-details clearable class="rounded-lg"></v-text-field>
            <v-spacer />
            <v-btn color="primary" class="rounded-lg" @click="openCreateDialog">
                <v-icon left>mdi-plus</v-icon>
                Crear Objetivo
            </v-btn>
        </v-toolbar>
        <v-divider />

        <v-data-table mobile-breakpoint="0" :headers="headers" :items="filteredObjectives" :search="search"
            :items-per-page="itemsPerPage" :sort-by="sortBy" :sort-desc="sortDesc" @update:sort-by="v => sortBy = v"
            @update:sort-desc="v => sortDesc = v" class="elevation-0"
            :footer-props="{ itemsPerPageOptions: [5, 10, 20, 50] }">
            <template v-slot:item.status="{ item }">
                <v-chip :color="statusColor(item.status)" small text-color="white">{{ item.status }}</v-chip>
            </template>

            <template v-slot:item.overdue="{ item }">
                <v-icon v-if="isOverdue(item)">mdi-alert</v-icon>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-btn icon small @click="openEditDialog(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn icon small @click="openDeleteDialog(item)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>

            <template v-slot:item.startDate="{ item }">
                <span style="text-transform:capitalize;"> {{ fromNow(item.startDate) }} </span>
            </template>

            <template v-slot:item.endDate="{ item }">
                <span style="text-transform:capitalize;"> {{ fromNow(item.endDate) }} </span>
            </template>


        </v-data-table>

        <!-- Create/Edit Dialog -->
        <v-dialog v-model="showDialog" max-width="600px">
            <v-card>
                <v-card-title>
                    {{ dialogTitle }}
                    <v-spacer />
                    <v-btn icon @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />

                <v-card-text class="pt-6">
                    <v-form ref="objectiveForm">
                        <v-text-field filled v-model="editedObjective.title" label="Título"
                            :rules="[v => !!v || 'El título es obligatorio']" required></v-text-field>
                        <v-textarea filled v-model="editedObjective.description" label="Descripción"></v-textarea>
                        <v-menu ref="startMenu" v-model="startMenu" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field filled v-model="startDateInput" label="Fecha de Inicio" readonly
                                    v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedObjective.startDate" scrollable
                                @input="startMenu = false"></v-date-picker>
                        </v-menu>
                        <v-menu ref="endMenu" v-model="endMenu" :close-on-content-click="false"
                            transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field filled v-model="endDateInput" label="Fecha de Fin" readonly v-bind="attrs"
                                    v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="editedObjective.endDate" scrollable
                                @input="endMenu = false"></v-date-picker>
                        </v-menu>
                        <v-select filled v-model="editedObjective.status" :items="statusOptions" label="Estado"
                            required></v-select>

                        <!-- Admin can set userId -->
                        <v-text-field v-if="isAdmin" v-model="editedObjective.userId" label="ID de Usuario"
                            hint="Asigna este objetivo a un usuario específico (Solo Admin)"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-divider />

                <v-card-actions>
                    <v-spacer />
                    <v-btn text @click="closeDialog">Cancelar</v-btn>
                    <v-btn color="primary" @click="saveObjective">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Delete Confirmation Dialog -->
        <v-dialog v-model="showDeleteDialog" max-width="500px">
            <v-card>
                <v-card-title>
                    Confirmar Eliminación
                </v-card-title>
                <v-card-text>
                    ¿Estás seguro de que quieres eliminar este objetivo?
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="closeDeleteDialog">Cancelar</v-btn>
                    <v-btn color="error" @click="deleteObjective">Eliminar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar.show" :color="snackbar.color" top right>
            {{ snackbar.message }}
            <v-btn text @click="snackbar.show = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-snackbar>
    </v-card>
</template>

<script>
import { getFirestore, collection, query, where, orderBy, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { Timestamp, serverTimestamp } from 'firebase/firestore';

import moment from 'moment';

export default {
    name: 'ObjectivesManager',
    props: {
        user: { type: Object, required: true },  // { uid: 'xyz', ... }
        isAdmin: { type: Boolean, default: false }
    },
    data() {
        return {
            objectives: [],
            search: '',
            sortBy: 'title',
            sortDesc: false,
            itemsPerPage: 10,
            statusOptions: ['No Iniciado', 'En Progreso', 'Completado'],
            headers: [
                { text: 'Título', value: 'title', sortable: true },
                { text: 'Descripción', value: 'description', sortable: true },
                { text: 'Estado', value: 'status', sortable: true },
                { text: 'Vencido', value: 'overdue', sortable: false },
                { text: 'Fecha de Inicio', value: 'startDate', sortable: true },
                { text: 'Fecha de Fin', value: 'endDate', sortable: true },
                { text: 'Acciones', value: 'actions', sortable: false }
            ],
            showDialog: false,
            showDeleteDialog: false,
            editedObjective: {
                title: '',
                description: '',
                startDate: null,
                endDate: null,
                status: 'No Iniciado',
                userId: null
            },
            startMenu: false,
            endMenu: false,
            editMode: false,
            selectedObjectiveId: null,
            snackbar: {
                show: false,
                message: '',
                color: 'success'
            }
        }
    },
    computed: {
        filteredObjectives() {
            return this.objectives.map(obj => {
                return {
                    ...obj,
                    overdue: this.isOverdue(obj)
                }
            });
        },
        dialogTitle() {
            return this.editMode ? 'Editar Objetivo' : 'Crear Objetivo';
        },
        startDateInput: {
            get() {
                return this.editedObjective.startDate ? this.formatDate(this.editedObjective.startDate) : '';
            },
            set(value) {
                this.editedObjective.startDate = value ? new Date(value) : null;
            }
        },
        endDateInput: {
            get() {
                return this.editedObjective.endDate ? this.formatDate(this.editedObjective.endDate) : '';
            },
            set(value) {
                this.editedObjective.endDate = value ? new Date(value) : null;
            }
        }
    },
    async created() {
        await this.fetchObjectives();
    },
    methods: {
        fromNow(date) {
            if (!date) return '';
            return moment(date).locale('es').fromNow();
        },
        async fetchObjectives() {
            const db = getFirestore();
            let q;
            const objectivesRef = collection(db, 'objectives');

            if (this.isAdmin) {
                // Admin can see all objectives
                q = query(objectivesRef, orderBy('createdAt', 'desc'));
            } else {
                // Normal user only sees their own
                q = query(objectivesRef, where('userId', '==', this.user.id), orderBy('createdAt', 'desc'));
            }

            const snapshot = await getDocs(q);
            this.objectives = snapshot.docs.map(doc => {
                const data = doc.data();
                return {
                    id: doc.id,
                    ...data,
                    startDate: data.startDate?.toDate ? data.startDate.toDate() : null,
                    endDate: data.endDate?.toDate ? data.endDate.toDate() : null
                }
            });
        },

        openCreateDialog() {
            this.editMode = false;
            this.resetEditedObjective();
            if (!this.isAdmin) {
                this.editedObjective.userId = this.$props.user.id;
            }
            this.showDialog = true;
        },

        openEditDialog(item) {
            // Only allow edit if admin or user's own objective
            if (!this.isAdmin && item.userId !== this.user.id) {
                this.showSnackbar('No tienes permiso para editar este objetivo.', 'error');
                return;
            }
            this.editMode = true;
            this.selectedObjectiveId = item.id;
            this.editedObjective = { ...item };
            this.showDialog = true;
        },

        openDeleteDialog(item) {
            // Only allow delete if admin or user's own objective
            if (!this.isAdmin && item.userId !== this.user.id) {
                this.showSnackbar('No tienes permiso para eliminar este objetivo.', 'error');
                return;
            }
            this.selectedObjectiveId = item.id;
            this.showDeleteDialog = true;
        },

        closeDialog() {
            this.showDialog = false;
            this.resetEditedObjective();
        },

        closeDeleteDialog() {
            this.showDeleteDialog = false;
            this.selectedObjectiveId = null;
        },

        async saveObjective() {
            // Validation
            if (!this.validateObjective()) return;

            const objectiveData = {
                title: this.editedObjective.title,
                description: this.editedObjective.description || '',
                status: this.editedObjective.status || 'No Iniciado',
                startDate: this.editedObjective.startDate ? Timestamp.fromDate(moment(this.editedObjective.startDate, 'YYYY-MM-DD').toDate()) : null,
                endDate: this.editedObjective.endDate ? Timestamp.fromDate(moment(this.editedObjective.endDate, 'YYYY-MM-DD').toDate()) : null,
                userId: this.editedObjective.userId || this.user.id,
                created: this.editedObjective.created || serverTimestamp(),
                updatedAt: serverTimestamp()
            };

            // Check date validity
            if (objectiveData.startDate && objectiveData.endDate && objectiveData.startDate.toMillis() > objectiveData.endDate.toMillis()) {
                this.showSnackbar('La fecha de fin debe ser posterior a la fecha de inicio', 'error');
                return;
            }

            try {
                const db = getFirestore();

                if (this.editMode) {
                    // Update existing

                    const docRef = doc(db, 'objectives', this.selectedObjectiveId);
                    await updateDoc(docRef, objectiveData);
                    this.showSnackbar('Objetivo actualizado con éxito', 'success');
                } else {
                    // Create new
                    objectiveData.createdAt = Timestamp.now();
                    await addDoc(collection(db, 'objectives'), objectiveData);
                    this.showSnackbar('Objetivo creado con éxito', 'success');
                }
                this.showDialog = false;
                await this.fetchObjectives();
            } catch (error) {
                console.error(error);
                this.showSnackbar('Error al guardar el objetivo', 'error');
            }
        },

        async deleteObjective() {
            try {
                const db = getFirestore();

                const docRef = doc(db, 'objectives', this.selectedObjectiveId);
                await deleteDoc(docRef);
                this.showSnackbar('Objetivo eliminado con éxito', 'success');
                this.showDeleteDialog = false;
                await this.fetchObjectives();
            } catch (error) {
                console.error(error);
                this.showSnackbar('Error al eliminar el objetivo', 'error');
            }
        },

        resetEditedObjective() {
            this.editedObjective = {
                title: '',
                description: '',
                startDate: null,
                endDate: null,
                status: 'No Iniciado',
                userId: null
            };
            this.selectedObjectiveId = null;
        },

        validateObjective() {
            if (!this.editedObjective.title) {
                this.showSnackbar('El título es obligatorio', 'error');
                return false;
            }
            return true;
        },

        showSnackbar(message, color = 'success') {
            this.snackbar.message = message;
            this.snackbar.color = color;
            this.snackbar.show = true;
        },

        formatDate(date) {
            if (!date) return '';
            const d = new Date(date);
            return d.toISOString().substr(0, 10);
        },

        isOverdue(item) {
            if (!item.endDate) return false;
            const now = new Date();
            return now > item.endDate && item.status !== 'Completado';
        },

        statusColor(status) {
            if (status === 'Completado') return 'green';
            if (status === 'En Progreso') return 'blue';
            return 'grey';
        }
    }
}
</script>

<style scoped>
.headline {
    font-weight: bold;
}
</style>
