<template>
    <v-row no-gutters>
        <Builder @confirmWorkout="confirmGeneratedWorkout" ref="asistantBuilder" />
        <v-col cols="12" md="3" v-show="!$vuetify.breakpoint.smAndDown">
            <!-- Drawer for adding new exercises -->
            <v-toolbar flat class="pl-2">
                Ejercicios

                <v-spacer class="mr-4"></v-spacer>

                <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" rounded filled
                    class="rounded-lg" dense single-line hide-details></v-text-field>




            </v-toolbar>

            <v-divider />
            <v-card flat class="fill-height rounded-0" style="height: calc(100dvh - 200px);overflow-y:scroll;">
                <v-card-text class="pa-0 ">

                    <v-list class="py-0 fill-height" dense>
                        <draggable v-model="filteredExercises" :group="{ name: 'exercises', pull: 'clone', put: false }"
                            :clone="cloneExercise">
                            <v-list-item v-for="(exercise, index) in filteredExercises" :key="index" @click=""
                                style="cursor: move">

                                <v-list-item-content>
                                    <v-list-item-title v-html="highlightSearch(exercise.name)"></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn fab small elevation="0" @click="newExercise.id = exercise.id; addExercise()">
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </v-list-item-action>

                            </v-list-item>
                        </draggable>
                    </v-list>
                </v-card-text>
            </v-card>
            <v-divider vertical></v-divider>

        </v-col>

        <v-col cols="12" md="9">
            <v-toolbar flat>
                <v-icon left>mdi-format-list-bulleted</v-icon>
                Programa
                <v-spacer></v-spacer>

                <!-- Add Cluster Button -->


                <v-bottom-sheet v-model="showBottomSheet" :value="selectedExercises.length > 0" hide-overlay
                    max-width="400" persistent>
                    <v-card>
                        <v-card-title class="d-flex justify-space-between align-center">
                            <span>{{ selectedExercises.length }}
                                {{ selectedExercises.length > 1 ? 'ejercicios seleccionados' : 'ejercicio seleccionado'
                                }}
                            </span>
                            <v-btn icon @click="unselectAll">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-card-actions class="pb-6">

                            <v-btn outlined color="error" @click="removeSelectedExercises">
                                <v-icon left>mdi-delete</v-icon>
                                borrar
                            </v-btn>

                            <v-spacer></v-spacer>



                            <v-btn color="primary" @click="createClusterFromSelected">
                                <v-icon left>mdi-plus-box</v-icon>
                                Crear grupo
                            </v-btn>

                        </v-card-actions>

                    </v-card>
                </v-bottom-sheet>

                <!-- Clean list -->




                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="undo" elevation="0" :disabled="!canUndo" icon>
                            <v-icon>mdi-undo</v-icon>
                        </v-btn>
                    </template>
                    <span>Deshacer</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" @click="redo" elevation="0" :disabled="!canRedo" icon>
                            <v-icon>mdi-redo</v-icon>
                        </v-btn>
                    </template>
                    <span>Rehacer</span>
                </v-tooltip>

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="cleanExercises" elevation="0"
                            :disabled="dayExercises.length == 0">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                    </template>
                    <span>Borrar todo</span>

                </v-tooltip>


            </v-toolbar>
            <v-divider />


            <v-card flat>
                <v-card-text class=" pa-2">
                    <v-list dense class="py-0 ">

                        <!-- Draggable when the list is empty with a centered text and drop like styles -->
                        <draggable v-if="dayExercises.length == 0" v-model="dayExercises" group="exercises"
                            class="empty-list">
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title class="text-center">
                                        Arrastra ejercicios aquí
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </draggable>



                        <!-- Draggable list containing both exercises and clusters -->
                        <draggable v-else v-model="dayExercises" group="exercises" handle=".drag-handle"
                            class="empty-list2">


                            <v-list-item dense v-for="(exercise, index) in dayExercises" :key="index"
                                @click="toggleSelect(index)" :class="{ 'selected-item': isSelected(index) }"
                                class="drag-handle px-0">

                                <!-- Render clusters differently -->
                                <div v-if="exercise.isCluster && exercise.isCluster == true" class="cluster-container">
                                    <v-list-item class="drag-handle">
                                        <v-avatar size="30" class="mr-4  font-weight-bold" color="success" style="
    color: white;">
                                            {{ abecedario[index] }}
                                        </v-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title style="font-weight: bold;text-transform:uppercase">

                                                <template v-if="exercise.title">
                                                    {{ exercise.title }}
                                                </template>
                                                <template v-else>
                                                    {{ exercise.exercises.length }} Ejercicios en este grupo
                                                </template>
                                            </v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ exercise.sets }} sets {{ exercise.rest ? 'x ' + exercise.rest + '
                                                segundos de
                                                descanso' : '' }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-toolbar-items>
                                            <v-btn icon small @click="removeExercise(index)">
                                                <v-icon color="error" small>mdi-close</v-icon>
                                            </v-btn>

                                            <v-menu offset-y :close-on-content-click="false" width="300">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn v-on="on" text small icon>
                                                        <v-icon x-small size="15">mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <!-- Menu content for editing -->
                                                <v-card>
                                                    <v-card-text>
                                                        <v-row>

                                                            <v-col cols="12">
                                                                <v-text-field label="Título" v-model="exercise.title"
                                                                    dense hide-details="" outlined></v-text-field>
                                                            </v-col>


                                                            <v-col cols="12">
                                                                <v-text-field label="Sets" v-model="exercise.sets"
                                                                    type="number" min="1" step="1" dense hide-details=""
                                                                    outlined></v-text-field>
                                                            </v-col>

                                                            <v-col cols="12">
                                                                <v-text-field label="Descanso (segundos)"
                                                                    v-model="exercise.rest" type="number" min="1"
                                                                    step="1" dense hide-details=""
                                                                    outlined></v-text-field>
                                                            </v-col>

                                                            <!-- 
                                                            <v-col cols="6">
                                                                <v-text-field label="Ejercicios"
                                                                    v-model="exercise.exercises.length" type="number"
                                                                    min="1" step="1" dense hide-details=""
                                                                    outlined></v-text-field>

                                                            </v-col> -->
                                                        </v-row>
                                                    </v-card-text>
                                                </v-card>
                                            </v-menu>
                                        </v-toolbar-items>
                                    </v-list-item>

                                    <!-- Display exercises inside the cluster -->
                                    <v-list dense class="cluster-exercise-list">


                                        <draggable v-model="exercise.exercises" group="exercises"
                                            handle=".drag-handle-cluster">
                                            <v-list-item v-for="(subExercise, exIndex) in exercise.exercises"
                                                :key="exIndex" class="clustered-exercise">
                                                <v-avatar size="30" color="success"
                                                    class="mr-4 overflow-visible font-weight-bold" style="
    color: white;">

                                                    {{ abecedario[index] }}{{ exIndex + 1 }}


                                                </v-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title>{{ exerciseHashIdNames[subExercise.id]
                                                        }}</v-list-item-title>
                                                    <v-list-item-subtitle>

                                                        <template
                                                            v-if="subExercise.type === 'reps' && Array.isArray(subExercise.value)">
                                                            {{ subExercise.value.join('-') }} reps
                                                        </template>
                                                        <template v-else>
                                                            {{ subExercise.type === 'reps' ? subExercise.value + ' reps'
                                                                : subExercise.value + `''` }}
                                                        </template>
                                                        {{ subExercise.rest ? ` x ${subExercise.rest}'' descanso` : ''
                                                        }}
                                                        <template v-if="subExercise.rpe">
                                                            <v-chip x-small label class="ml-1">
                                                                RPE {{ subExercise.rpe }}
                                                            </v-chip>
                                                        </template>
                                                    </v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-toolbar-items>
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" icon small @click="removeExercise(index)">
                                                                <v-icon color="error" small>mdi-close</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Eliminar</span>
                                                    </v-tooltip>

                                                    <v-menu offset-y :close-on-content-click="false" width="300">
                                                        <template v-slot:activator="{ on }">


                                                            <v-tooltip bottom>
                                                                <template v-slot:activator="{ on: tooltipOn }">
                                                                    <v-btn v-on="Object.assign({}, on, tooltipOn)" text
                                                                        small icon>
                                                                        <v-icon x-small size="15">mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Editar</span>
                                                            </v-tooltip>



                                                        </template>


                                                        <!-- Menu content for editing -->
                                                        <v-card>
                                                            <v-card-text>
                                                                <v-select v-model="subExercise.type"
                                                                    :items="exerciseTypes" label="Tipo" dense
                                                                    outlined></v-select>
                                                                <v-row>

                                                                    <v-col cols="12" v-if="subExercise.type === 'time'">
                                                                        <v-text-field v-model="subExercise.value"
                                                                            label="Segundos" type="number" min="1"
                                                                            step="1" dense hide-details=""
                                                                            outlined></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" v-else>
                                                                        <v-text-field v-model="subExercise.valueInput"
                                                                            label="Repeticiones (ej:12 o 12-10-8 variado)"
                                                                            @input="handleRepsInput($event, subExercise, exercise.sets)"
                                                                            @keypress="e => {
                                                                                const char = String.fromCharCode(e.keyCode);
                                                                                if (char === '-') {
                                                                                    const currentValue = subExercise.valueInput || '';
                                                                                    const currentDashes = currentValue.split('-').length - 1;
                                                                                    // Prevent: double dashes, trailing dashes, and more dashes than allowed by sets
                                                                                    if (currentValue.endsWith('-') ||
                                                                                        currentDashes >= exercise.sets - 1 ||
                                                                                        currentValue.length === 0) {
                                                                                        e.preventDefault();
                                                                                        return;
                                                                                    }
                                                                                }
                                                                                const pattern = /[0-9-]/;
                                                                                if (!pattern.test(char)) e.preventDefault();
                                                                            }" dense hide-details="" outlined
                                                                            :hint="'Usa guiones para series piramidales'"
                                                                            persistent-hint></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col cols="6">
                                                                        <v-text-field label="Descanso (segundos)"
                                                                            v-model="subExercise.rest" type="number"
                                                                            min="1" step="1" dense hide-details=""
                                                                            outlined></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="6">
                                                                        <v-text-field label="RPE"
                                                                            v-model="subExercise.rpe" type="number"
                                                                            min="0" max="10" step="1" dense
                                                                            hide-details="" outlined
                                                                            :rules="[v => (v >= 0 && v <= 10) || 'RPE debe estar entre 0 y 10']"
                                                                            hint="Escala de esfuerzo percibido (0-10)"
                                                                            persistent-hint>
                                                                            <template v-slot:append>
                                                                                <RPEVisualizer
                                                                                    :rpe="Number(subExercise.rpe) || 0" />
                                                                            </template>
                                                                        </v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-menu>

                                                    <!--drag handle clustr-->
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" text small icon
                                                                class="drag-handle-cluster">
                                                                <v-icon x-small size="15">mdi-cursor-move</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Reordenar</span>
                                                    </v-tooltip>
                                                </v-toolbar-items>


                                            </v-list-item>
                                        </draggable>
                                    </v-list>
                                </div>

                                <!-- Render regular exercises -->
                                <template v-else>
                                    <v-avatar size="30" class="mx-4  font-weight-bold" color="success" style="
    color: white;">
                                        {{ abecedario[index] }}
                                    </v-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title style="font-weight: bold;text-transform:uppercase">
                                            {{ exerciseHashIdNames[exercise.id] }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ exercise.sets }} x
                                            <template v-if="exercise.type === 'reps' && Array.isArray(exercise.value)">
                                                {{ exercise.value.join('-') }} reps
                                            </template>
                                            <template v-else>
                                                {{ exercise.type === 'reps' ? exercise.value + ' reps' : exercise.value
                                                    + `''` }}
                                            </template>
                                            {{ exercise.rest ? ` x ${exercise.rest}'' descanso` : '' }}
                                            <template v-if="exercise.rpe">
                                                <v-chip x-small label class="ml-1">
                                                    RPE {{ exercise.rpe }}
                                                </v-chip>
                                            </template>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-toolbar-items>

                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn icon small @click="removeExercise(index)" v-on="on">
                                                    <v-icon color="error" small>mdi-close</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>






                                        <v-menu offset-y :close-on-content-click="false" width="300">
                                            <template v-slot:activator="{ on }">


                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on: tooltipOn }">
                                                        <v-btn v-on="Object.assign({}, on, tooltipOn)" text small icon>
                                                            <v-icon x-small size="15">mdi-pencil</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <span>Editar</span>
                                                </v-tooltip>



                                            </template>
                                            <!-- Menu content for editing -->
                                            <v-card>
                                                <v-card-text>
                                                    <v-select v-model="exercise.type" :items="exerciseTypes"
                                                        label="Tipo" dense outlined></v-select>
                                                    <v-row>
                                                        <v-col cols="4">
                                                            <v-text-field label="Sets" v-model="exercise.sets"
                                                                type="number" min="1" step="1" dense hide-details=""
                                                                outlined></v-text-field>
                                                        </v-col>
                                                        <v-col cols="8" v-if="exercise.type === 'time'">
                                                            <v-text-field v-model="exercise.value" label="Segundos"
                                                                type="number" min="1" step="1" dense hide-details=""
                                                                outlined></v-text-field>
                                                        </v-col>
                                                        <v-col cols="8" v-else>
                                                            <v-text-field v-model="exercise.valueInput"
                                                                label="Repeticiones (ej: 12-10-8)"
                                                                @input="handleRepsInput($event, exercise)" @keypress="e => {
                                                                    const char = String.fromCharCode(e.keyCode);
                                                                    if (char === '-') {
                                                                        const currentDashes = (exercise.valueInput || '').split('-').length - 1;
                                                                        if (currentDashes >= exercise.sets - 1) {
                                                                            e.preventDefault();
                                                                            return;
                                                                        }
                                                                    }
                                                                    const pattern = /[0-9-]/;
                                                                    if (!pattern.test(char)) e.preventDefault();
                                                                }" dense hide-details="" outlined
                                                                :hint="'Usa guiones para series piramidales'"
                                                                persistent-hint></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col cols="6">
                                                            <v-text-field label="Descanso (segundos)"
                                                                v-model="exercise.rest" type="number" min="1" step="1"
                                                                dense hide-details="" outlined></v-text-field>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-text-field label="RPE" v-model="exercise.rpe"
                                                                type="number" min="0" max="10" step="1" dense
                                                                hide-details="" outlined
                                                                :rules="[v => (v >= 0 && v <= 10) || 'RPE debe estar entre 0 y 10']"
                                                                hint="Escala de esfuerzo percibido (0-10)"
                                                                persistent-hint>
                                                                <template v-slot:append>
                                                                    <RPEVisualizer :rpe="Number(exercise.rpe) || 0" />
                                                                </template>
                                                            </v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-menu>
                                    </v-toolbar-items>
                                </template>
                            </v-list-item>





                        </draggable>

                    </v-list>

                    <v-menu offset-y :close-on-content-click="false" max-width="500">
                        <template v-slot:activator="{ on }">
                            <v-btn large v-on="on" block color="primary" elevation="0" class="mt-2 rounded-0">
                                <v-icon left>
                                    mdi-plus
                                </v-icon>
                                Agregar Ejercicio
                            </v-btn>
                        </template>
                        <!-- Menu content for adding exercise -->
                        <v-card>
                            <v-card-text>
                                <v-select v-model="newExercise.type" :items="exerciseTypes" label="Tipo" dense
                                    outlined></v-select>

                                <v-row>
                                    <v-col cols="4">
                                        <v-text-field label="Sets" v-model="newExercise.sets" type="number" min="1"
                                            step="1" dense outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="8" v-if="newExercise.type === 'time'">
                                        <v-text-field v-model="newExercise.value" label="Segundos" type="number" min="1"
                                            step="1" dense outlined></v-text-field>
                                    </v-col>
                                    <v-col cols="8" v-else>
                                        <v-text-field v-model="newExercise.valueInput"
                                            label="Repeticiones (ej: 12-10-8)"
                                            @input="handleRepsInput($event, newExercise)" @keypress="e => {
                                                const char = String.fromCharCode(e.keyCode);
                                                if (char === '-') {
                                                    const currentDashes = (newExercise.valueInput || '').split('-').length - 1;
                                                    if (currentDashes >= newExercise.sets - 1) {
                                                        e.preventDefault();
                                                        return;
                                                    }
                                                }
                                                const pattern = /[0-9-]/;
                                                if (!pattern.test(char)) e.preventDefault();
                                            }" dense hide-details="" outlined
                                            :hint="'Usa guiones para series piramidales'"
                                            persistent-hint></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-autocomplete v-model="newExercise.id" :items="exercises" item-text="name"
                                    hide-details="" item-value="id" label="Seleccionar ejercicio" dense
                                    outlined></v-autocomplete>
                            </v-card-text>

                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" @click="addExercise">
                                    <v-icon left>
                                        mdi-plus
                                    </v-icon>
                                    Agregar</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-menu>

                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn block class="rounded-0" elevation="0" v-on="on" large
                                style="text-transform: capitalize" @click="openAssistant">
                                <v-icon left small>
                                    mdi-robot-happy
                                </v-icon>
                                Asistente
                            </v-btn>
                        </template>
                        <span>Generar con Asistente</span>
                    </v-tooltip>

                </v-card-text>
            </v-card>



        </v-col>
    </v-row>
</template>

<script>
import draggable from 'vuedraggable';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Builder from './Builder.vue';
import RPEVisualizer from '../training/RPEVisualizer.vue';

export default {
    props: {
        exercises: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    components: {
        draggable, Builder, RPEVisualizer
    },
    data() {
        return {
            abecedario: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],

            drawer: false,
            dayExercises: [],
            selectedExercises: [],
            exerciseTypes: [
                { text: 'Repeticiones', value: 'reps' },
                { text: 'Tiempo', value: 'time' },
            ],
            exerciseHashIdNames: {},
            newExercise: {
                id: null,
                sets: 1,
                type: 'reps',
                value: 10,
                valueInput: '10',
            },
            search: null,
            showBottomSheet: false,
            history: [],
            currentHistoryIndex: -1,
            maxHistorySize: 50, // Maximum number of states to keep in history
        };
    },
    mounted() {
        // Remove this line:
        // this.saveToHistory();
    },
    computed: {
        filteredExercises() {
            if (!this.search) return this.exercises;

            return this.exercises.filter(ex => ex.name.toLowerCase().includes(this.search.toLowerCase()));
        },
        canUndo() {
            return this.currentHistoryIndex > 0;
        },

        canRedo() {
            return this.currentHistoryIndex < this.history.length - 1;
        },
    },

    methods: {

        async openAssistant() {
            this.$refs.asistantBuilder.toggleDialog();
        },
        async removeSelectedExercises() {
            this.dayExercises = this.dayExercises.filter((_, index) => !this.selectedExercises.includes(index));
            this.selectedExercises = [];
            this.showBottomSheet = false;

            console.log('remove selected');
        },
        unselectAll() {
            this.selectedExercises = [];
            this.showBottomSheet = false;

            console.log('unselect all');
        },

        highlightSearch(text) {
            if (!this.search) return text;
            const search = this.search.toLowerCase();
            const index = text.toLowerCase().indexOf(search);
            if (index === -1) return text;
            return text.substring(0, index) + '<span class="font-weight-bold">' + text.substring(index, index + search.length) + '</span>' + text.substring(index + search.length);
        },

        cloneExercise({ id }) {
            return {
                id,
                sets: 1,
                type: 'reps',
                value: 10,
            };
        },
        cleanExercises() {
            this.dayExercises = [];
            this.selectedExercises = [];
            this.saveToHistory();
        },
        toggleSelect(index) {
            const selectedIndex = this.selectedExercises.indexOf(index);

            //check if its a cluster and return false
            if (this.dayExercises[index].isCluster) {
                return false;
            }

            if (selectedIndex === -1) {
                this.selectedExercises.push(index);
            } else {
                this.selectedExercises.splice(selectedIndex, 1);
            }

            if (this.selectedExercises.length === 0) {
                this.showBottomSheet = false;
                console.log('hide');
            } else {
                this.showBottomSheet = true;
                console.log('show');
            }


        },
        isSelected(index) {
            return this.selectedExercises.includes(index);
        },
        createClusterFromSelected() {
            if (this.selectedExercises.length > 0) {
                const selectedExercisesData = this.selectedExercises.map(index => this.dayExercises[index]);
                const newCluster = {
                    id: `cluster-${Date.now()}`, // Unique ID for cluster
                    type: 'reps',
                    isCluster: true,
                    title: null,
                    sets: 1,
                    exercises: selectedExercisesData,
                };

                // Add new cluster to dayExercises
                this.dayExercises.push(newCluster);

                // Remove selected exercises from dayExercises
                this.dayExercises = this.dayExercises.filter((_, index) => !this.selectedExercises.includes(index));
                this.selectedExercises = []; // Clear the selection
                this.saveToHistory();

                this.showBottomSheet = false;
            }
        },
        removeExercise(index) {
            this.dayExercises.splice(index, 1);
            this.saveToHistory();
        },
        addExercise() {
            const selectedExercise = this.exercises.find(ex => ex.id === this.newExercise.id);
            if (selectedExercise) {
                const newExerciseData = {
                    id: selectedExercise.id,
                    sets: this.newExercise.sets,
                    type: this.newExercise.type,
                    value: this.newExercise.type === 'reps' ?
                        (Array.isArray(this.newExercise.value) ? [...this.newExercise.value] : this.newExercise.value) :
                        this.newExercise.value,
                    valueInput: this.newExercise.valueInput,
                };
                this.dayExercises.push(newExerciseData);
                this.saveToHistory();

                // Reset the form
                this.newExercise = {
                    id: null,
                    sets: 1,
                    type: 'reps',
                    value: 10,
                    valueInput: '10',
                };
            }
        },
        get() {
            return this.dayExercises;
        },
        set(data) {
            this.dayExercises = data;
        },
        confirmGeneratedWorkout(exercises) {
            this.dayExercises = this.dayExercises.concat(exercises);
            this.saveToHistory();
        },
        saveToHistory() {
            // Initialize history if empty
            if (this.history.length === 0) {
                this.history.push(JSON.stringify(this.dayExercises));
                this.currentHistoryIndex = 0;
                return;
            }

            // Get current state
            const currentState = JSON.stringify(this.dayExercises);
            const lastState = this.history[this.currentHistoryIndex];

            // Only save if state is different from last saved state
            if (currentState !== lastState) {
                // Remove any future states if we're not at the end of history
                if (this.currentHistoryIndex < this.history.length - 1) {
                    this.history = this.history.slice(0, this.currentHistoryIndex + 1);
                }

                // Add new state
                this.history.push(currentState);
                this.currentHistoryIndex++;

                // Remove oldest states if exceeding maxHistorySize
                if (this.history.length > this.maxHistorySize) {
                    this.history.shift();
                    this.currentHistoryIndex--;
                }
            }
        },
        undo() {
            if (this.currentHistoryIndex > 0) {
                this.currentHistoryIndex--;
                this.dayExercises = JSON.parse(this.history[this.currentHistoryIndex]);
            }
        },
        redo() {
            if (this.currentHistoryIndex < this.history.length - 1) {
                this.currentHistoryIndex++;
                this.dayExercises = JSON.parse(this.history[this.currentHistoryIndex]);
            }
        },
        handleRepsInput(input, exercise, clusterSets) {
            if (!input) return;

            // Remove any trailing dash
            if (input.endsWith('-')) {
                exercise.valueInput = input.slice(0, -1);
                return;
            }

            // Use cluster sets if provided (for subexercises), otherwise use exercise sets
            const maxSets = clusterSets || exercise.sets;

            // Handle pyramid sets input (e.g., "12-10-8")
            if (input.includes('-')) {
                const values = input.split('-').map(v => parseInt(v.trim())).filter(v => !isNaN(v));
                // Validate that number of values doesn't exceed number of sets
                if (values.length > maxSets) {
                    // Trim excess values
                    exercise.valueInput = values.slice(0, maxSets).join('-');
                    exercise.value = values.slice(0, maxSets);
                } else {
                    exercise.value = values;
                }
            } else {
                // Handle single value
                const value = parseInt(input);
                exercise.value = isNaN(value) ? 0 : value;
            }
        },
    },
    watch: {
        exercises: {
            handler() {
                this.exerciseHashIdNames = this.exercises.reduce((acc, ex) => {
                    acc[ex.id] = ex.name;
                    return acc;
                }, {});
            },
            immediate: true,
        },
        dayExercises: {
            handler(newVal, oldVal) {
                // Only save if lengths are different or it's a reorder
                if (!oldVal ||
                    newVal.length !== oldVal.length ||
                    JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
                    this.saveToHistory();
                }
            },
            deep: true
        }
    },
};
</script>
<style scoped>
.selected-item {
    background-color: #e0f7fa;
    /* Highlight selected items */
}

.cluster-container {
    width: 100%;
    background-color: #f0f4f7;

}



.clustered-exercise {
    margin-bottom: 5px;
}



.drag-handle {
    cursor: move;
}

.theme--dark .selected-item {
    background-color: rgba(255, 255, 255, 0.2);
}

.theme--light .selected-item {
    background-color: rgba(0, 0, 0, 0.2);
}


.theme--dark .cluster-container {
    background-color: #1f2d3d;
    border-left-color: #f0f4f7;
}

.theme--light .cluster-container {
    background-color: #f0f4f7;
    border-left-color: #1f2d3d;
}

.cluster-container {
    width: 100%;
}


.empty-list {
    height: 200px;
    justify-content: center;
    align-items: center;
    border: 2px dashed #e0e0e0;
    padding: 5px;
    border-radius: 10px;
}

.empty-list2 {
    padding: 5px;
    border-radius: 10px;
    overflow: hidden;
    min-height: 200px;
    border: 2px dashed #e0e0e0;
}



.drag-handle {
    cursor: move;
}
</style>
