<template>
  <v-container fluid class="pa-0">

    <v-toolbar flat>
      <v-text-field filled rounded single-line dense v-model="search" hide-details="" class=" rounded-lg" label="Buscar"
        append-icon="mdi-magnify">
      </v-text-field>

    </v-toolbar>

    <v-divider></v-divider>


    <v-data-table class="elevation-0 rounded-0" style="width:100%" :headers="headers" :items="data" :search="search"
      :loading="loading" @click:row="rowClick" disable-pagination hide-default-footer fixed-header
      :disable-sort="$store.state.Auth.token.claims.type != 'superuser' && $store.state.Auth.token.claims.type != 'admin'"
      mobile-breakpoint="0" sort-by="expired">

      <template v-slot:no-data v-if="$store.state.Auth.token.claims.type == 'entrenador'">
        <h3 class="ma-6 subtitle-1">
          {{ search && search.length > 4 ? 'No se encontraron resultados' : 'Ingrese una cedula para buscar' }}
        </h3>
      </template>

      <template v-slot:no-results v-if="$store.state.Auth.token.claims.type == 'entrenador'">
        <h3 class="ma-6 subtitle-1">
          {{ search && search.length > 4 ? 'No se encontraron resultados' : 'Ingrese una cedula para buscar' }}
        </h3>
      </template>

      <!-- 
              <template v-slot:item.civilId="{ item }">
                {{ validate_ci(item.civilId) ? item.civilId : item.civilId + ' CI INVALIDA'}}
              </template> -->


      <template #item.img="{ item }">
        <v-avatar size="30" class="mr-2">
          <UserImage :user="item" small />
        </v-avatar>
      </template>


      <template #item.displayName="{ item }">
        <span class="font-weight-medium">{{ item.displayName }}</span>
      </template>


      <template #item.mainWorkout.plan_id="{ item }">
        <span v-if="item.type != 'admin'">
          <template v-if="item.mainWorkout.plan_id">
            <v-chip v-if="item.mainWorkout.end && new Date(item.mainWorkout.end) < new Date()" dark small label
              color="error" class="font-weight-medium">
              <v-icon left small>mdi-alert</v-icon>
              Finalizado
            </v-chip>
            <v-chip
              v-else-if="item.mainWorkout.end && new Date(item.mainWorkout.end) <= new Date(new Date().setDate(new Date().getDate() + 7))"
              color="orange" dark small label>
              <v-icon left small>mdi-alert</v-icon>
              Finaliza {{ getMomentFromNow(item.mainWorkout.end) }}
            </v-chip>
            <v-chip v-else color="success" dark small label>
              <v-icon left small>mdi-check-circle</v-icon>
              Activo
            </v-chip>
          </template>
          <template v-else>
            <v-chip color="error" dark small label>
              <v-icon left small>mdi-alert</v-icon>
              Sin programa
            </v-chip>
          </template>
        </span>
      </template>






      <template #item.phoneNumber="{ item }">
        {{ item.phoneNumber ? item.phoneNumber.replace(/\+598|\s/g, '') : '' }}
      </template>


      <template #item.endOfSubscription="{ item }">
        <v-chip :color="getVencimiento(item) == 'DEBE CUOTA' ? 'red' : 'green'" dark v-if="item.endOfSubscription">
          {{ getVencimiento(item) }}
        </v-chip>
      </template>


      <template #item.plan="{ item }">
        <span v-if="item.plan == 0">
          Libre
        </span>
        <span v-else>
          {{ item.plan }}
        </span>
      </template>


      <template #item.injuries="{ item }">
        <div class="d-flex flex-column gap-1">
          <v-chip v-if="item.injuries?.length > 0 && item.injuries.filter(i => !i.recuperado).length > 0" color="error"
            x-small label class="mb-1">
            <v-icon left x-small>mdi-alert</v-icon>
            {{ item.injuries.filter(i => !i.recuperado).length }} Sin rehabilitar
          </v-chip>

          <v-chip v-if="item.injuries?.filter(i => i.recuperado).length" color="success" x-small label>
            <v-icon left x-small>mdi-check</v-icon>
            {{ item.injuries.filter(i => i.recuperado).length }} Rehabilitado
          </v-chip>
        </div>
      </template>


      <template #item.userTrainingWeek="{ item }">
        <UserTrainingWeek :user="item" :userId="item.id" v-if="item.type != 'admin'" />
      </template>








      <template #item.expired="{ item }">
        <v-chip v-if="item.licensePaused == true" color="primary darken-4" dark>
          <v-icon>mdi-pause</v-icon>
          Licencia activa
        </v-chip>
        <v-chip v-else-if="item.endOfSubscription"
          :color="item.expired && item.expired <= 0 ? 'red' : 'success darken-1'" dark>
          {{ item.expired > 0 ? '+' : '' }}{{ item.expired }} </v-chip>

        <v-chip dark color="warning darken-3"
          v-else-if="!item.endOfSubscription && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(item.type)">
          <v-icon left small>mdi-alert</v-icon>
          DEBE PRIMER CUOTA
        </v-chip>
      </template>

    </v-data-table>

  </v-container>
</template>

<script>
import TableItem from "@/components/TableItem.vue";

import { mask } from 'vue-the-mask'
import moment from 'moment'
import UserImage from '@/components/profile/UserImage.vue';

import UserTrainingWeek from '@/components/workouts/UserTrainingWeek.vue';


export default {
  directives: { mask },
  data() {
    return {
      search: ""
    }
  },
  components: {
    TableItem, UserImage, UserTrainingWeek
  },

  props: {
    data: Array,
    icon: String,
    title: String,
    subtitle: String,
    subtitleIcon: String,
    loading: Boolean,
    acceptCompanies: Boolean,
    companies: Array,
    headers: Array
  },
  methods: {
    getMomentFromNow(date) {
      return moment(date).locale('es').fromNow()
    },
    rowClick(e) {
      this.$emit('save', e)
    },
    emitSearch() {
      if (this.search.length >= 4)
        this.$emit('search', this.search)
    },
    emitAdd() {
      this.$emit('add2', this.search)
    },
    getColor(type) {
      switch (type) {
        case 'superuser':
          return 'black'
        case 'admin':
          return 'orange'
        case 'usuario':
          return 'green';
        case 'entrenador':
          return 'blue';
        case 'paselibre':
          return 'cyan';
        case 'gratis':
          return 'green darken-2';
        default:
          return 'grey'
      }
    },
    getVencimiento(item) {
      let user = item

      if (user.endOfSubscription && ['usuario', 'canje', 'representante', 'opengym', 'personalizado'].includes(user.type)) {
        let today = new Date();
        let endOfSubscription = user.endOfSubscription.toDate();
        if (today > endOfSubscription) {
          return "DEBE CUOTA"
        }
        else {
          return endOfSubscription.getDate() + '/' + (endOfSubscription.getMonth() + 1) + '/' + endOfSubscription.getFullYear();

        }
      } else {

        return '';
      }


    },
    validation_digit(ci) {
      var a = 0;
      var i = 0;
      if (ci.length <= 6) {
        for (i = ci.length; i < 7; i++) {
          ci = '0' + ci;
        }
      }
      for (i = 0; i < 7; i++) {
        a += (parseInt("2987634"[i]) * parseInt(ci[i])) % 10;
      }
      if (a % 10 === 0) {
        return 0;
      } else {
        return 10 - a % 10;
      }
    }

    , validate_ci(ci) {
      ci = this.clean_ci(ci);
      var dig = ci[ci.length - 1];
      ci = ci.replace(/[0-9]$/, '');
      return (dig == this.validation_digit(ci));
    }

    , random_ci() {
      var ci = Math.floor(Math.random() * 10000000).toString();
      ci = ci.substring(0, 7) + validation_digit(ci);
      return ci;
    }

    , clean_ci(ci) {
      return ci.replace(/\D/g, '');
    }

  },
  watch: {
    search() {

      if (this.$store.state.Auth.token.claims.type && (this.$store.state.Auth.token.claims.type == 'entrenador' && this.search.isNumeric() && this.search.length >= 4)) {
        this.emitSearch()
      }

    },
  },

}
</script>

<style lang="scss" scoped>
.v-data-table {
  border-radius: 8px;
  overflow: hidden;

  ::v-deep .v-data-table__wrapper {

    // Add subtle hover effect on rows
    tbody tr:hover {
      background-color: rgba(var(--v-theme-primary), 0.04);
    }

    // Improve header styling
    th {
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      font-size: 0.75rem;
    }

    // Add subtle border between rows
    td {
      border-bottom: thin solid rgba(var(--v-border-color), 0.12);
    }
  }
}

// Improve search field appearance
.v-text-field.rounded-lg {
  .v-input__slot {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05) !important;
  }

  &:hover .v-input__slot {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08) !important;
  }

  &.v-input--is-focused .v-input__slot {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12) !important;
  }
}

// Consistent chip styling
.v-chip {
  font-weight: 500;
  letter-spacing: 0.5px;

  .v-icon {
    margin-right: 4px;
  }
}
</style>
