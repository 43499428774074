<template>
    <div>
        <div v-for="(option, index) in question.options" :key="index" class="d-flex align-center mb-2">
            <v-checkbox disabled class="mt-0 mr-2"></v-checkbox>
            <v-text-field v-model="question.options[index]" placeholder="Option text" hide-details dense outlined
                class="flex-grow-1"></v-text-field>
            <v-btn icon small class="ml-2" @click="question.options.splice(index, 1)">
                <v-icon small>mdi-close</v-icon>
            </v-btn>
        </div>
        <v-btn text small color="primary" class="mt-2" @click="question.options.push('')">
            <v-icon left small>mdi-plus</v-icon>
            Add Option
        </v-btn>
    </div>
</template>

<script>
export default {
    name: 'CheckboxQuestion',
    props: ['question']
}
</script>