<template>
    <div class="somatotype-chart">
        <apexchart type="radar" height="300" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
    name: 'SomatotypeChart',
    components: {
        apexchart: VueApexCharts
    },

    props: {
        endomorphy: {
            type: Number,
            required: true
        },
        mesomorphy: {
            type: Number,
            required: true
        },
        ectomorphy: {
            type: Number,
            required: true
        }
    },

    computed: {
        series() {
            return [{
                name: 'Somatotipo',
                data: [this.endomorphy, this.mesomorphy, this.ectomorphy]
            }]
        },
        chartOptions() {
            return {
                chart: {
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: ['Endomorfia', 'Mesomorfia', 'Ectomorfia']
                },
                yaxis: {
                    show: true,
                    min: 0,
                    max: 7,
                    tickAmount: 7
                },
                plotOptions: {
                    radar: {
                        size: 140,
                        polygons: {
                            strokeColors: '#e9e9e9',
                            fill: {
                                colors: ['#f8f8f8', '#fff']
                            }
                        }
                    }
                },
                colors: ['#00b0ff'],
                markers: {
                    size: 4,
                    colors: ['#00b0ff'],
                    strokeColors: '#fff',
                    strokeWidth: 2
                },
                tooltip: {
                    y: {
                        formatter: (value) => value.toFixed(2)
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.somatotype-chart {
    width: 100%;
    height: 300px;
}
</style>