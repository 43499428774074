<!-- src/components/community/CommunityForm.vue -->
<template>
  <div>
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn color="primary" icon v-on="on" @click="dialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Nueva Comunidad</span>
    </v-tooltip>

    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="rounded-lg">
        <v-card-title class="headline primary--text pa-4">
          <v-icon left color="primary">mdi-account-group-outline</v-icon>
          Nueva Comunidad
        </v-card-title>

        <v-card-text class="pa-4">
          <v-form ref="form" @submit.prevent="createCommunity">
            <v-text-field v-model="community.name" label="Nombre de la Comunidad"
              :rules="[v => !!v || 'El nombre es requerido']" outlined class="mb-4"></v-text-field>

            <v-select v-model="community.members" :items="availableUsers" item-text="displayName" item-value="id"
              label="Seleccionar Miembros" multiple chips small-chips outlined deletable-chips
              :rules="[v => v.length > 0 || 'Debe seleccionar al menos un usuario']"></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog" class="mr-2">
            Cancelar
          </v-btn>
          <v-btn color="primary" @click="createCommunity" :loading="loading" class="px-4">
            <v-icon left>mdi-plus</v-icon>
            Crear
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { collection, addDoc, getFirestore, doc, updateDoc } from "firebase/firestore";
import { arrayUnion } from "firebase/firestore";

export default {
  name: "CommunityForm",
  props: {
    availableUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      loading: false,
      community: {
        name: "",
        members: [],
      },
    };
  },
  methods: {
    async createCommunity() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const db = getFirestore();

          // Create new community
          const docRef = await addDoc(collection(db, "communities"), {
            name: this.community.name,
            members: this.community.members,
          });

          // Update users' enabledCommunities
          for (const userId of this.community.members) {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
              enabledCommunities: arrayUnion(docRef.id),
            });
          }

          // Emit the new community
          this.$emit("create", {
            id: docRef.id,
            ...this.community,
          });

          this.$notify({
            title: "Comunidad creada",
            text: "La comunidad ha sido creada exitosamente",
            type: "success",
          });

          this.closeDialog();
        } catch (error) {
          console.error("Error creating community:", error);
          this.$notify({
            title: "Error",
            text: "Ocurrió un error al crear la comunidad",
            type: "error",
          });
        }
        this.loading = false;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$refs.form.reset();
      this.community = {
        name: "",
        members: [],
      };
    },
  },
};
</script>

<style scoped>
.v-card {
  transition: transform 0.2s;
}

.v-card:hover {
  transform: translateY(-2px);
}
</style>
